<template>
  <div v-if="form" class="row">
    <div class="col-lg-6">
      <div class="form-label align-bottom">名前</div>
      <div class="form-edit-line fs-5" style="font-weight: bold;">
        {{ form.name }}
      </div>
      <div v-if="form.kana" class="form-label align-bottom">カナ</div>
      <div v-if="form.kana" class="form-edit-line">
        {{ form.kana }}
      </div>
      <div v-if="form.name_en" class="form-label align-bottom">英語名</div>
      <div v-if="form.name_en" class="form-edit-line">
        {{ form.name_en }}
      </div>
      <div v-if="form.officialSiteUrl" class="form-label align-bottom">公式サイト</div>
      <div v-if="form.officialSiteUrl" class="form-edit-line text-break">
        <a :href="form.officialSiteUrl" target="_blank">{{ form.officialSiteUrl }}</a>
      </div>
      <div v-if="form.twitterId" class="form-label align-bottom"><i class="fa-brands fa-twitter fa-fw" style="margin-right: 5px;"></i>Twitter</div>
      <div v-if="form.twitterId" class="form-edit-line text-break">
        <a :href="'https://twitter.com/' + form.twitterId" target="_blank">@{{ form.twitterId }}</a>
      </div>
      <div v-if="form.facebookUrl" class="form-label align-bottom"><i class="fa-brands fa-facebook fa-fw" style="margin-right: 5px;"></i>facebook</div>
      <div v-if="form.facebookUrl" class="form-edit-line text-break">
        <a :href="form.facebookUrl" target="_blank">{{ form.facebookUrl }}</a>
      </div>
      <div v-if="form.instagramId" class="form-label align-bottom"><i class="fa-brands fa-instagram fa-fw" style="margin-right: 5px;"></i>Instagram</div>
      <div v-if="form.instagramId" class="form-edit-line text-break">
        <a :href="'https://instagram.com/' + form.instagramId" target="_blank">@{{ form.instagramId }}</a>
      </div>
      <div v-if="form.tiktokId" class="form-label align-bottom"><i class="fa-brands fa-tiktok fa-fw" style="margin-right: 5px;"></i>TikTok</div>
      <div v-if="form.tiktokId" class="form-edit-line text-break">
        <a :href="'https://tiktok.com/' + form.tiktokId" target="_blank">@{{ form.tiktokId }}</a>
      </div>
      <div v-if="form.soundcloudId" class="form-label align-bottom"><i class="fa-brands fa-soundcloud fa-fw" style="margin-right: 5px;"></i>SOUNDCLOUD</div>
      <div v-if="form.soundcloudId" class="form-edit-line text-break">
        <a :href="'https://soundcloud.com/' + form.soundcloudId" target="_blank">@{{ form.soundcloudId }}</a>
      </div>
      <div v-if="form.youtubeUrl" class="form-label align-bottom"><i class="fa-brands fa-youtube fa-fw" style="margin-right: 5px;"></i>YouTube</div>
      <div v-if="form.youtubeUrl" class="form-edit-line text-break">
        <a :href="form.youtubeUrl" target="_blank">{{ form.youtubeUrl }}</a>
      </div>
      <div v-if="form.appleMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-apple fa-fw" style="margin-right: 5px;"></i>Apple Music</div>
      <div v-if="form.appleMusicUrl" class="form-edit-line text-break">
        <a :href="form.appleMusicUrl" target="_blank">{{ form.appleMusicUrl }}</a>
      </div>
      <div v-if="form.spotifyUrl" class="form-label align-bottom"><i class="fa-brands fa-spotify fa-fw" style="margin-right: 5px;"></i>Spotify</div>
      <div v-if="form.spotifyUrl" class="form-edit-line text-break">
        <a :href="form.spotifyUrl" target="_blank">{{ form.spotifyUrl }}</a>
      </div>
      <div v-if="form.youtubeMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-youtube-square fa-fw" style="margin-right: 5px;"></i>YouTube Music</div>
      <div v-if="form.youtubeMusicUrl" class="form-edit-line text-break">
        <a :href="form.youtubeMusicUrl" target="_blank">{{ form.youtubeMusicUrl }}</a>
      </div>
      <div v-if="form.amazonMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-amazon fa-fw" style="margin-right: 5px;"></i>Amazon Music</div>
      <div v-if="form.amazonMusicUrl" class="form-edit-line text-break">
        <a :href="form.amazonMusicUrl" target="_blank">{{ form.amazonMusicUrl }}</a>
      </div>
      <div v-if="form.lineMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-line fa-fw" style="margin-right: 5px;"></i>LINE Music</div>
      <div v-if="form.lineMusicUrl" class="form-edit-line text-break">
        <a :href="form.lineMusicUrl" target="_blank">{{ form.lineMusicUrl }}</a>
      </div>
      <div v-for="site in form.siteList" :key="site.siteUrl">
        <div class="form-label align-bottom"><i class="fas fa-globe fa-fw" style="margin-right: 5px;"></i>{{ site.name }}</div>
        <div class="form-edit-line text-break">
          <a :href="site.siteUrl" target="_blank">{{ site.siteUrl }}</a>
        </div>
      </div>
      <div v-if="form.photoList && form.photoList.length > 0" class="form-label align-bottom">アー写</div>
      <div v-if="form.photoList && form.photoList.length > 0" class="form-edit-line">
        <div v-for="(photo, index) in form.photoList" :key="photo.blobName">
          <a @click="selectPhoto(index)" href="#"><img v-if="photo.url" :src="photo.url" class="img-thumbnail float-start" style="max-width: 180px; margin: 5px;"></a>
        </div>
      </div>
      <div v-if="form.memo" class="form-label align-bottom">メモ</div>
      <div v-if="form.memo" class="form-edit-line">
        <span v-html="form.memo"></span>
      </div>
      <div v-if="form.createdAt" class="form-label align-bottom">登録</div>
      <div v-if="form.createdAt" class="form-edit-line">
        {{form.createdAt}}（{{form.createdBy}}）
      </div>
      <div v-if="form.updatedAt" class="form-label align-bottom">最終更新</div>
      <div v-if="form.updatedAt" class="form-edit-line">
        {{form.updatedAt}}（{{form.updatedBy}}）
      </div>
    </div>
    <div class="col-lg-6">
      <ul class="nav nav-tabs" style="margin-bottom: 10px;">
        <li class="nav-item">
          <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
            原盤
          </a>
        </li>
        <li class="nav-item">
          <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
            アルバム
          </a>
        </li>
        <!--                  <li class="nav-item">-->
        <!--                    <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }" aria-current="page" href="#">-->
        <!--                      分析-->
        <!--                    </a>-->
        <!--                  </li>-->
      </ul>
      <div v-if="rightAreaMode === 'MASTER'">
        <div class="input-group">
          <input @keydown.enter="masterList = []; masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
          <button @click="masterList = []; masterCondition.searchString = ''; masterCondition.page = 0; findMaster();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
          <button @click="masterList = []; masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
        </div>
        <div style="margin-top: 5px;" class="text-end">
          <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
          <span v-else style="margin-top: 20px;">一致データが存在しません</span>
        </div>
        <table v-if="masterList" class="table table-responsive">
          <tbody>
            <tr v-for="(master, index) in masterList" :key="index" class="align-middle">
              <td>
                <span v-if="master.isrc" style="font-size: 80%;">ISRC : {{master.isrc}}<br></span>
                {{master.displayName}}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="masterResult && masterResult.allRecords > masterList.length">
            <tr>
              <td colspan="5">
                <div class="d-grid gap-2">
                  <button @click="masterCondition.page = masterResult.page + 1; findMaster();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="rightAreaMode === 'ALBUM'">
        <div class="input-group">
          <input @keydown.enter="albumList = []; albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
          <button @click="albumList = []; albumCondition.searchString = ''; albumCondition.page = 0; findAlbum();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
          <button @click="albumList = []; albumCondition.page = 0; findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
        </div>
        <div style="margin-top: 5px;" class="text-end">
          <span v-if="albumResult && albumResult.allRecords > 0">{{ albumResult.allRecords }}件の一致データ</span>
          <span v-else style="margin-top: 20px;">一致データが存在しません</span>
        </div>
        <table v-if="albumList" class="table table-responsive">
          <tbody>
            <tr v-for="(album, index) in albumList" :key="index" class="align-middle">
              <td>
                <p v-if="album.catalogNo" style="margin: 0; font-size: 80%;"><span v-html="album.catalogNo"></span></p>
                {{album.name}}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="albumResult && albumResult.allRecords > albumList.length">
            <tr>
              <td colspan="5">
                <div class="d-grid gap-2">
                  <button @click="albumCondition.page = albumResult.page + 1; findAlbum();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { artistFunction } from '@/views/common/artist/function'
import { masterFunction } from '@/views/red/master/function'
import { albumStore, masterStore } from '@/components/modal/store'
import { albumFunction } from '@/views/red/album/function'

export default defineComponent({
  props: ['artistId'],
  setup (props) {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const artistFunc = artistFunction()
    const albumName = ref('')
    const masterName = ref('')
    // Form
    const form = ref({})
    const rightAreaMode = ref('MASTER')
    const photoUrl = ref('')
    // 原盤一覧
    const masterCondition = masterStore()
    const masterFind = masterFunction()
    const masterResult = ref(null)
    const masterList = ref([])
    // アルバム一覧
    const albumCondition = albumStore()
    const albumFind = albumFunction()
    const albumResult = ref(null)
    const albumList = ref([])
    // メソッド
    const init = async () => {
      form.value.id = props.artistId
      const promises = []
      promises.push(artistFunc.get(form.value.id))
      promises.push(artistFunc.findPhoto(form.value.id))
      promises.push(artistFunc.findSite(form.value.id))
      promises.push(findMaster())
      promises.push(findAlbum())
      if (route.path.includes('/red/album/artist/disp/')) {
        promises.push(albumFind.get(route.params.albumId))
      }
      if (route.path.includes('/red/master/artist/disp/')) {
        promises.push(masterFind.get(route.params.masterId))
      }
      const response = await Promise.all(promises)
      form.value = response[0]
      form.value.photoList = response[1]
      form.value.siteList = response[2]
    }
    const findMaster = async () => {
      const result = await masterFind.findByArtist(form.value.id, masterCondition)
      masterResult.value = result
      masterList.value = masterList.value.concat(result.list)
    }
    const masterClearSearch = () => {
      masterCondition.searchString = ''
      masterCondition.page = 0
      findMaster()
    }
    const findAlbum = async () => {
      const result = await albumFind.findByArtist(form.value.id, albumCondition)
      albumResult.value = result
      albumList.value = albumList.value.concat(result.list)
    }
    const albumClearSearch = () => {
      albumCondition.searchString = ''
      albumCondition.page = 0
      findAlbum()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      albumCondition.page = 0
      albumCondition.searchString = ''
      masterCondition.page = 0
      masterCondition.searchString = ''
      await init(route.params.id)
    })
    return {
      route,
      router,
      form,
      albumName,
      masterName,
      rightAreaMode,
      photoUrl,
      masterCondition,
      masterResult,
      masterList,
      findMaster,
      masterClearSearch,
      albumCondition,
      albumResult,
      albumList,
      findAlbum,
      albumClearSearch
    }
  }
})
</script>
