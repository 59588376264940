<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/user/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] ユーザー一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="id" :to="'/admin/user/edit/' + id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link to="/admin/user/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div v-if="contractName" class="form-label align-bottom">契約</div>
          <div v-if="contractName" class="form-edit-line">
            {{contractName}}
          </div>
          <div class="form-label align-bottom">名前</div>
          <div class="form-edit-line">
            {{name}}
          </div>
          <div class="form-label align-bottom">メールアドレス</div>
          <div class="form-edit-line">
            <table class="table table-responsive">
              <tbody>
                <tr v-for="email in emailList" :key="email.id">
                  <td>{{email.email}}</td>
                  <td class="text-end" style="width: 100px;">
                    <span v-if="email.defaultEmail" class="badge bg-primary"><i class="fas fa-envelope" style="margin-right: 5px;"></i>メイン</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="cellPhone" class="form-label align-bottom">携帯電話番号</div>
          <div v-if="cellPhone" class="form-edit-line">
            {{cellPhone}}
          </div>
          <div class="form-label align-bottom">ログイン可否</div>
          <div class="form-edit-line">
            <span v-if="canLogin === 'YES'">可能</span>
            <span v-else>不可能</span>
          </div>
          <div class="form-label align-bottom">ログイン方法</div>
          <div class="form-edit-line">
            <span v-if="loginMethod === 'EMAIL'">メール</span>
            <span v-else>SMS</span>
          </div>
          <div v-if="nickname" class="form-label align-bottom">表示名</div>
          <div v-if="nickname" class="form-edit-line">
            {{cellPhone}}
          </div>
          <div class="form-label align-bottom">規約同意</div>
          <div class="form-value">
            <div>
              <span v-if="agree === 'YES'">同意済み</span>
              <span v-else>未同意</span>
            </div>
          </div>
          <div class="form-label align-bottom">お知らせのメール受信</div>
          <div class="form-value">
            <div>
              <span v-if="receiveInformation">システム停止等のお知らせ</span>
              <span v-else style="text-decoration: line-through;">システム停止等のお知らせ</span>
            </div>
            <div>
              <span v-if="receiveVersionInformation">バージョンアップのお知らせ</span>
              <span v-else style="text-decoration: line-through;">バージョンアップのお知らせ</span>
            </div>
          </div>
          <div v-if="destinationList.length > 0" class="form-label align-bottom">送付先</div>
          <div v-if="destinationList.length > 0" class="form-value">
            <table class="table table-responsive">
              <thead>
              <tr>
                <th>送付先名</th>
                <th>契約者名</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="destination in destinationList" :key="destination.id">
                <td>{{destination.name}}</td>
                <td>{{destination.contractName}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="form-label align-bottom">登録日時</div>
          <div class="form-edit-line">
            {{createdAt}}（{{createdBy}}）
          </div>
          <div class="form-label align-bottom">最終更新日時</div>
          <div class="form-edit-line">
            {{updatedAt}}（{{updatedBy}}）
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const route = useRoute()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // Form
    const id = ref(null)
    const contractName = ref(null)
    const name = ref('')
    const email = ref('')
    const cellPhone = ref('')
    const loginMethod = ref('EMAIL')
    const canLogin = ref('YES')
    const nickname = ref('')
    const receiveInformation = ref(true)
    const agree = ref('NO')
    const receiveVersionInformation = ref(true)
    const createdAt = ref('')
    const createdBy = ref('')
    const updatedAt = ref('')
    const updatedBy = ref('')
    const destinationList = ref([])
    const emailList = ref([])
    // メソッド
    function entityToForm (model) {
      name.value = model.name
      email.value = model.email
      cellPhone.value = model.cellPhone
      loginMethod.value = model.loginMethod
      canLogin.value = model.canLogin
      nickname.value = model.nickname
      receiveInformation.value = (model.receiveInformation === 'YES')
      agree.value = model.agree
      receiveVersionInformation.value = (model.receiveVersionInformation === 'YES')
      createdAt.value = model.createdAt
      createdBy.value = model.createdBy
      updatedAt.value = model.updatedAt
      updatedBy.value = model.updatedBy
      if (model.contractId) {
        axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + model.contractId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          contractName.value = response.data.name
        })
      } else {
        contractName.value = null
      }
      destinationList.value = []
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + model.id + '/destination/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        destinationList.value = response.data
        for (const destination of destinationList.value) {
          axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + destination.contractId, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            destination.contractName = response.data.name
          })
        }
      })
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + model.id + '/email/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        emailList.value = response.data
      })
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      id.value = route.params.id
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/account/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
      })
      loadingModal.hide()
    })
    return {
      loadingModalDiv,
      id,
      contractName,
      name,
      email,
      cellPhone,
      loginMethod,
      canLogin,
      nickname,
      receiveInformation,
      agree,
      receiveVersionInformation,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      destinationList,
      emailList
    }
  }
})
</script>
