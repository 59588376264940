<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/user/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      利用者一覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/user/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">略称</div>
          <div class="form-edit-line">
            <input v-model="form.abbreviation" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">カナ</div>
          <div class="form-edit-line">
            <input v-model="form.kana" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">英語名</div>
          <div class="form-edit-line">
            <input v-model="form.nameEn" type="text" class="form-control">
          </div>
          <div class="card" style="margin-bottom: 10px;" id="calcDiv">
            <div class="card-header">
              頒布形態
              <button v-if="rightAreaMode === 'SALES'" @click="openUserSalesModal" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-plus"></i></button>
            </div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(sales, index) in form.salesList" :key="index" :class="{'deleted-row': sales.deleted}" class="align-middle">
                  <td class="text-center" style="width: 55px;">
                    <button @click.prevent.stop="editUserSales(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-edit fa-fw"></i></button>
                  </td>
                  <td>{{ sales.name }}</td>
                  <td style="width: 47px;" class="text-end">
                    <button @click="sales.deleted = !sales.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-label align-bottom">メモ</div>
          <div class="form-edit-line">
            <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
          </div>
          <div style="height: 50px;"></div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="userSalesModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">頒布形態追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="userSales.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">英語名</div>
            <div class="form-edit-line">
              <input v-model="userSales.nameEn" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">利用種別</div>
            <div class="form-edit-line">
              <select v-model="userSales.useTypeId" class="form-select">
                <option v-for="usetype in usetypeList" :key="usetype.id" :value="usetype.id">
                  {{ usetype.name }}
                </option>
                <option value="none">-</option>
              </select>
            </div>
            <div class="form-label align-bottom">URL</div>
            <div class="form-edit-line">
              <input v-model="userSales.siteUrl" type="url" class="form-control">
            </div>
            <div class="form-label align-bottom">使用数・受領額共にゼロのデータ</div>
            <div class="form-edit-line">
              <select v-model="userSales.registerZeroData" class="form-select">
                <option value="true">取り込む</option>
                <option value="false">取り込まない</option>
              </select>
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>1期に取り込むファイル数</div>
            <div class="form-edit-line">
              <input v-model="userSales.registerFileCount" @change="changeRegisterFileCount" type="number" class="form-control" style="width: 80px;">
            </div>
            <div v-if="userSales.usedMonthType === 'input'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>取り込むファイルの利用月</div>
            <div v-if="userSales.registerMonthList && userSales.registerMonthList.length > 0 && userSales.usedMonthType === 'input'" class="form-edit-line">
              <div v-for="month in userSales.registerMonthList" :key="month.monthsAgo" class="input-group">
                <input v-model="month.monthsAgo" type="number" class="form-control" style="flex: 0 1 80px;">
                <span class="input-group-text">ヶ月前</span>
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>取込ファイル定義</div>
            <div class="form-edit-line">
              <select @change="changeDefinition(userSales)" v-model="userSales.definitionId" class="form-select">
                <option v-for="definition in definitionList" :key="definition.id" :value="definition.id">
                  {{definition.name}}
                </option>
              </select>
            </div>
            <div v-if="userSales.definitionItemList && userSales.definitionItemList.length > 0" class="form-label align-bottom">
              定義詳細
            </div>
            <div v-if="userSales.definitionItemList && userSales.definitionItemList.length > 0" class="form-edit-line">
              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 40px;">#</th>
                  <th>種別</th>
                  <th style="width: 170px;">対象／対象外</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in userSales.definitionItemList" :key="index">
                    <td>{{index + 1}}</td>
                    <td>
                      <span v-if="item.itemType === 'catalog_no'">製品番号</span>
                      <span v-else-if="item.itemType === 'jan_code'">JANコード（POSコード）</span>
                      <span v-else-if="item.itemType === 'user_album_code'">利用者アルバムコード</span>
                      <span v-else-if="item.itemType === 'isrc'">ISRC</span>
                      <span v-else-if="item.itemType === 'master_code'">原盤管理コード</span>
                      <span v-else-if="item.itemType === 'user_master_code'">利用者原盤コード</span>
                      <span v-else-if="item.itemType === 'matching_code'">種類が混在したコード</span>
                      <span v-else-if="item.itemType === 'album_title'">アルバム名</span>
                      <span v-else-if="item.itemType === 'master_name'">原盤名</span>
                      <span v-else-if="item.itemType === 'album_or_master_name'">アルバム名／原盤名</span>
                      <span v-else-if="item.itemType === 'artist_name'">アーティスト名</span>
                      <span v-else-if="item.itemType === 'used_year'">使用年</span>
                      <span v-else-if="item.itemType === 'used_month'">使用月</span>
                      <span v-else-if="item.itemType === 'used_year_month_1'">使用年月（YYYYMM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_2'">使用年月（YYYY/MM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_3'">使用年月（YYYY-MM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_4'">使用年月（YYYY年MM月）</span>
                      <span v-else-if="item.itemType === 'use_type_name'">利用種別</span>
                      <span v-else-if="item.itemType === 'royalty_type_name'">印税種別</span>
                      <span v-else-if="item.itemType === 'used_no'">使用数</span>
                      <span v-else-if="item.itemType === 'price_out_tax'">価格（税抜）</span>
                      <span v-else-if="item.itemType === 'price_in_tax'">価格（税込）</span>
                      <span v-else-if="item.itemType === 'revenue_out_tax'">売上（税抜）</span>
                      <span v-else-if="item.itemType === 'revenue_in_tax'">売上（税込）</span>
                      <span v-else-if="item.itemType === 'unit_price'">分配単価</span>
                      <span v-else-if="item.itemType === 'div_rate'">分配率</span>
                      <span v-else-if="item.itemType === 'income_amount_out_tax'">受領額（税抜）</span>
                      <span v-else-if="item.itemType === 'income_amount_in_tax'">受領額（税込）</span>
                      <span v-else-if="item.itemType === 'tax_rate'">消費税率</span>
                      <span v-else-if="item.itemType === 'memo'">メモ</span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center">
                      <div class="input-group input-group-sm">
                        <select v-model="item.registerOrThrough" class="form-select form-select-sm">
                          <option value="register">特定取込</option>
                          <option value="through">取込除外</option>
                        </select>
                        <button v-if="item.rotStrings && item.rotStrings.length > 0" @click="openRotStrings(index)" class="btn btn-outline-danger btn-sm"><i class="fas fa-cog"></i></button>
                        <button v-else @click="openRotStrings(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-cog"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">通貨</div>
            <div class="form-edit-line">
              <select v-model="userSales.currencyId" class="form-select">
                <option v-for="currency in currencyList" :key="currency.id" :value="currency.id">
                  {{currency.name}}
                </option>
              </select>
            </div>
            <div class="form-label align-bottom">消費税</div>
            <div class="form-edit-line">
              <select v-model="userSales.taxation" class="form-select">
                <option value="true">課税 / 課税・非課税混在</option>
                <option value="false">非課税</option>
              </select>
            </div>
            <div class="form-label align-bottom">一括取込対応</div>
            <div class="form-edit-line">
              <div class="input-group">
                <select v-model="userSales.batchRegister" class="form-select">
                  <option value="false">対応しない</option>
                  <option value="true">対応する</option>
                </select>
                <button v-if="userSales.batchRegister === 'true' || userSales.batchRegister === true" @click="addBatchRegisterFiler" class="btn btn-outline-primary"><i class="fas fa-plus"></i></button>
              </div>
            </div>
            <div v-if="userSales.batchRegister === 'true' || userSales.batchRegister === true" class="form-edit-line">
              <div v-for="(filter, fIndex) in userSales.batchRegisterFilterList" :key="fIndex" class="input-group">
                <select v-model="filter.filterType" class="form-select" style="flex: 0 1 280px;">
                  <option value="file_name">フォルダ名またはファイル名</option>
                  <option value="extension">拡張子</option>
                  <option value="sheet_name">Excelシート名</option>
                </select>
                <button @click="filter.batchRegisterFilterOrList.push({ orString: '' })" class="btn btn-outline-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>OR</button>
                <template v-for="(or, orIndex) in filter.batchRegisterFilterOrList" :key="orIndex">
                  <input v-model="or.orString" type="text" class="form-control" style="flex: 0 1 150px;">
                </template>
              </div>
            </div>
            <div class="form-label align-bottom">メモ</div>
            <div class="form-edit-line">
              <textarea v-model="userSales.memo" class="form-control" rows="8"></textarea>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button v-if="!userSales.id" @click="addUserSales" type="button" class="btn btn-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
              <button v-if="userSales.id" @click="addUserSales" type="button" class="btn btn-primary"><i class="fas fa-sync fa-fw" style="margin-right: 5px;"></i>更新</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">利用者の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の利用者を削除しようとしています。削除すると、利用者情報の確認ができなくなり、該当利用者が紐づいている利用者、アルバム、利用者の利用者情報がクリアされます。
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rotModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">特定取込／取込除外文字列</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <textarea v-model="rotStrings" class="form-control" rows="5"></textarea>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeRotStrings" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="setRotStrings" type="button" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-check"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { userFunction } from './function'
import { useTypeFunction } from '../usetype/function'
import { definitionFunction } from '../definition/function'
import { currencyFunction } from '../../common/currency/function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const userFunc = userFunction()
    const useTypeFunc = useTypeFunction()
    const definitionFunc = definitionFunction()
    const currencyFunc = currencyFunction()
    const form = ref(null)
    const rightAreaMode = ref('SALES')
    const currencyList = ref([])
    // 頒布形態
    const userSalesModalDiv = ref(null)
    let userSalesModal = null
    const userSales = ref({})
    const definitionList = ref([])
    const usetypeList = ref([])
    let userSalesIndex = -1
    const rotModalDiv = ref(null)
    let rotModal = null
    const rotStrings = ref([])
    let rotIndex = -1
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      for (const userSales of form.value.salesList) {
        let matchType = 'album'
        for (const definitionItem of userSales.definitionItemList) {
          if (definitionItem.matching) {
            if (definitionItem.itemType !== 'catalog_no' && definitionItem.itemType !== 'jan_code' && definitionItem.itemType !== 'user_album_code') {
              matchType = 'master'
            }
          }
        }
        if (userSales.useTypeId === 'none' && matchType === 'master') {
          errors.value.push('原盤ともマッチする可能性のある定義を使用する場合は、利用種別は省略できません。')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!form.value.abbreviation) {
          form.value.abbreviation = form.value.name
        }
        for (const userSales of form.value.salesList) {
          if (userSales.useTypeId === 'none') {
            userSales.useTypeId = null
          }
          for (const filter of userSales.batchRegisterFilterList) {
            if (filter.batchRegisterFilterOrList && filter.batchRegisterFilterOrList.length > 0) {
              filter.filterString = filter.batchRegisterFilterOrList.filter(x => x.orString !== '').map(x => x.orString).join('/')
            }
          }
        }
        if (!form.value.id) {
          form.value.id = await userFunc.insert(session.session.company.id, form.value)
        } else {
          await userFunc.update(form.value.id, form.value)
        }
        userFunc.indexUpdate(form.value.id)
        await router.push('/red/user/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await userFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/red/user/list')
    }
    const openUserSalesModal = () => {
      userSalesIndex = -1
      userSales.value = {
        definitionId: null,
        registerFileCount: 1,
        registerZeroData: true,
        batchRegister: false,
        batchRegisterFilterList: [],
        registerMonthList: [{
          monthsAgo: 1
        }]
      }
      if (definitionList.value.length > 0) {
        userSales.value.definitionId = definitionList.value[0].id
        changeDefinition(userSales.value)
      }
      for (const currency of currencyList.value) {
        if (currency.defaultCurrency) {
          userSales.value.currencyId = currency.id
          break
        }
      }
      userSales.value.taxation = true
      if (usetypeList.value.length > 0) {
        if (!userSales.value.useTypeId) {
          userSales.value.useTypeId = usetypeList.value[0].id
        }
      }
      userSalesModal.show()
    }
    const closeRotStrings = () => {
      userSalesModal.show()
      rotModal.hide()
    }
    const editUserSales = (index) => {
      userSalesIndex = index
      userSales.value = form.value.salesList[index]
      console.log(userSales.value)
      if (userSales.value.definitionId) {
        changeDefinition(userSales.value)
      }
      if (!userSales.value.registerMonthList || userSales.value.registerMonthList.length === 0) {
        userSales.value.registerMonthList = []
        for (let i = 0; i < userSales.value.registerFileCount; i++) {
          userSales.value.registerMonthList.push({
            monthsAgo: i + 1
          })
        }
      }
      for (const filter of userSales.value.batchRegisterFilterList) {
        const strings = filter.filterString.split('/')
        filter.batchRegisterFilterOrList = []
        strings.forEach(x => {
          filter.batchRegisterFilterOrList.push({
            orString: x
          })
        })
      }
      userSalesModal.show()
    }
    const addUserSales = () => {
      errors.value = []
      if (!userSales.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!userSales.value.definitionId) {
        errors.value.push('定義を選択してください')
      }
      if (!userSales.value.currencyId) {
        errors.value.push('先に通貨を登録して選択してください')
      }
      if (!userSales.value.registerFileCount) {
        errors.value.push('1期に取り込むファイル数を入力してください')
      }
      let matchType = 'album'
      for (const definitionItem of userSales.value.definitionItemList) {
        if (definitionItem.matching) {
          if (definitionItem.itemType !== 'catalog_no' && definitionItem.itemType !== 'jan_code' && definitionItem.itemType !== 'user_album_code') {
            matchType = 'master'
          }
        }
      }
      if (userSales.value.useTypeId === 'none' && matchType === 'master') {
        errors.value.push('原盤ともマッチする可能性のある定義を使用する場合は、利用種別は省略できません。')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (userSalesIndex === -1) {
          form.value.salesList.push(userSales.value)
        } else {
          form.value.salesList[userSalesIndex] = userSales.value
        }
        userSalesModal.hide()
      }
    }
    const addBatchRegisterFiler = () => {
      console.log(userSales.value)
      if (!userSales.value.batchRegisterFilterList) {
        userSales.value.batchRegisterFilterList = []
      }
      userSales.value.batchRegisterFilterList.push({
        filterType: 'file_name',
        filterString: '',
        batchRegisterFilterOrList: [{
          orString: ''
        }]
      })
    }
    const init = async (id) => {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          name: '',
          kana: '',
          nameEn: '',
          memo: '',
          salesList: []
        }
      } else {
        form.value = await userFunc.get(id)
        for (const userSales of form.value.salesList) {
          if (!userSales.useTypeId) {
            userSales.useTypeId = 'none'
          }
        }
      }
      const promises = []
      promises.push(useTypeFunc.find(session.session.company.id))
      promises.push(currencyFunc.findAll())
      promises.push(definitionFunc.findAll())
      const responses = await Promise.all(promises)
      usetypeList.value = responses[0]
      currencyList.value = responses[1]
      definitionList.value = responses[2]
    }
    const changeDefinition = async (userSales) => {
      userSales.definitionItemList = await definitionFunc.findItem(userSales.definitionId, userSales.id)
      if (!userSales.id) {
        for (const item of userSales.definitionItemList) {
          item.definitionItemId = item.id
          item.registerOrThrough = 'register'
          item.rotStrings = []
        }
      }
      userSales.usedMonthType = definitionList.value.filter(x => x.id === userSales.definitionId)[0].usedMonthType
    }
    const openRotStrings = (index) => {
      rotIndex = index
      const item = userSales.value.definitionItemList[index]
      if (item && item.rotStrings) {
        rotStrings.value = item.rotStrings.join('\n')
      } else {
        rotStrings.value = []
      }
      userSalesModal.hide()
      rotModal.show()
    }
    const setRotStrings = () => {
      if (rotStrings.value) {
        userSales.value.definitionItemList[rotIndex].rotStrings = rotStrings.value.split('\n')
      } else {
        userSales.value.definitionItemList[rotIndex].rotStrings = []
      }
      rotModal.hide()
      userSalesModal.show()
    }
    const changeRegisterFileCount = () => {
      if (userSales.value.registerFileCount > userSales.value.registerMonthList.length) {
        for (let i = userSales.value.registerMonthList.length; i < userSales.value.registerFileCount; i++) {
          userSales.value.registerMonthList.push({
            monthsAgo: 1
          })
        }
      } else {
        for (let i = userSales.value.registerFileCount; i < userSales.value.registerMonthList.length; i++) {
          userSales.value.registerMonthList.splice(i, 1)
        }
      }
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      userSalesModal = new Modal(userSalesModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rotModal = new Modal(rotModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      errors,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      rightAreaMode,
      save,
      openDeleteModal,
      executeDelete,
      userSalesModalDiv,
      userSales,
      openUserSalesModal,
      addUserSales,
      usetypeList,
      editUserSales,
      definitionList,
      currencyList,
      changeDefinition,
      openRotStrings,
      rotModalDiv,
      rotStrings,
      setRotStrings,
      closeRotStrings,
      changeRegisterFileCount,
      addBatchRegisterFiler
    }
  }
})
</script>
