<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center" style="margin-top: 15px;">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-address-card fa-fw" style="margin-right: 5px;"></i> 登録依頼一覧</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="downloadInvoicePdf" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDF</button>
              <router-link v-if="form && form.id" :to="'/green/rightsholder/external/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/green/rightsholder/external/edit/NEW" class="btn btn-outline-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div v-if="form" class="container text-start" style="margin-top: 20px;">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{form.name}}
              <span v-if="!form.name"><br></span>
            </div>
            <div v-if="form.externalId" class="form-label align-bottom">外部システム連携用ID</div>
            <div v-if="form.externalId" class="form-edit-line">
              {{form.externalId}}
              <span v-if="!form.externalId"><br></span>
            </div>
            <div v-if="form.memo" class="form-label align-bottom">メモ</div>
            <div v-if="form.memo" class="form-edit-line">
              <div class="alert alert-secondary" v-html="form.memo.replaceAll('\n', '<br>')" style="font-size: 90%;"></div>
            </div>
            <hr>
            <div v-if="invoice" class="alert alert-info">回答済</div>
            <div v-if="!invoice" class="alert alert-secondary">未回答</div>
            <div v-if="invoice" class="card" style="margin-bottom: 10px;">
              <div class="card-header">権利者登録状況</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive table-borderless" style="margin-bottom: 0;">
                  <tbody>
                  <tr>
                    <td style="width: 150px;">課税／免税</td>
                    <td>
                      <span v-if="invoice.taxationType === 'taxation'">課税事業者</span>
                      <span v-else>免税事業者</span>
                    </td>
                  </tr>
                  <tr v-if="invoice.taxationType === 'taxation'">
                    <td style="width: 150px;">事業者登録番号</td>
                    <td>
                      T{{invoice.invoiceNo}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr>
            <div class="form-label align-bottom">収集用URL</div>
            <div class="form-edit-line">
              <div class="input-group" style="margin-top: 10px;">
                <input v-model="invoiceUrl" type="text" class="form-control" disabled>
                <button @click="copyInvoiceUrl" class="btn btn-outline-primary"><i class="fas fa-copy"></i></button>
                <button @click="generateInvoiceUrl(true)" class="btn btn-outline-danger"><i class="fas fa-undo"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">宛先</div>
            <div class="form-edit-line">
              <input v-model="invoiceDestinationName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">挨拶文テンプレート</div>
            <div class="form-edit-line">
              <select @change="changeInvoiceTemplate" v-model="invoiceTemplate" class="form-select">
                <option value="">-</option>
                <option v-for="greeting in invoiceGreetingList" :key="greeting.id" :value="greeting.greeting">{{greeting.title}}</option>
              </select>
            </div>
            <div class="form-label align-bottom">挨拶文</div>
            <div class="form-edit-line">
              <textarea v-model="invoiceGreeting" class="form-control" rows="10"></textarea>
            </div>
            <hr v-if="invoice">
            <div v-if="invoice">
              <div class="form-label align-bottom">課税事業者／免税事業者</div>
              <div class="form-edit-line">
                <span v-if="invoice.taxationType === 'taxation'">課税事業者</span>
                <span v-else>免税事業者</span>
              </div>
              <div v-if="invoice.taxationType !== 'taxation'" class="form-label align-bottom">課税事業者への変更予定</div>
              <div v-if="invoice.taxationType !== 'taxation'" class="form-edit-line">
                <span v-if="invoice.toTaxationSchedule">あり</span>
                <span v-else>なし</span>
              </div>
              <div v-if="invoice.taxationType === 'taxation'" class="form-label align-bottom">事業者登録番号</div>
              <div v-if="invoice.taxationType === 'taxation'" class="form-edit-line fs-5">
                T{{invoice.invoiceNo}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { exFunction } from '@/functions/exception'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { useRoute } from 'vue-router'
import { Modal, Toast } from 'bootstrap'
import { contractFindFunction } from '@/views/common/contract/function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const auth = authFunction()
    const ex = exFunction()
    const session = ref(null)
    const processModalDiv = ref(null)
    let processModal = null
    const processMessage = ref('')
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    // Form
    const contractFunc = contractFindFunction()
    const form = ref(null)
    const invoiceUrl = ref('')
    const invoiceDestinationName = ref('')
    const invoiceGreetingList = ref([])
    const invoiceTemplate = ref('')
    const invoiceGreeting = ref('')
    const invoice = ref(null)
    const taxationType = ref('')
    const invoiceNo = ref('')
    // メソッド
    const init = async (id) => {
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        form.value = response.data
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + form.value.accountId + '/invoice', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          if (response.status === 200) {
            invoice.value = response.data
            console.log(invoice.value)
          }
        })
      }).catch(error => { ex.identifyEx(error) })
      await generateInvoiceUrl(false)
      invoiceDestinationName.value = form.value.name + ' 様'
      invoiceGreetingList.value = await contractFunc.findInvoiceGreeting()
    }
    const copyInvoiceUrl = () => {
      navigator.clipboard.writeText(invoiceUrl.value)
      messages.value = ['コピーされました']
      messageToast.show()
    }
    const generateInvoiceUrl = async (renew) => {
      let tokenUrl = '/external/rightsholder/' + form.value.id + '/token'
      if (renew) {
        tokenUrl = tokenUrl + '/renew'
      }
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + tokenUrl, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        console.log(response.data)
        invoiceUrl.value = response.data
      }).catch(error => {
        ex.identifyEx(error)
        throw error
      })
    }
    const changeInvoiceTemplate = () => {
      let greeting = ''
      if (invoiceTemplate.value) {
        greeting = invoiceTemplate.value
      }
      invoiceGreeting.value = greeting
    }
    const downloadInvoicePdf = async () => {
      processMessage.value = 'PDFファイルの作成中です。しばらくお待ちください。'
      processModal.show()
      const contract = await contractFunc.get()
      const { data } = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + form.value.id + '/pdf', {
        companyName: contract.name,
        destinationName: invoiceDestinationName.value,
        greeting: invoiceGreeting.value
      }, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      const a = document.createElement('a')
      a.href = data
      a.click()
      processModal.hide()
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        session.value = response.data
      }).catch(error => { ex.identifyEx(error) })
      await init(route.params.id)
    })
    return {
      processMessage,
      processModalDiv,
      messages,
      messageToastDiv,
      session,
      form,
      invoiceUrl,
      invoiceDestinationName,
      invoiceGreetingList,
      invoiceTemplate,
      invoiceGreeting,
      invoice,
      taxationType,
      invoiceNo,
      copyInvoiceUrl,
      generateInvoiceUrl,
      changeInvoiceTemplate,
      downloadInvoicePdf
    }
  }
})
</script>
