<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <i class="fas fa-user" style="margin-right: 5px;"></i>Myアカウント
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/account/edit'" class="btn btn-outline-primary"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line fs-5" style="font-weight: bold;">
              {{ form.name }}
            </div>
            <div class="form-label align-bottom">メールアドレス</div>
            <div class="form-edit-line">
              <table class="table table-responsive">
                <tbody>
                <tr v-for="email in emailList" :key="email.id">
                  <td>{{email.email}}</td>
                  <td class="text-end" style="width: 150px;">
                    <span v-if="email.defaultEmail" class="badge bg-primary" style="margin-right: 5px;">メイン</span>
                    <span v-if="email.public" class="badge bg-success">公開</span>
                    <span v-if="!email.public" class="badge bg-secondary">非公開</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="form.cellPhone" class="form-label align-bottom">携帯電話番号</div>
            <div v-if="form.cellPhone" class="form-edit-line">
              {{ form.cellPhone }}
            </div>
            <div v-if="form.cellPhone" class="form-label align-bottom">ログイン方法</div>
            <div v-if="form.cellPhone" class="form-edit-line">
              <span v-if="form.loginMethod === 'EMAIL'">メール</span>
              <span v-if="form.loginMethod === 'SMS'">SMS</span>
            </div>
            <div class="form-label align-bottom">お知らせのメール受信</div>
            <div class="form-edit-line">
              <div>
                <span v-if="form.receiveInformation">システム停止等のお知らせ</span>
                <span v-if="!form.receiveInformation" style="text-decoration: line-through;">システム停止等のお知らせ</span>
              </div>
              <div>
                <span v-if="form.receiveVersionInformation">バージョンアップのお知らせ</span>
                <span v-if="!form.receiveVersionInformation" style="text-decoration: line-through;">バージョンアップのお知らせ</span>
              </div>
            </div>
            <div v-show="session.session.red" class="form-label align-bottom">RED取込結果メール通知</div>
            <div v-show="session.session.red" class="form-edit-line">
              <span v-if="form.redImportNotify">受け取る</span>
              <span v-if="!form.redImportNotify">受け取らない</span>
            </div>
            <div v-if="invoice">
              <div class="form-label align-bottom">課税事業者／免税事業者</div>
              <div class="form-edit-line">
                <span v-if="invoice.taxationType === 'taxation'">課税事業者を選択</span>
                <span v-else>免税事業者を選択</span>
              </div>
              <div class="form-label align-bottom">事業者登録番号</div>
              <div class="form-edit-line">
                T{{invoice.invoiceNo}}
              </div>
              <div v-if="agent">
                <div class="form-label align-bottom">代理登録分</div>
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item" v-for="(superior, index) in superiorList" :key="superior.id">
                    <a @click="changeSuperior(index)" class="nav-link" :class="{ 'active': selectedSuperior === superior.id }" aria-current="page" href="#">
                      {{superior.name}}
                    </a>
                  </li>
                </ul>
                <div v-for="superior in superiorList" :key="superior.id">
                  <div v-if="selectedSuperior === superior.id">
                    <div v-if="superior.invoice" class="form-label align-bottom">課税事業者／免税事業者</div>
                    <div v-if="superior.invoice" class="form-value">
                      <span v-if="superior.invoice.taxationType === 'taxation'">課税事業者を選択</span>
                      <span v-else>免税事業者を選択</span>
                    </div>
                    <div v-if="superior.invoice && superior.invoice.taxationType === 'taxation'" class="form-label align-bottom">事業者登録番号</div>
                    <div v-if="superior.invoice && superior.invoice.taxationType === 'taxation'" class="form-value">
                      T{{superior.invoice.invoiceNo}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { accountFindFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const accountFind = accountFindFunction()
    const route = useRoute()
    const router = useRouter()
    // Form
    const form = ref(null)
    const emailList = ref([])
    const invoice = ref({})
    const agent = ref(false)
    const superiorList = ref([])
    const superior = ref({})
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      const promises = []
      promises.push(accountFind.get())
      promises.push(accountFind.findEmail())
      promises.push(accountFind.getInvoice())
      promises.push(accountFind.findSuperior())
      const response = await Promise.all(promises)
      form.value = response[0]
      emailList.value = response[1]
      invoice.value = response[2]
      superiorList.value = response[3]
      if (superiorList.value.length > 0) {
        agent.value = true
        superior.value = superiorList.value[0]
      } else {
        agent.value = false
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init()
    })
    return {
      session,
      route,
      router,
      form,
      emailList,
      invoice,
      agent,
      superior,
      superiorList
    }
  }
})
</script>
