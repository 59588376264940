import HelpContents from './Contents.vue'
import HelpDetail from './Detail.vue'
import HelpDownload from './Download.vue'
import HelpLogin from './Login'
import HelpMfa from './Mfa.vue'
import HelpMyaccountDisp from './MyaccountDisp.vue'
import HelpMyaccountEdit from './MyaccountEdit.vue'
import HelpMyaccountEmail from './MyaccountEmail.vue'
import HelpMyaccountMobile from './MyaccountMobile.vue'
import HelpMyaccountNotify from './MyaccountNotify.vue'
import HelpPolicy from './Policy'
import HelpPurpose from './Purpose'
import HelpReceive from './Receive'
import HelpRemember from './Remember.vue'
import HelpSecurity from './Security'
import HelpTerms from './Terms'

export default {
  routes: [
    {
      path: '/help',
      name: 'HelpContents',
      component: HelpContents
    },
    {
      path: '/help/terms',
      name: 'HelpTerms',
      component: HelpTerms
    },
    {
      path: '/help/policy',
      name: 'HelpPolicy',
      component: HelpPolicy
    },
    {
      path: '/help/purpose',
      name: 'HelpPurpose',
      component: HelpPurpose
    },
    {
      path: '/help/security',
      name: 'HelpSecurity',
      component: HelpSecurity
    },
    {
      path: '/help/login',
      name: 'HelpLogin',
      component: HelpLogin
    },
    {
      path: '/help/receive',
      name: 'HelpReceive',
      component: HelpReceive
    },
    {
      path: '/help/detail',
      name: 'HelpDetail',
      component: HelpDetail
    },
    {
      path: '/help/myaccount/disp',
      name: 'HelpMyaccountDisp',
      component: HelpMyaccountDisp
    },
    {
      path: '/help/myaccount/edit',
      name: 'HelpMyaccountEdit',
      component: HelpMyaccountEdit
    },
    {
      path: '/help/myaccount/email',
      name: 'HelpMyaccountEmail',
      component: HelpMyaccountEmail
    },
    {
      path: '/help/myaccount/mobile',
      name: 'HelpMyaccountMobile',
      component: HelpMyaccountMobile
    },
    {
      path: '/help/myaccount/notify',
      name: 'HelpMyaccountNotify',
      component: HelpMyaccountNotify
    },
    {
      path: '/help/mfa',
      name: 'HelpMfa',
      component: HelpMfa
    },
    {
      path: '/help/remember',
      name: 'HelpRemember',
      component: HelpRemember
    },
    {
      path: '/help/download',
      name: 'HelpDownload',
      component: HelpDownload
    }
  ]
}
