import RedUsetypeEdit from './Edit'
import RedUsetypeList from './List'

export default {
  routes: [
    {
      path: '/red/usetype/list',
      name: 'RedUsetypeList',
      component: RedUsetypeList
    },
    {
      path: '/red/usetype/edit',
      name: 'RedUsetypeEdit',
      component: RedUsetypeEdit
    }
  ]
}
