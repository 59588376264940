import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { paymentRecordStore } from './store'

export const paymentRecordFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const store = paymentRecordStore()
  const findByRightsHolder = async (rightsHolderId, withholdingTaxation, year) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/record/rightsholder/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        rights_holder_id: rightsHolderId,
        withholding_taxation: withholdingTaxation,
        year: year
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId) => {
    const params = {
      company_id: companyId,
      year: store.year,
      search_string: store.searchString,
      page: store.page,
      per_page: 20
    }
    if (store.findTarget !== 'all') {
      params.find_target = store.findTarget
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/record/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.findTarget = 'all'
    store.page = 0
  }
  return { find, findByRightsHolder, clear }
}
