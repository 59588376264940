<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i> 原盤一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/master/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件" :disabled="store.detailSearchMode">
            <span class="input-group-text">並び順</span>
            <select v-model="store.sortColumn" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 150px;" :disabled="store.detailSearchMode">
              <option value="name">名前</option>
              <option value="kana">カナ</option>
              <option value="artist">アーティスト</option>
            </select>
            <select v-model="store.sortOrder" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 160px;" :disabled="store.detailSearchMode">
              <option value="asc">昇順（A → Z）</option>
              <option value="desc">降順（Z → A）</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア" :disabled="store.detailSearchMode"><i class="fas fa-undo"></i></button>
            <button @click="store.detailSearchMode = true" class="btn btn-outline-primary" :disabled="store.detailSearchMode"><i class="fas fa-filter-list"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary" :disabled="store.detailSearchMode"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div v-if="store.detailSearchMode" class="alert border-dark bg-white" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="store.detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <label class="col-4 col-lg-4 col-xl-2 col-form-label">検索文字</label>
                  <div class="col-8 col-lg-8 col-xl-10">
                    <input v-model="store.detailCondition.searchString" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">アーティスト</label>
                  <div class="col-8">
                    <input v-model="store.detailCondition.artistName" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">コード</label>
                  <div class="col-8">
                    <input v-model="store.detailCondition.code" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">ISRC</label>
                  <div class="col-8">
                    <input v-model="store.detailCondition.isrc" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">利用者コード</label>
                  <div class="col-8">
                    <input v-model="store.detailCondition.userCode" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">支払／保留</label>
                  <div class="col-8">
                    <select v-model="store.detailCondition.suspension" class="form-select">
                      <option value="all">全て</option>
                      <option value="false">支払</option>
                      <option value="true">保留</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">保留状況</label>
                  <div class="col-8">
                    <select v-model="store.detailCondition.suspensionStatus" class="form-select">
                      <option value="all">全て</option>
                      <option value="has">保留金がある</option>
                      <option value="none">保留金がない</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">並び順①</label>
                  <div class="col-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn1" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="kana">カナ</option>
                        <option value="code">コード</option>
                        <option value="isrc">ISRC</option>
                        <option value="artist">アーティスト</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder1" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-4 col-form-label">並び順②</label>
                  <div class="col-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn2" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="kana">カナ</option>
                        <option value="code">コード</option>
                        <option value="isrc">ISRC</option>
                        <option value="artist">アーティスト</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder2" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end" style="margin-top: 10px;">
                <button @click="store.page = 0; find();" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
                <button @click="clearSearchDetail" class="btn btn-outline-success" title="クリア"><i class="fas fa-undo" style="margin-right: 5px;"></i>クリア</button>
              </div>
            </div>
          </div>
          <table v-if="masterResult && masterResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 60px;"></th>
                <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                <th class="d-none d-md-table-cell" style="width: 170px;">ISRC</th>
                <th>原盤名</th>
                <th class="d-none d-lg-table-cell">アーティスト</th>
              </tr>
            </thead>
            <tbody v-if="masterList">
              <tr v-for="master in masterList" :key="master.id" class="text-start align-middle">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/red/master/disp/' + master.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td class="d-none d-lg-table-cell">{{ master.code }}</td>
                <td class="d-none d-md-table-cell"><span v-html="master.isrc"></span></td>
                <td>{{ master.displayName }}</td>
                <td class="d-none d-lg-table-cell">{{ master.artistName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="masterResult && masterResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="masterResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ masterResult.allPages }}ページ</span></li>
                <li v-if="store.page !== masterResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== masterResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { masterStore } from './store'
import { masterFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const masterFind = masterFunction()
    // 検索
    const store = masterStore()
    const masterResult = ref(null)
    const masterList = ref([])
    // メソッド
    const find = async () => {
      if (store.detailSearchMode) {
        renderResults(await masterFind.findDetail(session.session.company.id, store))
      } else {
        renderResults(await masterFind.find(session.session.company.id, store))
      }
    }
    const clearSearch = () => {
      masterFind.clear(store)
      masterFind.detailClear(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = masterResult.value.allPages - 1
      find()
    }
    const renderResults = (result) => {
      masterResult.value = result
      masterList.value = masterResult.value.list
    }
    const clearSearchDetail = () => {
      masterFind.detailClear(store)
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      store,
      masterResult,
      masterList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      clearSearchDetail
    }
  }
})
</script>
