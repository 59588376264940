<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-door-closed fa-fw" style="margin-right: 5px;"></i> 期締め更新</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="openUpdateModal" class="btn btn-outline-danger"><i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>実行する</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container" style="max-width: 800px;">
          <div class="alert alert-danger text-start">
            期締め更新を実行すると、繰越額等が確定され<b>元に戻すことができなくなります</b>。現在期、更新後の期に間違いがないかご確認の上で実行ボタンを押してください。
          </div>
          <div class="form-label align-bottom fs-5">現在分配期</div>
          <div class="form-edit-line fs-5">
            <b>{{currentDivYear}}年{{currentDivTerm}}期</b>
          </div>
          <div class="form-label align-bottom fs-5">更新後分配期</div>
          <div class="form-edit-line fs-5">
            <b>{{nextDivYear}}年{{nextDivTerm}}期</b>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" ref="updateModalDiv">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">期締め更新の実行</h5>
            </div>
            <div class="modal-body text-start">
              <div class="alert alert-danger">
                期締め更新を実行すると、繰越額等が確定され元に<b>戻すことができなくなります</b>。現在期、更新後の期に間違いがないかご確認の上で実行ボタンを押してください。
                <p v-if="!existsDivResult" style="margin: 20px 0 0 0;" class="fs-5">
                  <i class="fas fa-exclamation-circle"></i><i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i><b>注意</b><i class="fas fa-exclamation-circle" style="margin-left: 5px;"></i><i class="fas fa-exclamation-circle"></i><br>
                  <span>現在期で一度も明細作成が行われていません。本当に期締めをして大丈夫か改めてご確認ください。</span>
                </p>
              </div>
              <div class="form-label align-bottom">現在分配期</div>
              <div class="form-edit-line">
                {{currentDivYear}}年{{currentDivTerm}}期
              </div>
              <div class="form-label align-bottom">更新後分配期</div>
              <div class="form-edit-line">
                {{nextDivYear}}年{{nextDivTerm}}期
              </div>
            </div>
            <div class="modal-footer d-flex">
              <div class="me-auto">
                <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
              </div>
              <div>
                <button @click="executeUpdate" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>注意事項を理解した上で期締め更新を実行する</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="completeModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">完了</h5>
          </div>
          <div class="modal-body text-start">
            期締め処理が完了しました
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
            </div>
            <div>
              <button @click="refresh" type="button" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-refresh" style="margin-right: 5px;"></i>画面を更新する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Modal, Toast } from 'bootstrap'
import { redCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const processModalDiv = ref(null)
    let processModal = null
    const processMessage = ref('')
    const errorToastDiv = ref(null)
    let errorToast = null
    const errors = ref([])
    const completeModalDiv = ref(null)
    let completeModal = null
    const updateModalDiv = ref(null)
    let updateModal = null
    // 検索
    const companyFunc = redCompanyFunction()
    const currentDivYear = ref(null)
    const currentDivTerm = ref(null)
    const nextDivYear = ref(null)
    const nextDivTerm = ref(null)
    const existsDivResult = ref(false)
    // メソッド
    const openUpdateModal = () => {
      updateModal.show()
    }
    const executeUpdate = async () => {
      errors.value = []
      processMessage.value = '期締め更新処理中です。しばらくこのままでお待ちください。'
      processModal.show()
      updateModal.hide()
      try {
        await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/term/update', {}, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            companyId: session.session.company.id
          }
        })
        await getTermInfo()
        completeModal.show()
      } catch (error) {
        console.error(error)
        errors.value = ['期締め更新処理でエラーが発生しました']
        errorToast.show()
      }
      processModal.hide()
    }
    const getTermInfo = async () => {
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      currentDivYear.value = divTerm.divYear
      currentDivTerm.value = divTerm.divTerm
      axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: session.session.company.id,
          divYear: currentDivYear.value,
          divTerm: currentDivTerm.value
        }
      }).then(response => {
        if (!response.data) {
          existsDivResult.value = false
        } else {
          existsDivResult.value = true
        }
      })
      const nextTerm = await companyFunc.getNextDivTerm(session.session.company.id)
      nextDivYear.value = nextTerm.divYear
      nextDivTerm.value = nextTerm.divTerm
    }
    const refresh = () => {
      completeModal.hide()
      router.push('/portal')
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      updateModal = new Modal(updateModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      completeModal = new Modal(completeModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      loadingModal.hide()
      await getTermInfo()
    })
    return {
      loadingModalDiv,
      processModalDiv,
      processMessage,
      errorToastDiv,
      errors,
      completeModalDiv,
      updateModalDiv,
      currentDivYear,
      currentDivTerm,
      nextDivYear,
      nextDivTerm,
      existsDivResult,
      openUpdateModal,
      executeUpdate,
      getTermInfo,
      refresh
    }
  }
})
</script>
