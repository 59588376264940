import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usageDetailStore = defineStore('usage_detail', () => {
  const searchString = ref('')
  const divYear = ref(0)
  const divTerm = ref(false)
  const page = ref(0)
  return { searchString, page, divYear, divTerm }
})
