import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const errorFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const findUnMatch = async (divTermId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUnMatchCount = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUseTypeErrorCount = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/error/usetype/count/all', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findUseTypeError = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/error/usetype/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const downloadUnMatch = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch/download', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const downloadNotDiv = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/error/download', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findNotDiv = async (divTermId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/error/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getNotDivCount = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/error/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const saveUseType = async (albumId, userSalesId, useTypeId) => {
    await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/album/' + albumId + '/sales/' + userSalesId + '/' + useTypeId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  return { findUnMatch, findNotDiv, downloadUnMatch, getUnMatchCount, getNotDivCount, getUseTypeErrorCount, findUseTypeError, saveUseType, downloadNotDiv }
}
