<template>
  <div>
    <div class="container-fluid no_print" style="margin-left: 5mm; width: 295mm;">
      <div class="row align-items-center" style="margin-top: 15px;">
        <div class="d-none d-md-block col-md-8 text-start">
          <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
            <ol v-if="route.path.indexOf('/red/receipt/print') === -1" class="breadcrumb" style="margin: 0;">
              <li class="breadcrumb-item">
                <router-link to="/red/payment/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>
                  支払明細一覧
                </router-link>
              </li>
              <li v-if="route.params.id !== 'ALL'" class="breadcrumb-item">
                <router-link :to="'/red/payment/disp/' + route.params.id"><i class="fas fa-eye fa-fw" style="margin-right: 5px;"></i>
                  閲覧
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-print" style="margin-right: 5px;"></i>印刷</li>
            </ol>
          </nav>
        </div>
        <div class="col d-flex justify-content-end">
          <button onclick="window.print()" class="btn btn-primary"><i class="fas fa-print fa-fw"></i></button>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <div v-for="(form, index) in formList" :key="index">
        <section v-if="form" class="sheet">
          <div class="row h-100">
            <div class="col-4" style="border-right: 1px dotted #333333!important;">
              <div class="text-start" style="margin-top: 15mm; margin-left: 7mm; font-size: 9pt;">
                <p v-if="form.statement_domestic_or_oversees === 'DOMESTIC'">
                  〒<span v-if="form.statement_postal_code">{{form.statement_postal_code.substring(0, 3)}}</span>-<span v-if="form.statement_postal_code">{{form.statement_postal_code.substring(3, 7)}}</span>
                </p>
                <p v-if="form.statement_domestic_or_oversees === 'DOMESTIC'">
                  {{form.statement_prefecture}}{{form.statement_city}}{{form.statement_town_area}}
                </p>
                <p v-if="form.statement_domestic_or_oversees === 'DOMESTIC' && form.statement_building">
                  {{form.statement_building}}
                </p>
                <p v-if="form.statement_domestic_or_oversees !== 'DOMESTIC'">
                  <span v-html="form.statement_address_en.replace('\n', '<br>')"></span>
                </p>
                <p style="margin-top: 10mm; font-size: 11pt;">
                  <span v-if="form.statement_honorific_position === 'BEFORE'">{{form.statement_honorific_title}}</span> {{form.statement_name}} <span v-if="form.statement_honorific_position === 'AFTER'">{{form.statement_honorific_title}}</span>
                </p>
                <div style="height: 30mm;"></div>
                <p v-if="form.invoice_no">
                  <span v-if="form.statement_lang === 'japanese'">事業者登録番号︎</span><span v-else>Registration number</span> : T{{form.invoice_no}}
                </p>
                <p v-if="form.rights_holder_name">
                  権利者 : {{form.rights_holder_name}}
                </p>
                <p v-if="form.statement_greeting" style="margin-top: 10mm; margin-right: 7mm;">
                  <span v-html="form.statement_greeting.replace('\n', '<br>')"></span>
                </p>
              </div>
            </div>
            <div v-if="divTerm" class="col-8">
              <div style="margin-top: 5mm; font-size: 16pt;">
                <span v-if="form.statement_lang === 'japanese'">
                  {{divTerm.div_year}}年{{divTerm.div_term}}期 印税支払明細書
                </span>
                <span v-else>
                  The {{divTerm.div_term}}<span v-if="divTerm.div_term === 1">st</span><span v-if="divTerm.div_term === 2">nd</span><span v-if="divTerm.div_term === 3">rd</span><span v-else>th</span> period of
                  {{divTerm.div_year}} ROYALTY STATEMENT
                </span>
              </div>
              <div v-if="divTermSetting">
                <span v-if="form.statement_lang === 'japanese'">
                  （計算対象期間：{{divTermSetting.start_year}}年{{divTermSetting.start_month}}月～{{divTermSetting.end_year}}年{{divTermSetting.end_month}}月）
                </span>
                <span v-else>
                  （Calculation period：{{new Intl.DateTimeFormat('en-US', {month: "short"}).format(new Date(divTermSetting.start_year, divTermSetting.start_month - 1))}} {{divTermSetting.start_year}} ～ {{new Intl.DateTimeFormat('en-US', {month: "short"}).format(new Date(divTermSetting.end_year, divTermSetting.end_month - 1))}} {{divTermSetting.end_year}}）
                </span>
              </div>
              <div class="text-start" style="margin-left: 5mm; margin-top: 10mm;">
                <span v-if="form.statement_lang === 'japanese'">
                  支払予定日：{{form.payment_year}}年{{form.payment_month}}月{{form.payment_day}}日
                </span>
                <span v-else>
                  Payment date：{{form.payment_date_en}}
                </span>
              </div>
              <div style="margin-left: 5mm; margin-top: 5mm; font-size: 9pt;">
                <table style="border: 1px solid #333333;">
                  <thead>
                  <tr>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333;"></th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (A) 前期繰越
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (A) Amount brought forward
                      </span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (B) 今期発生
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (B) Royalties in this term
                      </span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (C) 合計発生<br>
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (C) Total amount<br>
                      </span>
                      <span style="font-size: 8pt;">( A + B )</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (D) 管理手数料<br>
                        <span style="font-size: 8pt;">( C x 率 )</span>
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (D) Commission<br>
                        <span style="font-size: 8pt;">( C x rate )</span>
                      </span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (E) 税抜相殺
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (E) Amount recouped
                      </span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        (F) 課税対象<br>
                        <span style="font-size: 8pt;">( C - D - E )</span>
                      </span>
                      <span v-else style="font-size: 7pt;">
                        (F) Taxable amount<br>
                        <span style="font-size: 8pt;">( C - D - E )</span>
                      </span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        消費税<br>
                        <span style="font-size: 8pt;">( F x 税率 )</span>
                      </span>
                      <span v-else style="font-size: 7pt;">
                        Consumption tax<br>
                        <span style="font-size: 8pt;">( F x rate )</span>
                      </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tax in form.taxList" :key="tax.id">
                      <td style="background-color: #dddddd; padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.rate === 0">
                          <span v-if="form.statement_lang === 'japanese'">
                            非課税
                          </span>
                          <span v-else>0%</span>
                        </span>
                        <span v-else>{{tax.rate}}%</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.amount_brought_forward.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.amount_in_this_term.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.total_amount.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.management_fee_amount.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.offset_amount_out_tax.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.before_tax_amount.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333;">
                        <span v-if="tax.amount">{{tax.amount.tax_amount.toLocaleString()}}</span>
                        <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td style="background-color: #dddddd; padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        <span v-if="form.statement_lang === 'japanese'">
                          計
                        </span>
                        <span v-else>Total</span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        {{form.amount_brought_forward.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        {{form.amount_in_this_term.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        {{form.total_amount.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        {{form.management_fee_amount.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        {{form.offset_amount_out_tax.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        (G) {{form.before_tax_amount.toLocaleString()}}
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 20mm; border: 1px solid #333333; border-top: 2px double #333333;">
                        (H) {{form.tax_amount.toLocaleString()}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style="border: 1px solid #333333; margin-top: 2mm;">
                  <tbody>
                    <tr>
                      <td style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">
                        <span v-if="form.statement_lang === 'japanese'">
                          (I) 源泉徴収<br>
                          <span style="font-size: 8pt;">( G x 税率 )</span>
                        </span>
                        <span v-else style="font-size: 8pt;">
                          (I) Income tax withheld<br>
                          <span style="font-size: 8pt;">( G x rate )</span>
                        </span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                        {{form.withholding_tax_amount.toLocaleString()}}
                      </td>
                      <td style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">
                        <span v-if="form.statement_lang === 'japanese'">
                          (J) 税込相殺
                        </span>
                        <span v-else style="font-size: 8pt;">
                          (J) Amount recouped<br>(in tax)<br>
\                        </span>
                      </td>
                      <td class="text-end" style="padding: 5px 3px 5px 3px; width: 24mm; border: 1px solid #333333;">
                        {{form.offset_amount_in_tax.toLocaleString()}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style="border: 1px solid #333333; margin-top: 2mm;">
                  <tbody>
                    <tr>
                      <td style="background-color: #dddddd; font-weight: normal; padding: 7px 5px 7px 5px; border: 1px solid #333333; width: 20mm;">
                        <span v-if="form.pay_or_carry_forward === 'pay'">
                          <span v-if="form.statement_lang === 'japanese'">
                            支払<br>
                            <span style="font-size: 7pt;">( G + H - I - J )</span>
                          </span>
                          <span v-else style="font-size: 8pt;">
                            Net amount payable<br>
                            <span style="font-size: 7pt;">( G + H - I - J )</span>
                          </span>
                        </span>
                        <span v-else>次期繰越</span>
                      </td>
                      <td class="text-end" style="padding: 7px 5px 7px 5px; width: 168mm; border: 1px solid #333333; font-size: 12pt;">
                        <span v-if="form.pay_or_carry_forward === 'pay'">￥{{form.payment_amount.toLocaleString()}}</span>
                        <span v-else>￥{{form.amount_carried_forward_out_tax.toLocaleString()}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="form.statement_lang === 'japanese'" class="text-end" style="margin-left: 5mm; margin-right: 5mm; margin-top: 12mm;">
                <span v-if="form.pay_or_carry_forward !== 'pay'">課税対象額(G)が{{form.carried_forward_line.toLocaleString()}}円未満のため次期繰越とさせて頂きます。</span>
                <span v-else>&nbsp;</span>
              </div>
              <div v-else class="text-end" style="margin-left: 5mm; margin-right: 5mm; margin-top: 5mm;">
                <span v-if="form.pay_or_carry_forward === 'pay'">
                  Please be advised that if the total for royalties owed is less then JPY{{form.carried_forward_line.toLocaleString()}}<br>this will be carried forward to the next royalty period.
                </span>
                <span v-else>&nbsp;</span>
              </div>
              <div class="row">
                <div class="col-3">
<!--                  <div v-if="form.statement_lang === 'japanese'" class="text-start" style="margin-left: 5mm; margin-top: 25mm;">-->
<!--                    <img src="../../../assets/qr.png" style="width: 30mm;">-->
<!--                  </div>-->
<!--                  <div v-else class="text-start" style="margin-left: 5mm; margin-top: 15mm;">-->
<!--                    <img src="../../../assets/qr.png" style="width: 30mm;">-->
<!--                  </div>-->
                </div>
                <div class="col-9">
                  <div v-if="form.statement_lang === 'japanese'">
                    <div v-if="company" class="text-end" style="margin-left: 5mm; margin-right: 5mm; margin-top: 20mm; font-size: 9pt;">
                      <p>
                        <span v-if="company.statement_postal_code && company.statement_postal_code.length === 7">〒{{company.statement_postal_code.substr(0, 3)}}-{{company.statement_postal_code.substr(3, 7)}}</span>
                        {{company.statement_prefecture}}{{company.statement_city}}{{company.statement_town_area}}
                      </p>
                      <p>
                        {{company.statement_building}}
                      </p>
                      <p style="margin-top: 2mm; font-size: 11pt;">
                        {{company.statement_name}}
                      </p>
                      <p style="margin-top: 2mm;">
                        TEL : {{company.statement_tel}}
                      </p>
                      <p>
                        EMAIL : {{company.statement_email}}
                      </p>
                      <p v-if="company.invoice_no" style="margin-top: 2mm;">
                        事業者登録番号 : T{{company.invoice_no}}
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="company" class="text-end" style="margin-left: 5mm; margin-right: 5mm; margin-top: 10mm; font-size: 9pt;">
                      <p v-if="company.statement_address_en" v-html="company.statement_address_en.replace('\n', '<br>')">
                      </p>
                      <p style="margin-top: 2mm; font-size: 11pt;">
                        {{company.statement_name_en}}
                      </p>
                      <p style="margin-top: 2mm;">
                        TEL : {{company.statement_tel}}
                      </p>
                      <p>
                        EMAIL : {{company.statement_email}}
                      </p>
                      <p v-if="company.invoice_no" style="margin-top: 2mm;">
                        Registration number : T{{company.invoice_no}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div v-if="form && printDetail">
          <section v-for="(detailPage, index) in form.detailPageList" class="sheet" :key="index">
            <div class="row" style="padding: 5mm;">
              <div class="col-6 text-start">
                {{form.rights_holder_name}}
<!--                <span v-if="form.statement_honorific_position === 'BEFORE'">{{form.statement_honorific_title}}</span> {{form.statement_name}} <span v-if="form.statement_honorific_position === 'AFTER'">{{form.statement_honorific_title}}</span>-->
              </div>
              <div v-if="company && divTerm" class="col-6 text-end">
                <span v-if="form.statement_lang === 'japanese'">
                  {{divTerm.div_year}}年{{divTerm.div_term}}期 {{company.statement_name}}
                </span>
                <span v-else>
                  The {{divTerm.div_term}}<span v-if="divTerm.div_term === 1">st</span><span v-if="divTerm.div_term === 2">nd</span><span v-if="divTerm.div_term === 3">rd</span><span v-else>th</span> term of
                  {{divTerm.div_year}} {{company.statement_name_en}}
                </span>
              </div>
              <table style="font-size: 7pt; margin-top: 1mm;">
                <thead>
                  <tr>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 8mm;">※1</th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        タイトル
                      </span>
                      <span v-else>Title</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 30mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        アーティスト
                      </span>
                      <span v-else>Artist</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 30mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        品番<br>ISRC
                      </span>
                      <span v-else>Catalog number<br>ISRC</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 30mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        利用者
                      </span>
                      <span v-else>User</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        利用年月
                      </span>
                      <span v-else>Month</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 15mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        利用種別
                      </span>
                      <span v-else>Type of use</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 15mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        印税種別
                      </span>
                      <span v-else>Type of royalty</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 13mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        使用数
                      </span>
                      <span v-else>Number of use</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 13mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        計算<br>対象数
                      </span>
                      <span v-else>※2</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        受領額
                      </span>
                      <span v-else>Income amount</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 7mm;">※3</th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 13mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        印税率<br>分配率
                      </span>
                      <span v-else>Rate</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 10mm;">
                      ※4
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        支払額
                      </span>
                      <span v-else>Your share</span>
                    </th>
                    <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 12mm;">
                      <span v-if="form.statement_lang === 'japanese'">
                        消費税
                      </span>
                      <span v-else>Tax rate</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="detail in detailPage" :key="detail.id">
                    <td class="text-center" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.data_unit === 'master'">M</span>
                      <span v-else>A</span>
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 0;">
                      <span v-if="detail.data_unit === 'master'">{{detail.master_name}}</span>
                      <span v-else>{{detail.album_name}}</span>
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 0;">
                      <span v-if="detail.data_unit === 'master'">{{detail.master_artist_name}}</span>
                      <span v-else>{{detail.album_artist_name}}</span>
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 0;">
                      <span v-if="detail.data_unit === 'master'">{{detail.master_isrc}}</span>
                      <span v-else>{{detail.album_catalog_no}}</span>
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 0;">
                      {{detail.user_name}}
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="form.statement_lang === 'japanese'">
                        {{detail.used_year}}年{{detail.used_month}}月
                      </span>
                      <span v-else>
                        {{new Intl.DateTimeFormat('en-US', {month: "short"}).format(new Date(detail.used_year, detail.used_month - 1))}}
                        {{detail.used_year}}
                      </span>
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      {{detail.use_type_abbreviation}}
                    </td>
                    <td class="text-start" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      {{detail.royalty_type_abbreviation}}
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.used_no !== 0">{{detail.used_no.toLocaleString()}}</span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.calc_target_no !== 0">{{detail.calc_target_no.toLocaleString()}}</span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span>{{detail.income_amount.toLocaleString()}}</span>
                    </td>
                    <td class="text-center" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.contract_calc_type === 'unit_rate'">1</span>
                      <span v-if="detail.contract_calc_type === 'unit_price'">2</span>
                      <span v-if="detail.contract_calc_type === 'fix_price'">3</span>
                      <span v-if="detail.contract_calc_type === 'div_rate'">4</span>
                      <span v-if="detail.contract_calc_type === 'div_rate_revenue'">5</span>
                      <span v-if="detail.contract_calc_type === 'after_rate'">6</span>
                      <span v-if="detail.contract_calc_type === 'after_rate_revenue'">7</span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.contract_calc_type === 'unit_rate' || detail.contract_calc_type === 'div_rate' || detail.contract_calc_type === 'div_rate_revenue' || detail.contract_calc_type === 'after_rate' || detail.contract_calc_type === 'after_rate_revenue'">
                        <span v-if="detail.contract_royalty_rate_type === 'decimal'">
                          {{detail.contract_royalty_rate}}%
                        </span>
                        <span v-else>
                          {{detail.contract_royalty_rate_c}}/{{detail.contract_royalty_rate_m}}
                        </span>
                      </span>
                      <span v-if="detail.contract_calc_type === 'unit_price'">
                        {{detail.contract_unit_price.toLocaleString()}}
                      </span>
                      <span v-if="detail.contract_calc_type === 'fix_price'">
                        {{detail.contract_fix_price.toLocaleString()}}
                      </span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span v-if="detail.group_rights_holder">{{detail.member_share_c}}/{{detail.member_share_m}}</span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span>{{detail.payment_amount.toLocaleString()}}</span>
                    </td>
                    <td class="text-end" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                      <span>{{detail.tax_rate}}%</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span v-if="form.statement_lang === 'japanese'" class="text-start" style="margin-top: 1mm; font-size: 8pt;">※1 A : アルバム、M : 原盤 ※2 1 : 単価計算、2 : 固定単価、3 : 固定金額、4 : 分配率（受領額）、5 : 分配率（売上）、6 : 手残り計算（受領額）、7 : 手残り計算（売上） ※4 按分比率</span>
              <span v-else class="text-start" style="margin-top: 1mm; font-size: 8pt;">※1 A : Album、M : Master ※2 : Number of calculate target ※3 1 : Unit price、2 : Fixed unit price、3 : Fixed price、4 : Rate against income、5 : Rate against revenue、6/7 : Rate against after calculating the others ※4 Rate of distribution</span>
            </div>
          </section>
          <section v-if="duplexPrint && form.detailPageList.length % 2 === 0" class="sheet">
            <div>&nbsp;</div>
          </section>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-info fs-5" style="margin-top: 50px; margin-left: 30px; margin-right: 30px; padding: 30px;">ページを読み込み中です。しばらくこのままでお待ちください。</div>
      <div v-if="allCount > 0" style="margin-top: 10px;">{{loadCount}} / {{allCount}}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import auth from '@/functions/auth'
import exception from '@/functions/exception'
import axios from 'axios'

export default defineComponent({
  setup () {
    const route = useRoute()
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    // Form
    const loading = ref(true)
    const allCount = ref(0)
    const loadCount = ref(0)
    const printDetail = ref(true)
    const formList = ref([])
    const company = ref(null)
    const divTerm = ref(null)
    const divTermSetting = ref(null)
    const duplexPrint = ref(false)
    async function getForm (id) {
      let form = {}
      await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/' + id, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(async response => {
        form = response.data
        form.detailPageList = []
        form.taxList = []
        form.payment_year = form.payment_date.split('-')[0]
        form.payment_month = form.payment_date.split('-')[1]
        form.payment_day = form.payment_date.split('-')[2]
        form.payment_date_en = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(new Date(form.payment_year, form.payment_month - 1, form.payment_day))
        await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/divterm/' + form.company_div_term_id, {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(async response => {
          divTerm.value = response.data
          await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/' + divTerm.value.company_id + '/divterm/' + divTerm.value.div_term + '/setting', {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            divTermSetting.value = response.data
            if (divTermSetting.value.start_month_year_type === 'current') {
              divTermSetting.value.start_year = divTerm.value.div_year
            } else {
              divTermSetting.value.start_year = divTerm.value.div_year - 1
            }
            if (divTermSetting.value.end_month_year_type === 'current') {
              divTermSetting.value.end_year = divTerm.value.div_year
            } else {
              divTermSetting.value.end_year = divTerm.value.div_year + 1
            }
          })
        })
        await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/' + form.company_id, {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          company.value = response.data
        })
      }).catch(error => {
        identifyEx(error)
      })
      await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/tax/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(async response => {
        form.taxList = response.data
        for (const tax of form.taxList) {
          await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/' + id + '/tax/' + tax.id, {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            tax.amount = response.data
          })
        }
      }).catch(error => {
        identifyEx(error)
      })
      if (printDetail.value) {
        let p = 0
        while (true) {
          const d = await getPage(id, p)
          if (!d || d.length === 0) {
            break
          }
          form.detailPageList.push(d)
          p++
        }
      }
      return form
    }
    async function getPage (id, p) {
      const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/' + id + '/detail/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: {
          page: p,
          per_page: 33
        }
      })
      return data
    }
    onBeforeMount(() => {
      const style = document.createElement('style')
      style.innerHTML = '@page { size: A4 landscape!important; margin: 0; }'
      document.head.appendChild(style)
    })
    onMounted(async () => {
      duplexPrint.value = sessionStorage.getItem('duplexPrint') === 'true'
      if (route.path.indexOf('/red/receipt/print/all/') !== -1) {
        printDetail.value = true
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/sent/' + route.params.id + '/header/list', {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(async response => {
          allCount.value = response.data.length
          for (const header of response.data) {
            loadCount.value = loadCount.value + 1
            formList.value.push(await getForm(header.redPaymentHeaderId))
          }
        })
      } else {
        printDetail.value = (route.params.detail === 'true')
        if (route.params.id !== 'ALL') {
          formList.value.push(await getForm(route.params.id))
        } else {
          const paymentList = await find()
          allCount.value = paymentList.length
          for (const data of paymentList) {
            loadCount.value = loadCount.value + 1
            formList.value.push(await getForm(data.id))
          }
        }
      }
      await nextTick(() => {
        loading.value = false
      })
    })
    const find = async () => {
      const params = {
        payment_status_id: sessionStorage.getItem('payment_statusId'),
        search_string: sessionStorage.getItem('payment_searchString'),
        page: 0,
        per_page: 2147483647
      }
      if (sessionStorage.getItem('payment_payOrCarryForward') !== 'ALL') {
        params.pay_or_carry_forward = sessionStorage.getItem('payment_payOrCarryForward')
      }
      const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: params
      }).catch(error => { identifyEx(error) })
      if (data.list) {
        return data.list
      } else {
        return []
      }
    }
    return {
      route,
      printDetail,
      formList,
      company,
      divTerm,
      divTermSetting,
      loading,
      loadCount,
      allCount,
      duplexPrint,
      find
    }
  }
})
</script>

<style scoped="scoped">
* {
  margin: 0;
  padding: 0;
}
@media print {
  body {
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', sans-serif!important;
    width: 297mm;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    line-height: 1.5em;
  }
  .no_print {
    display: none;
  }
}
.sheet {
  width: 297mm;
  height: 209.5mm;
  page-break-after: always;
  box-sizing: border-box;
  padding: 0;
  font-size: 10pt;
}
@media screen {
  body {
    background: #eee;
  }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}
</style>
