import CommonContractDisp from './Disp'
import CommonContractEdit from './Edit'

export default {
  routes: [
    {
      path: '/common/contract/disp',
      name: 'CommonContractDisp',
      component: CommonContractDisp
    },
    {
      path: '/common/contract/edit',
      name: 'CommonContractEdit',
      component: CommonContractEdit
    }
  ]
}
