<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/rightsholder/list"><i class="fas fa-copyright fa-fw" style="margin-right: 5px;"></i>
                      権利者一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
                <ol v-if="route.path.includes('/red/result/rightsholder/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/result/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>リアルタイム結果一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/result/disp/' + route.params.resultId">
                      <i class="fas fa-eye" style="margin-right: 5px;"></i>結果詳細
                    </router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/rightsholder/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link to="/red/rightsholder/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div v-if="form.code" class="form-label align-bottom">コード</div>
              <div v-if="form.code" class="form-edit-line">
                {{ form.code }}
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div v-if="form.kana" class="form-label align-bottom">カナ</div>
              <div v-if="form.kana" class="form-edit-line">
                {{ form.kana }}
              </div>
              <div v-if="!form.groupRightsHolder && form.domesticOrOversees === 'DOMESTIC' && (form.postalCode || form.prefecture || form.ciry || form.townArea || form.building)" class="form-label align-bottom">住所</div>
              <div v-if="!form.groupRightsHolder && form.postalCode || form.prefecture || form.ciry || form.townArea || form.building" class="form-edit-line">
                <p v-if="form.postalCode" style="margin: 0;">〒{{ form.postalCode }}</p>
                <p style="margin: 0;">{{ form.prefecture }}{{ form.city }}{{ form.townArea }}</p>
                <p style="margin: 0;">{{ form.building }}</p>
              </div>
              <div v-if="!form.groupRightsHolder && form.domesticOrOversees === 'OVERSEES' && form.addressEn" class="form-label align-bottom">住所</div>
              <div v-if="!form.groupRightsHolder && form.domesticOrOversees === 'OVERSEES' && form.addressEn" class="form-edit-line">
                <span v-html="form.addressEn.replaceAll('\n', '<br>')"></span>
              </div>
              <div v-if="!form.groupRightsHolder && form.tel" class="form-label align-bottom">電話番号</div>
              <div v-if="!form.groupRightsHolder && form.tel" class="form-edit-line">
                {{ form.tel }}
              </div>
              <div v-if="!form.groupRightsHolder && form.email" class="form-label align-bottom">メールアドレス</div>
              <div v-if="!form.groupRightsHolder && form.email" class="form-edit-line">
                {{ form.email }}
              </div>
              <div v-if="!form.groupRightsHolder" class="card" style="margin-bottom: 10px;" id="calcDiv">
                <div class="card-header">計算</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <div class="form-label align-bottom">支払／保留</div>
                  <div class="form-edit-line">
                    <span v-if="form.payOrCarryForward === 'pay'">支払</span>
                    <span v-if="form.payOrCarryForward === 'carry_forward'">保留（{{form.carryForwardReason}}）</span>
                  </div>
                  <div class="form-label align-bottom">消費税</div>
                  <div class="form-edit-line">
                    <span v-if="form.taxation">課税</span>
                    <span v-else>非課税</span>
                  </div>
                  <div class="form-label align-bottom">源泉税率</div>
                  <div class="form-edit-line">
                    <span v-if="form.withholdingTaxation === 'personal'">個人（10.21% 〜 20.42%）</span>
                    <span v-if="form.withholdingTaxation === 'fix'">定率（{{form.withholding_rate}}%）</span>
                    <span v-if="form.withholdingTaxation === 'none'">非課税</span>
                  </div>
                  <div class="form-label align-bottom">管理手数料率</div>
                  <div class="form-edit-line">
                    {{form.managementFeeRate}}%
                  </div>
                  <div class="form-label align-bottom">少額保留下限額</div>
                  <div class="form-edit-line">
                    ￥{{form.carriedForwardLine.toLocaleString()}}
                  </div>
                  <div class="form-label align-bottom">期設定</div>
                  <table v-if="form.divTermList" class="table table-responsive" style="max-width: 400px;">
                    <thead>
                    <tr>
                      <th style="width: 40px;">期</th>
                      <th>利用月</th>
                      <th style="width: 80px;">支払対象</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="divTermSetting in form.divTermList" :key="divTermSetting.divTerm">
                      <td>{{divTermSetting.divTerm}}</td>
                      <td>
                        <span v-if="divTermSetting.startMonthYearType === 'previous'">前年</span>
                        <span v-if="divTermSetting.startMonthYearType === 'current'">当年</span>
                        {{divTermSetting.startMonth}}月
                        〜
                        <span v-if="divTermSetting.endMonthYearType === 'current'">当年</span>
                        <span v-if="divTermSetting.endMonthYearType === 'next'">翌年</span>
                        {{divTermSetting.endMonth}}月
                      </td>
                      <td class="text-center">
                        <span v-if="divTermSetting.target">
                          <i class="fas fa-check-square fa-fw"></i>
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="!form.groupRightsHolder" class="card" style="margin-bottom: 10px;" id="statementDiv">
                <div class="card-header">支払計算書</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <div class="form-label align-bottom">計算書言語</div>
                  <div class="form-edit-line">
                    <span v-if="form.statementLang === 'japanese'">日本語</span>
                    <span v-if="form.statementLang === 'english'">英語</span>
                  </div>
                  <div class="form-label align-bottom">明細PDFフォーマット</div>
                  <div class="form-edit-line">
                    <span v-if="form.paymentPdfDefinitionId">{{form.paymentPdfDefinitionName}}</span>
                    <span v-else>標準</span>
                  </div>
                  <div class="form-label align-bottom">明細データフォーマット</div>
                  <div class="form-edit-line">
                    <span v-if="form.paymentDataDefinitionId">{{form.paymentDataDefinitionName}}</span>
                    <span v-else>標準</span>
                  </div>
                  <div class="form-label align-bottom">0円明細</div>
                  <div class="form-edit-line">
                    <span v-if="form.statementDetailZeroOmit">出力しない</span>
                    <span v-if="!form.statementDetailZeroOmit">出力する</span>
                  </div>
                  <div class="form-label align-bottom">明細書PDF一括DLの対象</div>
                  <div class="form-edit-line">
                    <span v-if="form.omitAllPdf">対象外とする</span>
                    <span v-if="!form.omitAllPdf">対象とする</span>
                  </div>
                  <div class="form-label align-bottom">支払予定日の設定</div>
                  <div class="form-edit-line">
                    <span v-if="form.usePaymentDateSetting">個別設定</span>
                    <span v-if="!form.usePaymentDateSetting">会社の設定</span>
                  </div>
                  <div v-if="form.usePaymentDateSetting" class="form-edit-line">
                    <span v-if="form.paymentDateType === 'last'">末日</span>
                    <span v-else>{{form.paymentDate}}日</span>
                  </div>
                  <div class="card" style="margin-bottom: 10px;">
                    <div class="card-header">インボイス制度</div>
                    <div class="card-body" style="padding: 10px 5px 5px 10px;">
                      <div class="form-label align-bottom">課税・免税事業者</div>
                      <div class="form-edit-line">
                        <span v-if="form.taxationType === 'taxation'">課税事業者</span>
                        <span v-else>免税事業者</span>
                      </div>
                      <div v-if="form.taxationType === 'exemption'" class="form-label align-bottom">課税事業者への変更予定</div>
                      <div v-if="form.taxationType === 'exemption'" class="form-edit-line">
                        <span v-if="form.toTaxationSchedule === true">あり</span>
                        <span v-else>なし</span>
                      </div>
                      <div v-if="form.taxationType === 'taxation' && form.invoiceNo" class="form-label align-bottom">インボイス事業者登録番号</div>
                      <div v-if="form.taxationType === 'taxation' && form.invoiceNo" class="form-edit-line">
                        T{{ form.invoiceNo }}
                      </div>
                      <div v-if="form.taxationType === 'exemption'" class="form-label align-bottom">免税事業者に対する扱い</div>
                      <div v-if="form.taxationType === 'exemption'" class="form-edit-line">
                        <span v-if="form.useRightsHolderSetting">権利者で個別設定</span>
                        <span v-else>会社の設定に従う</span>
                      </div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting" class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting" class="form-edit-line">
                        <span v-if="form.invoiceExemptionTaxPayable==='pay'">課税事業者と同様に全額支払う</span>
                        <span v-if="form.invoiceExemptionTaxPayable==='deduct'">消費税相当額を支払額から控除する</span>
                      </div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                        <span v-if="form.invoiceSmallException==='adapt'">対応する</span>
                        <span v-if="form.invoiceSmallException==='not_adapt'">対応しない</span>
                      </div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
                      <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                        <span v-if="form.invoiceTransitionalMeasures==='adapt'">対応する</span>
                        <span v-if="form.invoiceTransitionalMeasures==='not_adapt'">対応しない</span>
                      </div>
                    </div>
                  </div>
                  <div class="card" style="margin-bottom: 10px;">
                    <div class="card-header">送付先</div>
                    <div class="card-body" style="padding: 10px 5px 5px 10px;">
                      <div class="form-label align-bottom">計算書送付方法</div>
                      <div class="form-edit-line">
                        <span v-if="form.statementSendType === 'online'">オンライン</span>
                        <span v-if="form.statementSendType === 'paper'">郵送</span>
                      </div>
                      <div v-if="form.statementSendType === 'online'" class="form-label align-bottom">
                        GREEN送付先
                      </div>
                      <div v-if="form.statementSendType === 'online'" class="form-edit-line">
                        <table class="table table-responsive">
                          <tbody>
                          <tr v-for="(destination, index) in form.destinationList" :key="index">
                            <td>
                              <router-link :to="'/green/destination/disp/' + destination.destinationId">{{destination.destinationName}}</router-link>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="form.statementAddresseeType === 'master'" class="form-label align-bottom">宛先</div>
                      <div v-if="form.statementAddresseeType === 'master'" class="form-edit-line">
                        <router-link :to="'/common/addressee/disp/' + form.statementAddresseeId">{{form.statementAddresseeName}}</router-link>
                      </div>
                      <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">宛名</div>
                      <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                        <span v-if="form.statementHonorificPosition === 'BEFORE'">{{form.statementHonorificTitle}}&nbsp;</span>
                        {{ form.statementName }}
                        <span v-if="form.statementHonorificPosition === 'AFTER'">&nbsp;{{form.statementHonorificTitle}}</span>
                      </div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statement_domesticOrOversees === 'DOMESTIC' && (form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding)" class="form-label align-bottom">宛先</div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statement_domesticOrOversees === 'DOMESTIC' && (form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding)" class="form-edit-line">
                        <p v-if="form.statementPostalCode" style="margin: 0;">〒{{ form.statementPostalCode }}</p>
                        <p style="margin: 0;">{{ form.statementPrefecture }}{{ form.statementCity }}{{ form.statementTownArea }}</p>
                        <p style="margin: 0;">{{ form.statementBuilding }}</p>
                      </div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statement_domesticOrOversees === 'OVERSEES' && form.statementAddressEn" class="form-label align-bottom">宛先</div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statement_domesticOrOversees === 'OVERSEES' && form.statementAddressEn" class="form-edit-line">
                        <span v-html="form.statementAddressEn.replaceAll('\n', '<br>')"></span>
                      </div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statementTel" class="form-label align-bottom">電話番号</div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statementTel" class="form-edit-line">
                        {{ form.statementTel }}
                      </div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statementEmail" class="form-label align-bottom">メールアドレス</div>
                      <div v-if="form.statementAddresseeType === 'input' && form.statementEmail" class="form-edit-line">
                        {{ form.statementEmail }}
                      </div>
                    </div>
                  </div>
                  <div v-if="form.statementGreeting" class="form-label align-bottom">挨拶文</div>
                  <div v-if="form.statementGreeting" class="form-edit-line">
                    <span v-html="form.statementGreeting.replaceAll('\n', '<br>')"></span>
                  </div>
                </div>
              </div>
              <div v-if="!form.groupRightsHolder" class="card" style="margin-bottom: 10px;">
                <div class="card-header">支払調書</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <div v-if="form.paymentAddresseeType === 'master'" class="form-label align-bottom">宛先</div>
                  <div v-if="form.paymentAddresseeType === 'master'" class="form-edit-line">
                    <router-link :to="'/common/addressee/disp/' + form.paymentAddresseeId">{{form.paymentAddresseeName}}</router-link>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-label align-bottom">宛名</div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <span v-if="form.paymentHonorificPosition === 'BEFORE'">{{form.paymentHonorificTitle}}&nbsp;</span>
                    {{ form.paymentName }}
                    <span v-if="form.paymentHonorificPosition === 'AFTER'">&nbsp;{{form.paymentHonorificTitle}}</span>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input' && (form.paymentPostalCode || form.paymentPrefecture || form.paymentCity || form.paymentTownArea || form.paymentBuilding)" class="form-label align-bottom">住所</div>
                  <div v-if="form.paymentAddresseeType === 'input' && (form.paymentPostalCode || form.paymentPrefecture || form.paymentCity || form.paymentTownArea || form.paymentBuilding)" class="form-edit-line">
                    <p v-if="form.paymentPostalCode" style="margin: 0;">〒{{ form.paymentPostalCode }}</p>
                    <p style="margin: 0;">{{ form.paymentPrefecture }}{{ form.payment_city }}{{ form.paymentTownArea }}</p>
                    <p style="margin: 0;">{{ form.paymentBuilding }}</p>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input' && form.paymentTel" class="form-label align-bottom">電話番号</div>
                  <div v-if="form.paymentAddresseeType === 'input' && form.paymentTel" class="form-edit-line">
                    {{ form.paymentTel }}
                  </div>
                  <div v-if="form.paymentGreeting" class="form-label align-bottom">挨拶文</div>
                  <div v-if="form.paymentGreeting" class="form-edit-line">
                    <span v-html="form.paymentGreeting.replaceAll('\n', '<br>')"></span>
                  </div>
                </div>
              </div>
              <div v-if="!form.groupRightsHolder" class="card" style="margin-bottom: 10px;">
                <div class="card-header">振込先</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <div v-if="form.payeeType === 'master'" class="form-label align-bottom">振込先</div>
                  <div v-if="form.payeeType === 'master'" class="form-edit-line">
                    <router-link :to="'/common/payee/disp/' + form.payeeId">{{form.payeeName}}</router-link>
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankName" class="form-label align-bottom">銀行</div>
                  <div v-if="form.payeeType === 'input' && form.bankName" class="form-edit-line">
                    {{ form.bankName }}（{{ form.bankCode }}）
                  </div>
                  <div v-if="form.payeeType === 'input' && form.branchName" class="form-label align-bottom">支店</div>
                  <div v-if="form.payeeType === 'input' && form.branchName" class="form-edit-line">
                    {{ form.branchName }}（{{ form.branchCode }}）
                  </div>
                  <div v-if="form.payeeType === 'input' && form.accountNo" class="form-label align-bottom">預金種目</div>
                  <div v-if="form.payeeType === 'input' && form.accountNo" class="form-edit-line">
                    <span v-if="form.depositItem === '1'">普通</span>
                    <span v-if="form.depositItem === '2'">当座</span>
                    <span v-if="form.depositItem === '4'">貯蓄</span>
                    <span v-if="form.depositItem === '9'">その他</span>
                  </div>
                  <div v-if="form.payeeType === 'input' && form.accountNo" class="form-label align-bottom">口座番号</div>
                  <div v-if="form.payeeType === 'input' && form.accountNo" class="form-edit-line">
                    {{ form.accountNo }}
                  </div>
                  <div v-if="form.payeeType === 'input' && form.accountName" class="form-label align-bottom">口座名義</div>
                  <div v-if="form.payeeType === 'input' && form.accountName" class="form-edit-line">
                    {{ form.accountName }}
                  </div>
                  <div v-if="form.payeeType === 'input' && form.accountNana" class="form-label align-bottom">口座名義カナ</div>
                  <div v-if="form.payeeType === 'input' && form.accountNana" class="form-edit-line">
                    {{ form.accountNana }}
                  </div>
                </div>
              </div>
              <div v-if="!form.groupRightsHolder && form.suspensionList && form.suspensionList.length > 0" class="card" style="margin-bottom: 10px;">
                <div class="card-header">保留状況</div>
                <div class="card-body" style="padding: 5px;">
                  <table class="table table-responsive" style="max-width: 250px;">
                    <tbody>
                    <tr v-for="suspension in form.suspensionList" :key="suspension.id">
                      <td style="width: 100px;">{{suspension.taxRate}}%</td>
                      <td class="text-end" v-if="suspension.suspensionAmount">￥{{suspension.suspensionAmount.toLocaleString()}}</td>
                      <td class="text-end" v-else>￥0</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="advanceList && advanceList.length > 0" class="card" style="margin-bottom: 10px;">
                <div class="card-header">アドバンス</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <table class="table table-responsive">
                    <thead>
                    <tr>
                      <th style="width: 140px;">種類</th>
                      <th style="width: 120px;">課税・非課税</th>
                      <th>金額</th>
                      <th>相殺額</th>
                      <th>残額</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(ad, index) in advanceList" :key="index">
                      <td>
                        <span v-if="ad.advanceType === 'rights_holder'">権利者</span>
                        <span v-if="ad.advanceType === 'album'">アルバム</span>
                        <span v-if="ad.advanceType === 'master'">原盤</span>
<!--                        <button v-if="ad.advanceType === 'album'" @click="openAlbumList(index)" class="btn btn-sm btn-outline-primary" style="margin-left: 5px;"><i class="fas fa-cog"></i></button>-->
<!--                        <button v-if="ad.advanceType === 'master'" @click="openMasterList(index)" class="btn btn-sm btn-outline-primary" style="margin-left: 5px;"><i class="fas fa-cog"></i></button>-->
                      </td>
                      <td>
                        <span v-if="ad.taxation === true|| ad.taxation === 'true'">課税</span>
                        <span v-else>非課税</span>
                      </td>
                      <td>￥{{ad.paymentAmount.toLocaleString()}}</td>
                      <td>￥{{ad.offsetAmount.toLocaleString()}}</td>
                      <td>￥{{(ad.paymentAmount - ad.offsetAmount).toLocaleString()}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="form.groupRightsHolder" class="card" style="margin-bottom: 10px;">
                <div class="card-header">メンバー</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <table v-if="form && form.memberList" class="table table-responsive">
                    <thead>
                    <tr>
                      <th>権利者</th>
                      <th>比率</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="member in form.memberList" :key="member.id" :class="{ 'deleted-row': member.deleted }">
                      <td style="vertical-align: middle;">
                        <p v-if="member.memberCode" style="margin: 0 0 3px 0; font-size: 80%;">{{member.memberCode}}</p>
                        <p style="margin: 0;">
                          <router-link :to="'/red/rightsholder/disp/' + member.memberRightsHolderId">
                            {{ member.memberName }}
                          </router-link>
                        </p>
                      </td>
                      <td style="width: 90px;" class="text-end">{{member.share}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <span v-html="form.memo.replaceAll('\n', '<br>')"></span>
              </div>
              <div style="height: 50px;"></div>
            </div>
            <div class="col-12 col-lg-5">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'CONTRACT'" class="nav-link" :class="{ 'active': rightAreaMode === 'CONTRACT' }" aria-current="page" href="#">
                    契約書
                  </a>
                </li>
<!--                <li class="nav-item">-->
<!--                  <a @click="rightAreaMode = 'SUMMARY'" class="nav-link" :class="{ 'active': rightAreaMode === 'SUMMARY' }" aria-current="page" href="#">-->
<!--                    累計-->
<!--                  </a>-->
<!--                </li>-->
              </ul>
              <div v-if="rightAreaMode === 'CONTRACT'">
                <div class="row">
                  <div class="input-group">
                    <input v-model="contractCondition.searchString" @keydown.enter="findContract" type="text" class="form-control">
                    <button @click="findContract" class="btn btn-outline-primary"><i class="fas fa-search"></i></button>
                  </div>
                </div>
                <table v-if="form.contractList && form.contractList.length > 0" class="table table-responsive table-hover">
                  <tbody>
                  <tr v-for="contract in form.contractList" :key="contract.id" class="align-middle">
                    <td style="width: 55px;">
                      <button @click="openContractRefModal(contract.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    </td>
                    <td>
                      <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
                      {{contract.name}}
                      <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
              </div>
              <div v-if="rightAreaMode === 'SUMMARY'">
                <table v-if="form.paymentList && form.paymentList.length > 0">
                  <thead>
                    <tr>
                      <th style="width: 150px;">分配期</th>
                      <th>税抜支払金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="payment in form.paymentList" :key="payment.id">
                      <td>{{payment.divYear}}年{{payment.divTerm}}期</td>
                      <td class="text-end">{{payment.paymentAmount.toLocaleString()}}</td>
                    </tr>
                    <tr v-if="form.sum_paymentAmount" class="border-top">
                      <td>合計</td>
                      <td class="text-end">{{form.sum_paymentAmount.toLocaleString()}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="contractRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refContractId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書詳細</h5>
          </div>
          <div class="modal-body text-start">
            <contract-modal :contract-id="refContractId"></contract-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeContractRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoContract(refContractId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>契約書画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { rightsHolderFunction } from './function'
import { contractFunction } from '../contract/function'
import { destinationFunction } from '@/views/green/destination/function'
import { addresseeFunction } from '../../common/addressee/function'
import { payeeFunction } from '../../common/payee/function'
import { contractStore } from './store'
import contractModal from '@/components/modal/ContractModal'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { contractModal, footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const rightsHolderFunc = rightsHolderFunction()
    const route = useRoute()
    const router = useRouter()
    const destinationFunc = destinationFunction()
    const contractFunc = contractFunction()
    const contractCondition = contractStore()
    const addresseeFunc = addresseeFunction()
    const payeeFunc = payeeFunction()
    // Form
    const form = ref(null)
    const bankCode = ref('')
    const bankName = ref('')
    const branchCode = ref('')
    const branchName = ref('')
    const rightAreaMode = ref('CONTRACT')
    const oldRoute = route.path
    const advanceList = ref([])
    // 契約書詳細
    const contractRefModalDiv = ref({})
    let contractRefModal = null
    const refContractId = ref('')
    // メソッド
    const init = async (id) => {
      form.value = await rightsHolderFunc.get(id)
      await findContract()
      advanceList.value = await rightsHolderFunc.findAdvance(form.value.id)
      if (form.value.statementAddresseeType === 'master') {
        const addressee = await addresseeFunc.get(form.value.statementAddresseeId)
        form.value.statementAddresseeName = addressee.name
      }
      if (form.value.paymentAddresseeType === 'master') {
        const addressee = await addresseeFunc.get(form.value.paymentAddresseeId)
        form.value.paymentAddresseeName = addressee.name
      }
      if (form.value.payeeType === 'master') {
        const payee = payeeFunc.get(form.value.payeeId)
        form.value.payeeName = payee.name
      }
      if (form.value.statementSendType === 'online') {
        form.value.destinationList = await destinationFunc.findRed(form.value.id)
      }
    }
    const findContract = async () => {
      form.value.contractList = await contractFunc.findByRightsHolder(form.value.id, contractCondition)
    }
    const openContractRefModal = async (contractId) => {
      refContractId.value = contractId
      contractRefModal.show()
    }
    const closeContractRefModal = () => {
      refContractId.value = null
      contractRefModal.hide()
    }
    const gotoContract = (id) => {
      contractRefModal.hide()
      router.push('/red/contract/disp/' + id)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      contractRefModal = new Modal(contractRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    watch(route, () => {
      if (route.path !== oldRoute && route.path.includes('/rightsholder/disp/')) {
        init(route.params.id)
      }
    })
    return {
      route,
      form,
      bankCode,
      bankName,
      branchCode,
      branchName,
      rightAreaMode,
      advanceList,
      contractCondition,
      findContract,
      openContractRefModal,
      closeContractRefModal,
      gotoContract,
      contractRefModalDiv,
      refContractId
    }
  }
})
</script>
