import GreenOrganizationList from './List'
import GreenOrganizationDisp from './Disp'
import GreenOrganizationEdit from './Edit'
import GreenOrganizationJoin from './Join'

export default {
  routes: [
    {
      path: '/green/organization/list',
      name: 'GreenOrganizationList',
      component: GreenOrganizationList
    },
    {
      path: '/green/organization/disp/:id',
      name: 'GreenOrganizationDisp',
      component: GreenOrganizationDisp
    },
    {
      path: '/green/organization/edit/:id',
      name: 'GreenOrganizationEdit',
      component: GreenOrganizationEdit
    },
    {
      path: '/green/organization/add',
      name: 'GreenOrganizationAdd',
      component: GreenOrganizationEdit
    },
    {
      path: '/green/organization/join',
      name: 'GreenOrganizationJoin',
      component: GreenOrganizationJoin
    }
  ]
}
