import GreenDestinationDisp from './Disp'
import GreenDestinationEdit from './Edit'
import GreenDestinationList from './List'

export default {
  routes: [
    {
      path: '/green/destination/list',
      name: 'GreenDestinationList',
      component: GreenDestinationList
    },
    {
      path: '/green/destination/disp/:id',
      name: 'GreenDestinationDisp',
      component: GreenDestinationDisp
    },
    {
      path: '/green/destination/add',
      name: 'GreenDestinationAdd',
      component: GreenDestinationEdit
    },
    {
      path: '/green/destination/edit/:id',
      name: 'GreenDestinationEdit',
      component: GreenDestinationEdit
    }
  ]
}
