import CommonPayeeDisp from './Disp'
import CommonPayeeEdit from './Edit'
import CommonPayeeList from './List'

export default {
  routes: [
    {
      path: '/common/payee/list',
      name: 'CommonPayeeList',
      component: CommonPayeeList
    },
    {
      path: '/common/payee/disp/:id',
      name: 'CommonPayeeDisp',
      component: CommonPayeeDisp
    },
    {
      path: '/common/payee/edit/:id',
      name: 'CommonPayeeEdit',
      component: CommonPayeeEdit
    },
    {
      path: '/common/payee/add',
      name: 'CommonPayeeAdd',
      component: CommonPayeeEdit
    }
  ]
}
