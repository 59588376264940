<template>
  <div class="container">
    <div class="row" style="margin-top: 10px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div style="height: 30px;"></div>
    <div v-if="!loading && !validToken" class="alert alert-danger text-start">
      <div class="row">
        <div class="col-12 fs-4">不正なアクセスです</div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">本サイトは送付された明細書に記載のQRコードからしかアクセスできません。お持ちのスマートフォンでQRコードを読み込んでアクセスしてください。</div>
      </div>
    </div>
    <div v-else-if="!loading && !invoice" class="alert alert-danger text-start">
      <div class="row">
        <div class="col-12 fs-4">事業者登録番号が国税庁データベースに存在しません</div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">番号をご確認の上、誤っている場合には明細書発行会社にご連絡ください。なお、事業者登録後、国税庁データベースに反映されるまで数日かかる場合もありますので、誤りが見つけられない場合には数日後に再度お試しください。</div>
      </div>
    </div>
    <div v-else-if="!loading" class="alert alert-success text-start">
      <div class="row">
        <div class="col-12 fs-4">以下の情報が一致しました</div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="form-label align-bottom">事業者登録番号</div>
        <div class="form-edit-line">
          {{invoice.registratedNumber}}
        </div>
        <div class="form-label align-bottom">名前</div>
        <div class="form-edit-line fs-5">
          <b>{{invoice.name}}</b>
        </div>
        <div class="form-label align-bottom">住所</div>
        <div class="form-edit-line">
          {{invoice.address}}
        </div>
      </div>
      <div class="row" style="margin-top: 15px;">
        <div class="col-12">お名前だけでなく、住所が間違っていないかもご確認ください。表示される内容が誤っている場合には明細書発行会社にご連絡ください。</div>
      </div>
    </div>
    <footer class="alert alert-warning">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()
    const loading = ref(true)
    const validToken = ref(false)
    const invoice = ref(null)
    onMounted(() => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/validation/' + route.params.invoiceNo + '/' + route.params.token).catch(() => {
        validToken.value = false
        invoice.value = null
        loading.value = false
      }).then(response => {
        if (response.status === 200) {
          console.log(response.data)
          validToken.value = true
          invoice.value = response.data
          if (invoice.value.count === '0') {
            invoice.value = null
          } else {
            invoice.value = invoice.value.announcement[0]
            console.log(invoice.value)
          }
        }
        loading.value = false
      })
    })
    return {
      loading,
      validToken,
      invoice
    }
  }
})
</script>
