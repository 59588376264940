import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const rightsHolderFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getBySeq = async (seq) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/seq/' + seq, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        searchString: store.searchString,
        notMember: store.notMember,
        sortColumn: store.sortColumn,
        sortOrder: store.sortOrder,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findDetail = async (companyId, store) => {
    const params = {
      companyId: companyId,
      sortColumn1: store.detailCondition.sortColumn1,
      sortOrder1: store.detailCondition.sortOrder1,
      sortColumn2: store.detailCondition.sortColumn2,
      sortOrder2: store.detailCondition.sortOrder2,
      page: store.page,
      perPage: 20
    }
    if (store.detailCondition.searchString) {
      params.searchString = store.detailCondition.searchString
    }
    if (store.detailCondition.taxationType !== 'all') {
      params.taxationType = store.detailCondition.taxationType
    }
    if (store.detailCondition.invoiceNo) {
      params.invoiceNo = store.detailCondition.invoiceNo.replace('T', '')
    }
    if (store.detailCondition.payOrCarryForward !== 'all') {
      params.payOrCarryForward = store.detailCondition.payOrCarryForward
    }
    if (store.detailCondition.carryForwardStatus !== 'all') {
      params.carryForwardStatus = store.detailCondition.carryForwardStatus
    }
    if (store.detailCondition.statementSendType !== 'all') {
      params.statementSendType = store.detailCondition.statementSendType
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list/detail', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const findAll = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list/all', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findForStatement = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list/all/statement', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findMember = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/member/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByAddressee = async (addresseeId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/addressee/' + addresseeId + '/rightsholder/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByPayee = async (payeeId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payee/' + payeeId + '/rightsholder/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findAdvance = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/advance/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const insertAdvance = async (rightsHolderId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/advance', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const updateAdvance = async (advanceId, form) => {
    await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/advance/' + advanceId, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const removeAdvance = async (advanceId) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/advance/' + advanceId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const clear = (store) => {
    store.searchString = ''
    store.sortColumn = 'name'
    store.sortOrder = 'asc'
    store.page = 0
  }
  const detailClear = (store) => {
    store.detailCondition = {
      searchString: null,
      taxationType: 'all',
      invoiceNo: null,
      payOrCarryForward: 'all',
      carryForwardStatus: 'all',
      statementSendType: 'all',
      sortColumn1: 'name',
      sortOrder1: 'asc',
      sortColumn2: 'kana',
      sortOrder2: 'asc'
    }
    store.page = 0
  }
  const insert = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { companyId: companyId }
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const findDestination = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/' + rightsHolderId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const insertDestination = async (destinationId, rightsHolderId) => {
    await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + destinationId + '/red', {
      rightsHolderId: rightsHolderId
    }, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const removeDestination = async (destinationId, rightsHolderId) => {
    await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + destinationId + '/red/' + rightsHolderId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const getInvoice = async (invoiceNo) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/T' + invoiceNo, {})
    return data
  }
  const indexUpdate = (rightsHolderId) => {
    axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, getBySeq, find, findDetail, findAll, findMember, clear, detailClear, insert, update, remove, findByAddressee, findByPayee, findAdvance, insertAdvance, removeAdvance, updateAdvance, findDestination, insertDestination, removeDestination, getInvoice, indexUpdate, findForStatement }
}
