<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/usage/list"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績ファイル一覧</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="'/red/usage/register/' + route.params.userSalesId"><i class="fas fa-cloud fa-fw" style="margin-right: 5px;"></i> 取込</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-exclamation-triangle fa-fw" style="margin-right: 5px;"></i>アンマッチ一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
          </div>
        </div>
        <div style="position: fixed; top: 60px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table class="table table-responsive table-hover" style="font-size: 85%;">
            <thead>
              <tr>
                <th style="width: 50px;"></th>
                <th v-if="fileList.some(x => x.catalogNo)" style="width: 150px; word-break: break-all;">品番</th>
                <th v-if="fileList.some(x => x.janCode)" style="width: 150px; word-break: break-all;">JAN</th>
                <th v-if="fileList.some(x => x.userAlbumCode)" style="width: 150px; word-break: break-all;">利用者アルバムコード</th>
                <th v-if="fileList.some(x => x.isrc)" style="width: 150px; word-break: break-all;">ISRC</th>
                <th v-if="fileList.some(x => x.masterCode)" style="width: 150px; word-break: break-all;">原盤コード</th>
                <th v-if="fileList.some(x => x.userMasterCode)" style="width: 150px; word-break: break-all;">利用者原盤コード</th>
                <th v-if="fileList.some(x => x.matchingCode)" style="width: 150px; word-break: break-all;">混在したコード</th>
                <th v-if="fileList.some(x => x.albumTitle)" style="width: 300px; word-break: break-all;">アルバム名</th>
                <th v-if="fileList.some(x => x.masterName)" style="width: 300px; word-break: break-all;">原盤名</th>
                <th v-if="fileList.some(x => x.artistName)" style="width: 300px; word-break: break-all;">アーティスト名</th>
              </tr>
            </thead>
            <tbody v-if="fileList">
              <tr v-for="(file, index) in fileList" :key="index" class="text-start align-middle">
                <td class="text-center">
                  <button @click="openDetectModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-link-horizontal fa-fw"></i></button>
                </td>
                <td v-if="fileList.some(x => x.catalogNo)" style="word-break: break-all;">{{file.catalogNo}}</td>
                <td v-if="fileList.some(x => x.janCode)" style="word-break: break-all;">{{file.janCode}}</td>
                <td v-if="fileList.some(x => x.userAlbumCode)" style="word-break: break-all;">{{file.userAlbumCode}}</td>
                <td v-if="fileList.some(x => x.isrc)" style="word-break: break-all;">{{file.isrc}}</td>
                <td v-if="fileList.some(x => x.masterCode)" style="word-break: break-all;">{{file.masterCode}}</td>
                <td v-if="fileList.some(x => x.userMasterCode)" style="word-break: break-all;">{{file.userMasterCode}}</td>
                <td v-if="fileList.some(x => x.matchingCode)" style="word-break: break-all;">{{file.matchingCode}}</td>
                <td v-if="fileList.some(x => x.albumTitle)" style="word-break: break-all;">{{file.albumTitle}}</td>
                <td v-if="fileList.some(x => x.masterName)" style="word-break: break-all;">{{file.masterName}}</td>
                <td v-if="fileList.some(x => x.artistName)" style="word-break: break-all;">{{file.artistName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="fileResult.allRecords > 0">{{ fileResult.allRecords.toLocaleString() }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="fileResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="fileResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ page + 1 }}/{{ fileResult.allPages }}ページ</span></li>
                <li v-if="page !== fileResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="page !== fileResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="detectModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アンマッチ解消</h5>
          </div>
          <div class="modal-body text-start">
            <div v-if="detectForm.albumTitle" class="form-label align-bottom">アルバム名</div>
            <div v-if="detectForm.albumTitle" class="form-edit-line">
              {{ detectForm.albumTitle }}
            </div>
            <div v-if="detectForm.masterName" class="form-label align-bottom">原盤名</div>
            <div v-if="detectForm.masterName" class="form-edit-line">
              {{ detectForm.masterName }}
            </div>
            <div v-if="detectForm.artistName" class="form-label align-bottom">アーティスト</div>
            <div v-if="detectForm.artistName" class="form-edit-line">
              {{ detectForm.artistName }}
            </div>
            <div v-if="detectForm.price" class="form-label align-bottom">販売価格（販売価格が複数ある場合、一番高いものを表示）</div>
            <div v-if="detectForm.price" class="form-edit-line">
              {{ detectForm.price.toLocaleString() }}
            </div>
            <div v-if="detectForm.unitPrice" class="form-label align-bottom">分配単価（分配単価が複数ある場合、一番高いものを表示）</div>
            <div v-if="detectForm.unitPrice" class="form-edit-line">
              {{ detectForm.unitPrice.toLocaleString() }}
            </div>
            <hr>
            <div v-if="detectForm.catalogNo" class="form-label align-bottom">品番</div>
            <div v-if="detectForm.catalogNo" class="form-edit-line">
              {{ detectForm.catalogNo }}
            </div>
            <div v-if="detectForm.janCode" class="form-label align-bottom">JANコード</div>
            <div v-if="detectForm.janCode" class="form-edit-line">
              {{ detectForm.janCode }}
            </div>
            <div v-if="detectForm.userAlbumCode" class="form-label align-bottom">利用者アルバムコード</div>
            <div v-if="detectForm.userAlbumCode" class="form-edit-line">
              {{ detectForm.userAlbumCode }}
            </div>
            <div v-if="detectForm.isrc" class="form-label align-bottom">ISRC</div>
            <div v-if="detectForm.isrc" class="form-edit-line">
              {{ detectForm.isrc }}
            </div>
            <div v-if="detectForm.masterCode" class="form-label align-bottom">原盤コード</div>
            <div v-if="detectForm.masterCode" class="form-edit-line">
              {{ detectForm.masterCode }}
            </div>
            <div v-if="detectForm.userMasterCode" class="form-label align-bottom">利用者原盤コード</div>
            <div v-if="detectForm.userMasterCode" class="form-edit-line">
              {{ detectForm.userMasterCode }}
            </div>
            <div v-if="detectForm.matchingCode" class="form-label align-bottom">混在したコード</div>
            <div v-if="detectForm.matchingCode" class="form-edit-line">
              {{ detectForm.matchingCode }}
            </div>
            <div v-if="detectForm.useTypeName" class="form-label align-bottom">利用種別</div>
            <div v-if="detectForm.useTypeName" class="form-edit-line">
              {{ detectForm.useTypeName }}
            </div>
            <div v-if="detectForm.memo" class="form-label align-bottom">メモ</div>
            <div v-if="detectForm.memo" class="form-edit-line">
              {{ detectForm.memo }}
            </div>
            <hr>
            <ul class="nav nav-tabs" style="margin-bottom: 10px;">
              <li class="nav-item">
                <a @click="matchingMode = 'auto'" class="nav-link" :class="{ 'active': matchingMode === 'auto' }" aria-current="page" href="#">
                  自動候補
                </a>
              </li>
              <li class="nav-item">
                <a @click="matchingMode = 'manual'" class="nav-link" :class="{ 'active': matchingMode === 'manual' }" aria-current="page" href="#">
                  手動検索
                </a>
              </li>
              <li class="nav-item">
                <a @click="matchingMode = 'add'" class="nav-link" :class="{ 'active': matchingMode === 'add' }" aria-current="page" href="#">
                  新規登録
                </a>
              </li>
            </ul>
            <div v-if="matchingMode === 'auto'">
              <div v-if="unknownUnit" style="margin-bottom: 10px;">
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_master" value="master">
                  <label class="form-check-label" for="masterOrAlbum_master">原盤候補</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_album" value="album">
                  <label class="form-check-label" for="masterOrAlbum_album">アルバム候補</label>
                </div>
              </div>
              <div><span class="badge rounded-pill text-bg-info"><i class="fas fa-brain-circuit" style="margin-right: 3px;"></i>extracted by MANA AI</span></div>
              <table v-if="masterOrAlbum === 'master' && masterSimilarList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 40px;">※</th>
                    <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                    <th class="d-none d-md-table-cell" style="width: 170px;">ISRC</th>
                    <th>原盤名</th>
                    <th class="d-none d-lg-table-cell">アーティスト</th>
                  </tr>
                </thead>
                <tbody v-if="masterSimilarList">
                  <tr v-for="master in masterSimilarList" :key="master.id" class="text-start">
                    <td class="text-center">
                      <button @click="masterLinkCode(master.id)" class="btn btn-primary btn-sm"><i class="fas fa-link-horizontal fa-fw"></i></button>
                    </td>
                    <td class="text-center">
                      <span v-if="master.level === 'S'" class="badge bg-warning">S</span>
                      <span v-if="master.level === 'A'" class="badge bg-primary">A</span>
                      <span v-if="master.level === 'B'" class="badge bg-success">B</span>
                      <span v-if="master.level === 'C'" class="badge bg-info">C</span>
                      <span v-if="master.level === 'D'" class="badge bg-secondary">D</span>
                    </td>
                    <td class="d-none d-lg-table-cell">{{ master.code }}</td>
                    <td class="d-none d-md-table-cell"><span v-html="master.isrc"></span></td>
                    <td>
                      <button @click="openMasterModal(master.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus"></i></button>
                      {{ master.displayName }}
                    </td>
                    <td class="d-none d-lg-table-cell">{{ master.artistName }}</td>
                  </tr>
                </tbody>
                <tfoot><tr><td colspan="6" style="font-size: 90%;">
                  ※ 一致可能性&nbsp;&nbsp;&nbsp;<span class="badge bg-warning">S</span>:非常に高い、
                  <span class="badge bg-primary">A</span>:高い、
                  <span class="badge bg-success">B</span>:やや高い、
                  <span class="badge bg-info">C</span>:あまり高くない、
                  <span class="badge bg-secondary">D</span>:低い
                </td></tr></tfoot>
              </table>
              <div v-if="masterOrAlbum === 'master' && masterSimilarList.length === 0" style="margin-top: 10px;">類似原盤が見つかりませんでした。念の為手動検索をお試しの上、未登録原盤の場合には新規登録してください。</div>
              <table v-if="masterOrAlbum === 'album' && albumSimilarList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 40px;">※</th>
                    <th class="d-none d-lg-table-cell" style="width: 180px;">品番</th>
                    <th>タイトル</th>
                    <th class="d-none d-lg-table-cell">アーティスト</th>
                  </tr>
                </thead>
                <tbody v-if="albumSimilarList">
                  <tr v-for="album in albumSimilarList" :key="album.id" class="text-start">
                    <td class="text-center">
                      <button @click="albumLinkCode(album.id)" class="btn btn-primary btn-sm"><i class="fas fa-link-horizontal fa-fw"></i></button>
                    </td>
                    <td class="text-center">
                      <span v-if="album.level === 'S'" class="badge bg-warning">S</span>
                      <span v-if="album.level === 'A'" class="badge bg-primary">A</span>
                      <span v-if="album.level === 'B'" class="badge bg-success">B</span>
                      <span v-if="album.level === 'C'" class="badge bg-info">C</span>
                      <span v-if="album.level === 'D'" class="badge bg-secondary">D</span>
                    </td>
                    <td class="d-none d-md-table-cell"><span v-html="album.catalogNo"></span></td>
                    <td>
                      <button @click="openAlbumModel(album.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus"></i></button>
                      {{ album.name }}
                    </td>
                    <td class="d-none d-lg-table-cell">{{ album.artistName }}</td>
                  </tr>
                </tbody>
                <tfoot><tr><td colspan="6" style="font-size: 90%;">
                  ※ 一致可能性&nbsp;&nbsp;&nbsp;<span class="badge bg-warning">S</span>:非常に高い、
                  <span class="badge bg-primary">A</span>:高い、
                  <span class="badge bg-success">B</span>:やや高い、
                  <span class="badge bg-info">C</span>:あまり高くない、
                  <span class="badge bg-secondary">D</span>:低い
                </td></tr></tfoot>
              </table>
              <div v-if="masterOrAlbum === 'album' && albumSimilarList.length === 0" style="margin-top: 10px;">類似アルバムが見つかりませんでした。念の為手動検索をお試しの上、未登録アルバムの場合には新規登録してください。</div>
            </div>
            <div v-if="matchingMode === 'manual'">
              <div v-if="unknownUnit" style="margin-bottom: 10px;">
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_master" value="master">
                  <label class="form-check-label" for="masterOrAlbum_master">原盤を検索</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_album" value="album">
                  <label class="form-check-label" for="masterOrAlbum_album">アルバムを検索</label>
                </div>
              </div>
              <div v-if="masterOrAlbum === 'album'" class="input-group" style="margin-top: 15px;">
                <input @keydown.enter="albumList = []; albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button @click="albumList = []; albumCondition.searchString = ''; albumCondition.page = 0;" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="albumList = []; albumCondition.page = 0; findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="masterOrAlbum === 'album' && albumList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 50px;"></th>
                    <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                    <th class="d-none d-md-table-cell" style="width: 150px;">品番</th>
                    <th>タイトル</th>
                    <th class="d-none d-lg-table-cell">アーティスト</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="album in albumList" :key="album.id" class="text-start">
                    <td class="text-center" style="padding: 8px 2px 8px 2px;">
                      <button class="btn btn-primary btn-sm" @click="albumLinkCode(album.id)"><i class="fas fa-link fa-fw"></i></button>
                    </td>
                    <td class="text-center" style="padding: 8px 2px 8px 2px;">
                      <button class="btn btn-outline-primary btn-sm" @click="openAlbumModel(album.id)"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    </td>
                    <td class="d-none d-lg-table-cell">{{ album.code }}</td>
                    <td class="d-none d-md-table-cell"><span v-html="album.catalogNo"></span></td>
                    <td>
                      {{ album.name }}
                    </td>
                    <td class="d-none d-lg-table-cell">{{ album.artistName }}</td>
                  </tr>
                </tbody>
                <tfoot v-if="albumResult.allRecords > albumList.length">
                  <tr>
                    <td colspan="5">
                      <div class="d-grid gap-2">
                        <button @click="albumCondition.page = albumResult.page + 1; findAlbum();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div v-if="masterOrAlbum === 'master'" class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="masterList = []; masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button @click="masterList = []; masterCondition.searchString = ''; masterCondition.page = 0;" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="masterList = []; masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="masterOrAlbum === 'master' && masterList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 50px;"></th>
                    <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                    <th class="d-none d-md-table-cell" style="width: 170px;">ISRC</th>
                    <th>原盤名</th>
                    <th class="d-none d-lg-table-cell">アーティスト</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="master in masterList" :key="master.id" class="text-start">
                    <td class="text-center" style="padding: 8px 2px 8px 2px;">
                      <button class="btn btn-primary btn-sm" @click="masterLinkCode(master.id)"><i class="fas fa-link-horizontal fa-fw"></i></button>
                    </td>
                    <td class="text-center" style="padding: 8px 2px 8px 2px;">
                      <button class="btn btn-outline-primary btn-sm" @click="openMasterModal(master.id)"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    </td>
                    <td class="d-none d-lg-table-cell">{{ master.code }}</td>
                    <td class="d-none d-md-table-cell"><span v-html="master.isrc"></span></td>
                    <td>{{ master.displayName }}</td>
                    <td class="d-none d-lg-table-cell">{{ master.artistName }}</td>
                  </tr>
                </tbody>
                <tfoot v-if="masterResult.allRecords > masterList.length">
                  <tr>
                    <td colspan="5">
                      <div class="d-grid gap-2">
                        <button @click="masterCondition.page = masterResult.page + 1; findMaster();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div v-if="matchingMode === 'add'">
              <div v-if="unknownUnit" style="margin-bottom: 10px;">
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_master" value="master">
                  <label class="form-check-label" for="masterOrAlbum_master">原盤として登録</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="masterOrAlbum" class="form-check-input" type="radio" name="masterOrAlbum" id="masterOrAlbum_album" value="album">
                  <label class="form-check-label" for="masterOrAlbum_album">アルバムとして登録</label>
                </div>
              </div>
              <template v-if="masterOrAlbum === 'master'">
                <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>原盤名 ※明細書に使用</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.name" @change="changeMasterName" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>原盤名（表示用）※検索結果にのみ使用</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.displayName" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">カナ</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.kana" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">英語名</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.nameEn" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">管理コード</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.code" type="text" class="form-control" maxlength="20" style="width: 250px;">
                </div>
                <div class="form-label align-bottom">アーティスト</div>
                <div class="form-edit-line">
                  <input v-model="masterForm.artistName" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">メモ</div>
                <div class="form-edit-line">
                  <textarea v-model="masterForm.memo" class="form-control" rows="5"></textarea>
                </div>
                <div class="text-end">
                  <button @click="createMaster" class="btn btn-outline-primary"><i class="fas fa-save" style="margin-right: 5px;"></i>登録</button>
                </div>
              </template>
              <template v-if="masterOrAlbum === 'album'">
                <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>アルバムタイトル</div>
                <div class="form-edit-line">
                  <input v-model="albumForm.name" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">カナ</div>
                <div class="form-edit-line">
                  <input v-model="albumForm.kana" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">英語名</div>
                <div class="form-edit-line">
                  <input v-model="albumForm.nameEn" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">アーティスト</div>
                <div class="form-edit-line">
                  <input v-model="albumForm.artistName" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">メモ</div>
                <div class="form-edit-line">
                  <textarea v-model="albumForm.memo" class="form-control" rows="5"></textarea>
                </div>
                <div class="text-end">
                  <button @click="createAlbum" class="btn btn-outline-primary"><i class="fas fa-save" style="margin-right: 5px;"></i>登録</button>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤詳細</h5>
          </div>
          <div class="modal-body text-start">
            <div class="row">
              <div class="col-lg-7">
                <div class="form-label align-bottom">名前</div>
                <div class="form-edit-line fs-5" style="font-weight: bold;">
                  {{ masterRefForm.name }}
                </div>
                <div class="form-label align-bottom">名前（表示用）</div>
                <div class="form-edit-line">
                  {{ masterRefForm.displayName }}
                </div>
                <div v-if="masterRefForm.kana" class="form-label align-bottom">カナ</div>
                <div v-if="masterRefForm.kana" class="form-edit-line">
                  {{ masterRefForm.kana }}
                </div>
                <div v-if="masterRefForm.nameEn" class="form-label align-bottom">英語名</div>
                <div v-if="masterRefForm.nameEn" class="form-edit-line">
                  {{ masterRefForm.nameEn }}
                </div>
                <div v-if="masterRefForm.code" class="form-label align-bottom">管理コード</div>
                <div v-if="masterRefForm.code" class="form-edit-line">
                  {{ masterRefForm.code }}
                </div>
                <div v-if="masterRefForm.isrcList && masterRefForm.isrcList.length > 0" class="form-label align-bottom">
                  ISRC
                </div>
                <div class="form-edit-line">
                  <p v-for="(isrc, index) in masterRefForm.isrcList" :key="index" style="margin: 0;">
                    {{isrc.isrc}}
                  </p>
                </div>
                <div v-if="masterRefForm.artistId" class="form-label align-bottom">アーティスト</div>
                <div v-if="masterRefForm.artistId" class="form-edit-line">
                  {{ masterRefForm.artistName }}
                </div>
                <div v-if="masterRefForm.projectId" class="form-label align-bottom">プロジェクト</div>
                <div v-if="masterRefForm.projectId" class="form-edit-line">
                  <router-link :to="'/common/project/disp/' + masterRefForm.projectId">{{ masterRefForm.projectName }}</router-link>
                </div>
                <div v-if="masterRefForm.playTimeH || masterRefForm.playTimeM || masterRefForm.playTimeS" class="form-label align-bottom">演奏時間
                </div>
                <div v-if="masterRefForm.playTimeH || masterRefForm.playTimeM || masterRefForm.playTimeS" class="form-edit-line">
                  <span v-if="masterRefForm.playTimeH">{{ masterRefForm.playTimeH }}時間</span><span v-if="masterRefForm.playTimeM">{{masterRefForm.playTimeM}}分</span><span v-if="masterRefForm.playTimeS">{{ masterRefForm.playTimeS }}秒</span>
                </div>
                <div class="card" style="margin-bottom: 10px;">
                  <div class="card-header">計算</div>
                  <div class="card-body" style="padding: 10px 5px 5px 10px;">
                    <div class="form-label align-bottom">計算対象</div>
                    <div class="form-edit-line">
                      <span v-if="masterRefForm.calculateTarget">分配計算の対象とする</span>
                      <span v-if="!masterRefForm.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
                    </div>
                    <div v-if="masterRefForm.calculateTarget === true || masterRefForm.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                    <div v-if="masterRefForm.calculateTarget === true || masterRefForm.calculateTarget === 'true'" class="form-edit-line">
                      <span v-if="!masterRefForm.suspension">分配計算の対象とする</span>
                      <span v-if="masterRefForm.suspension">分配計算を保留する</span>
                    </div>
                  </div>
                </div>
                <div v-if="masterRefForm.userCodeList && masterRefForm.userCodeList.length > 0" class="accordion" id="accordionUserCode" style="margin-bottom: 10px;">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUserCode" aria-expanded="true" aria-controls="collapseUserCode">
                        報告コード
                      </button>
                    </h2>
                    <div id="collapseUserCode" class="accordion-collapse collapse" data-bs-parent="#accordionUserCode">
                      <div class="accordion-body">
                        <table class="table table-responsive">
                          <tbody>
                          <tr v-for="(userCode, index) in masterRefForm.userCodeList" :key="index">
                            <td class="text-break">{{userCode.userName}}</td>
                            <td class="text-break">{{userCode.code}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="masterRefForm.poolList && masterRefForm.poolList.length > 0" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">保留期</div>
                  <div class="card-body" style="padding: 5px;">
                    <table class="table table-responsive">
                      <tbody>
                      <tr v-for="pool in masterRefForm.poolList" :key="pool.id">
                        <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                        <td class="text-end">
                          <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                          <span v-else>解除しても支払わない</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="masterRefForm.memo" class="form-label align-bottom">メモ</div>
                <div v-if="masterRefForm.memo" class="form-edit-line">
                  <span v-html="masterRefForm.memo"></span>
                </div>
                <div class="form-label align-bottom">登録</div>
                <div class="form-edit-line">
                  <span v-html="masterRefForm.createdAt"></span>（{{masterRefForm.createdBy}}）
                </div>
                <div class="form-label align-bottom">最終更新</div>
                <div class="form-edit-line">
                  <span v-html="masterRefForm.updatedAt"></span>（{{masterRefForm.updatedBy}}）
                </div>
              </div>
              <div class="col-lg-5">
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item">
                    <a @click="masterRightAreaMode = 'CONTRACT'" class="nav-link" :class="{ 'active': masterRightAreaMode === 'CONTRACT' }"
                       aria-current="page" href="#">
                      契約書
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="masterRightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': masterRightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                      アルバム
                    </a>
                  </li>
                </ul>
                <div v-if="masterRightAreaMode === 'ALBUM'">
                  <table v-if="masterRefForm.albumList && masterRefForm.albumList.length > 0" class="table table-responsive">
                    <tbody>
                    <tr v-for="(album, index) in masterRefForm.albumList" :key="index">
                      <td>
                        <p v-if="album.catalogNo" style="margin: 0; font-size: 80%;">{{album.catalogNo}}</p>
                        {{album.name}}
                        <p v-if="album.artistName" style="margin: 0; font-size: 80%;">{{album.artistName}}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-else class="form-edit-line" style="margin-top: 10px;">関連アルバムが存在しません</div>
                </div>
                <div v-if="masterRightAreaMode === 'CONTRACT'">
                  <table v-if="masterRefForm.contractList && masterRefForm.contractList.length > 0" class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="contract in masterRefForm.contractList" :key="contract.id">
                      <td>
                        <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
                        {{contract.name}}
                        <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeMasterModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <router-link :to="'/red/master/disp/' + masterRefForm.id" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>原盤画面に移動</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="albumModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバム詳細</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line fs-5" style="font-weight: bold;">
              {{ albumRefForm.name }}
            </div>
            <div v-if="albumRefForm.kana" class="form-label align-bottom">カナ</div>
            <div v-if="albumRefForm.kana" class="form-edit-line">
              {{ albumRefForm.kana }}
            </div>
            <div v-if="albumRefForm.nameEn" class="form-label align-bottom">英語名</div>
            <div v-if="albumRefForm.nameEn" class="form-edit-line">
              {{ albumRefForm.nameEn }}
            </div>
            <div v-if="albumRefForm.artistId" class="form-label align-bottom">アーティスト</div>
            <div v-if="albumRefForm.artistId" class="form-edit-line">
              <router-link :to="'/red/album/artist/disp/' + albumRefForm.artistId + '/' + albumRefForm.id">{{ albumRefForm.artistName }}</router-link>
            </div>
            <div v-if="albumRefForm.projectId" class="form-label align-bottom">プロジェクト</div>
            <div v-if="albumRefForm.projectId" class="form-edit-line">
              <router-link :to="'/common/project/disp/' + albumRefForm.projectId">{{ albumRefForm.projectName }}</router-link>
            </div>
            <div v-if="albumRefForm.makerList && albumRefForm.makerList.length > 0" class="form-label align-bottom">
              レコード会社 / レーベル
            </div>
            <div v-if="albumRefForm.makerList" class="form-edit-line">
              <p v-for="maker in albumRefForm.makerList" :key="maker.userId" style="margin: 0;">
                <router-link :to="'/red/user/disp/' + maker.userId">{{maker.userName}}</router-link>
              </p>
            </div>
            <div v-if="albumRefForm.catalogNoList && albumRefForm.catalogNoList.length > 0" class="form-label align-bottom">
              製品番号
            </div>
            <div class="form-edit-line">
              <p v-for="(catalogNo, index) in albumRefForm.catalogNoList" :key="index" style="margin: 0;">
                {{catalogNo.catalogNo}}
              </p>
            </div>
            <div v-if="albumRefForm.janCodeList && albumRefForm.janCodeList.length > 0" class="form-label align-bottom">
              JANコード
            </div>
            <div class="form-edit-line">
              <p v-for="(janCode, index) in albumRefForm.janCodeList" :key="index" style="margin: 0;">
                {{janCode.janCode}}
              </p>
            </div>
            <div v-if="albumRefForm.salesDate" class="form-label align-bottom">発売日</div>
            <div v-if="albumRefForm.salesDate" class="form-edit-line">
              {{albumRefForm.salesDate.split('-')[0] + '年' + albumRefForm.salesDate.split('-')[1] + '月' + albumRefForm.salesDate.split('-')[2] + '日'}}
            </div>
            <div v-if="albumRefForm.salesPriceOutTax || albumRefForm.salesPriceInTax" class="form-label align-bottom">販売価格</div>
            <div v-if="albumRefForm.salesPriceOutTax || albumRefForm.salesPriceInTax" class="form-edit-line">
              <span v-if="albumRefForm.salesPriceOutTax">￥{{albumRefForm.salesPriceOutTax.toLocaleString()}} (税抜)<br v-if="albumRefForm.salesPriceInTax"></span>
              <span v-if="albumRefForm.salesPriceInTax">￥{{albumRefForm.salesPriceInTax.toLocaleString()}} (税込)</span>
            </div>
            <div v-if="albumRefForm.allRecordMusicCount" class="form-label align-bottom">総収録曲数</div>
            <div v-if="albumRefForm.allRecordMusicCount" class="form-edit-line">
              {{albumRefForm.allRecordMusicCount}}
            </div>
            <div v-if="albumRefForm.playTimeH || albumRefForm.playTimeM || albumRefForm.playTimeS" class="form-label align-bottom">総収録時間
            </div>
            <div v-if="albumRefForm.playTimeH || albumRefForm.playTimeM || albumRefForm.playTimeS" class="form-edit-line">
              <span v-if="albumRefForm.playTimeH">{{ albumRefForm.playTimeH }}時間</span>
              <span v-if="albumRefForm.playTimeM">{{albumRefForm.playTimeM }}分</span>
              <span v-if="albumRefForm.playTimeS">{{ albumRefForm.playTimeS }}秒</span>
            </div>
            <div class="card" style="margin-bottom: 10px;">
              <div class="card-header">計算</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">計算単位</div>
                <div class="form-edit-line">
                  <span v-if="albumRefForm.masterBreakDown">原盤単位</span>
                  <span v-if="!albumRefForm.masterBreakDown">アルバム単位</span>
                </div>
                <div class="form-label align-bottom">価格取得元</div>
                <div class="form-edit-line">
                  <span v-if="albumRefForm.priorityPrice === 'data'">実績データに価格情報がある場合、これを優先</span>
                  <span v-if="albumRefForm.priorityPrice === 'master'">本画面で登録された価格を優先</span>
                </div>
                <div v-if="albumRefForm.masterBreakDown === false || albumRefForm.masterBreakDown === 'false'" class="form-label align-bottom">管理割合</div>
                <div v-if="albumRefForm.masterBreakDown === false || albumRefForm.masterBreakDown === 'false'" class="form-edit-line">
                  {{albumRefForm.portionC}} / {{albumRefForm.portionM}}
                </div>
                <div class="form-label align-bottom">計算対象</div>
                <div class="form-edit-line">
                  <span v-if="albumRefForm.calculateTarget">分配計算の対象とする</span>
                  <span v-if="!albumRefForm.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
                </div>
                <div v-if="albumRefForm.calculateTarget === true || albumRefForm.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                <div v-if="albumRefForm.calculateTarget === true || albumRefForm.calculateTarget === 'true'" class="form-edit-line">
                  <span v-if="!albumRefForm.suspension">分配計算の対象とする</span>
                  <span v-if="albumRefForm.suspension">分配計算を保留する</span>
                </div>
              </div>
            </div>
            <div v-if="albumRefForm.photoList && albumRefForm.photoList.length > 0" class="form-label align-bottom">ジャケ写</div>
            <div v-if="albumRefForm.photoList && albumRefForm.photoList.length > 0" class="form-edit-line">
              <div v-for="(photo, index) in albumRefForm.photoList" :key="photo.blobName">
                <a @click="selectPhoto(index)" href="#"><img v-if="photo.url" :src="photo.url" class="img-thumbnail float-start" style="max-width: 180px; margin: 5px;"></a>
              </div>
            </div>
            <div v-if="albumRefForm.masterBreakDown === true || albumRefForm.masterBreakDown === 'true'" class="card" style="margin-bottom: 10px;">
              <div class="card-header">収録原盤</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                  <tr v-for="(record, index) in albumRefForm.recordMusicList" :key="index">
                    <td style="width: 40px; vertical-align: middle;">
                      {{index + 1}}
                    </td>
                    <td>
                      <span v-if="record.isrcList.length > 0" style="font-size: 80%;">ISRC : {{record.isrcList.map(x => x.isrc).join(' / ')}}<br></span>
                      <router-link :to="'/red/master/disp/' + record.masterId">{{record.masterName}}</router-link>
                      <span v-if="record.masterArtistName" style="font-size: 80%;"><br>{{record.masterArtistName}}</span>
                      <hr style="margin: 5px 0 5px 0;">
                      <div style="font-size: 80%;">
                        <span v-if="record.managed" class="badge bg-info">管理</span>
                        <span v-if="!record.managed" class="badge bg-secondary">非管理</span>
                        <span v-if="record.playTimeH || record.playTimeM || record.playTimeS" style="margin-left: 5px;">収録時間 :</span>
                        <span v-if="record.playTimeH">{{record.playTimeH}}時間</span>
                        <span v-if="record.playTimeM">{{record.playTimeM}}分</span>
                        <span v-if="record.playTimeS">{{record.playTimeS}}秒</span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="albumRefForm.userCodeList && albumRefForm.userCodeList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">報告コード</div>
              <div class="card-body" style="padding: 5px;">
                <table v-if="albumRefForm.userCodeList" class="table table-responsive">
                  <tbody>
                  <tr v-for="(userCode, index) in albumRefForm.userCodeList" :key="index">
                    <td>{{userCode.userName}}</td>
                    <td>{{userCode.code}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="albumRefForm.poolList && albumRefForm.poolList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">保留期</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                  <tr v-for="pool in albumRefForm.poolList" :key="pool.id">
                    <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                    <td class="text-end">
                      <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                      <span v-else>解除しても支払わない</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="albumRefForm.memo" class="form-label align-bottom">メモ</div>
            <div v-if="albumRefForm.memo" class="form-edit-line">
              <span v-html="albumRefForm.memo"></span>
            </div>
            <div class="form-label align-bottom">登録</div>
            <div class="form-edit-line">
              <span v-html="albumRefForm.createdAt"></span>（{{albumRefForm.createdBy}}）
            </div>
            <div class="form-label align-bottom">最終更新</div>
            <div class="form-edit-line">
              <span v-html="albumRefForm.updatedAt"></span>（{{albumRefForm.updatedBy}}）
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeAlbumModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoAlbum(albumRefForm.id)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アルバム画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usageFunction } from './function'
import { masterFunction } from '../master/function'
import { albumFunction } from '../album/function'
import { contractFunction } from '../contract/function'
import { userFunction } from '../user/function'
import { redCompanyFunction } from '@/functions/common'
import { Modal, Toast } from 'bootstrap'
import { albumStore, masterStore } from './store'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    // 検索
    const usageFunc = usageFunction()
    const fileResult = ref({})
    const fileList = ref([])
    // アンマッチ解消
    const masterFunc = masterFunction()
    const companyFunc = redCompanyFunction()
    const userFunc = userFunction()
    const detectModalDiv = ref(null)
    let detectModal = null
    const detectForm = ref({})
    const matchingMode = ref('auto')
    const unknownUnit = ref(true)
    const masterOrAlbum = ref('master')
    const masterForm = ref({})
    const albumForm = ref({})
    const masterSimilarList = ref([])
    const albumSimilarList = ref([])
    const page = ref(0)
    // 原盤詳細
    const albumFunc = albumFunction()
    const contractFunc = contractFunction()
    const masterModalDiv = ref(null)
    let masterModal = null
    const masterRefForm = ref({})
    const masterRightAreaMode = ref('CONTRACT')
    // アルバム詳細
    const albumModalDiv = ref(null)
    let albumModal = null
    const albumRefForm = ref({})
    const albumRightAreaMode = ref('CONTRACT')
    // 原盤検索
    const masterCondition = masterStore()
    const masterResult = ref({})
    const masterList = ref([])
    // アルバム検索
    const albumCondition = albumStore()
    const albumResult = ref({})
    const albumList = ref([])
    // メソッド
    const find = async () => {
      fileResult.value = await usageFunc.findUnMatch(route.params.uploadedFileId, page.value)
      fileList.value = fileResult.value.list
    }
    const openDetectModal = async (index) => {
      detectForm.value = fileList.value[index]
      if (detectForm.value.masterName) {
        masterForm.value.name = detectForm.value.masterName.toString().normalize('NFKC')
        masterForm.value.displayName = masterForm.value.name
      }
      if (detectForm.value.artistName) {
        masterForm.value.artistName = detectForm.value.artistName.toString().normalize('NFKC')
        albumForm.value.artistName = detectForm.value.artistName.toString().normalize('NFKC')
      }
      if (detectForm.value.masterCode) {
        masterForm.value.code = detectForm.value.masterCode
      }
      if (detectForm.value.albumTitle) {
        albumForm.value.name = detectForm.value.albumTitle
      }
      if (detectForm.value.isrc || detectForm.value.masterCode || detectForm.value.userMasterCode) {
        masterOrAlbum.value = 'master'
        unknownUnit.value = !!(detectForm.value.catalogNo || detectForm.value.janCode || detectForm.value.userAlbumCode)
      } else if (detectForm.value.matchingCode) {
        masterOrAlbum.value = 'master'
        unknownUnit.value = true
      } else {
        unknownUnit.value = false
        if (detectForm.value.catalogNo || detectForm.value.janCode || detectForm.value.userAlbumCode) {
          masterOrAlbum.value = 'album'
        }
      }
      if (detectForm.value.masterName) {
        masterSimilarList.value = await masterFunc.findSimilar(session.session.company.id, detectForm.value.masterName, detectForm.value.artistName)
      }
      if (detectForm.value.albumTitle) {
        albumSimilarList.value = await albumFunc.findSimilar(session.session.company.id, detectForm.value.albumTitle, detectForm.value.artistName)
      }
      detectModal.show()
    }
    const changeMasterName = () => {
      if (masterForm.value.name && !masterForm.value.displayName) {
        masterForm.value.displayName = masterForm.value.name
      }
    }
    const createMaster = async () => {
      masterForm.value.isrc = detectForm.value.isrc
      masterForm.value.code = detectForm.value.masterCode
      masterForm.value.userMasterCode = detectForm.value.userMasterCode
      if (!detectForm.value.userMasterCode && detectForm.value.matchingCode) {
        masterForm.value.userMasterCode = detectForm.value.matchingCode
      }
      if (masterForm.value.userMasterCode) {
        masterForm.value.userId = detectForm.value.userId
      }
      errors.value = []
      if (!masterForm.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!masterForm.value.displayName) {
        errors.value.push('表示名を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '原盤登録中です。しばらくこのままでお待ちください。'
        processModal.show()
        detectModal.hide()
        const masterId = await masterFunc.create(session.session.company.id, masterForm.value)
        const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
        masterFunc.updateIndex(masterId)
        console.log('start match')
        masterFunc.matching(masterId, term.id, async function () {
          console.log('end match')
          masterFunc.calculate(masterId)
          await find()
          processModal.hide()
        })
      }
    }
    const createAlbum = async () => {
      albumForm.value.catalogNo = detectForm.value.catalogNo
      albumForm.value.janCode = detectForm.value.janCode
      albumForm.value.userAlbumCode = detectForm.value.userAlbumCode
      if (!detectForm.value.userAlbumCode && detectForm.value.matchingCode) {
        albumForm.value.userAlbumCode = detectForm.value.matchingCode
      }
      if (albumForm.value.userAlbumCode) {
        albumForm.value.userId = detectForm.value.userId
      }
      errors.value = []
      if (!albumForm.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = 'アルバム登録中です。しばらくこのままでお待ちください。'
        processModal.show()
        detectModal.hide()
        const albumId = await albumFunc.create(session.session.company.id, albumForm.value)
        const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
        albumFunc.indexUpdate(albumId)
        albumFunc.matching(albumId, term.id, async function () {
          albumFunc.calculate(albumId)
          await find()
          processModal.hide()
        })
      }
    }
    const masterLinkCode = async (masterId) => {
      processMessage.value = 'アンマッチデータと登録済情報の紐付けを行なっています。しばらくこのままでお待ちください。'
      processModal.show()
      detectModal.hide()
      let userId = null
      if (detectForm.value.userMasterCode || detectForm.value.matchingCode) {
        const userSales = await userFunc.getUserSales(route.params.userSalesId)
        userId = userSales.userId
      }
      const linkPromises = []
      if (detectForm.value.isrc) {
        linkPromises.push(masterFunc.linkCode(masterId, 'isrc', detectForm.value.isrc, null))
      }
      if (detectForm.value.masterCode) {
        linkPromises.push(masterFunc.linkCode(masterId, 'masterCode', detectForm.value.masterCode, null))
      }
      if (detectForm.value.userMasterCode) {
        linkPromises.push(masterFunc.linkCode(masterId, 'userMasterCode', detectForm.value.userMasterCode, userId))
      }
      if (detectForm.value.matchingCode) {
        linkPromises.push(masterFunc.linkCode(masterId, 'matchingCode', detectForm.value.matchingCode, userId))
      }
      await Promise.all(linkPromises)
      const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
      masterFunc.matching(masterId, term.id, async function () {
        masterFunc.calculate(masterId)
        await find()
        processModal.hide()
      })
    }
    const albumLinkCode = async (albumId) => {
      processMessage.value = 'アンマッチデータと登録済情報の紐付けを行なっています。しばらくこのままでお待ちください。'
      processModal.show()
      detectModal.hide()
      let userId = null
      if (detectForm.value.userAlbumCode || detectForm.value.matchingCode) {
        const userSales = await userFunc.getUserSales(route.params.userSalesId)
        userId = userSales.userId
      }
      const linkPromises = []
      if (detectForm.value.catalogNo) {
        linkPromises.push(albumFunc.linkCode(albumId, 'catalogNo', detectForm.value.catalogNo, null))
      }
      if (detectForm.value.janCode) {
        linkPromises.push(albumFunc.linkCode(albumId, 'janCode', detectForm.value.janCode, null))
      }
      if (detectForm.value.userAlbumCode) {
        linkPromises.push(albumFunc.linkCode(albumId, 'userAlbumCode', detectForm.value.userAlbumCode, userId))
      }
      if (detectForm.value.matchingCode) {
        linkPromises.push(albumFunc.linkCode(albumId, 'matchingCode', detectForm.value.matchingCode, userId))
      }
      await Promise.all(linkPromises)
      const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
      albumFunc.matching(albumId, term.id, async function () {
        albumFunc.calculate(albumId)
        await find()
        processModal.hide()
      })
    }
    const openMasterModal = async (masterId) => {
      masterRefForm.value = await masterFunc.get(masterId)
      await findMasterAlbum(masterId)
      await findMasterContract(masterId)
      masterModal.show()
      detectModal.hide()
    }
    const closeMasterModal = () => {
      masterModal.hide()
      detectModal.show()
    }
    const findMasterAlbum = async (masterId) => {
      masterRefForm.value.albumList = await albumFunc.findByMaster(masterId)
    }
    const findMasterContract = async (masterId) => {
      masterRefForm.value.contractList = await contractFunc.findByMaster(masterId)
    }
    const openAlbumModel = async (albumId) => {
      albumRefForm.value = await albumFunc.get(albumId)
      albumModal.show()
      detectModal.hide()
    }
    const closeAlbumModal = () => {
      albumModal.hide()
      detectModal.show()
    }
    const gotoAlbum = (id) => {
      albumModal.hide()
      detectModal.hide()
      router.push('/red/album/disp/' + id)
    }
    const findMaster = async () => {
      masterResult.value = await masterFunc.findForMatch(session.session.company.id, masterCondition)
      masterList.value = masterList.value.concat(masterResult.value.list)
    }
    const findAlbum = async () => {
      albumResult.value = await albumFunc.findForMatch(session.session.company.id, albumCondition)
      albumList.value = albumList.value.concat(albumResult.value.list)
    }
    const firstPage = () => {
      page.value = 0
      find()
    }
    const prevPage = () => {
      page.value = page.value - 1
      find()
    }
    const nextPage = () => {
      page.value = page.value + 1
      find()
    }
    const lastPage = () => {
      page.value = fileResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      detectModal = new Modal(detectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterModal = new Modal(masterModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      albumModal = new Modal(albumModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      route,
      processMessage,
      processModalDiv,
      errors,
      errorToastDiv,
      fileList,
      find,
      detectModalDiv,
      detectForm,
      openDetectModal,
      matchingMode,
      unknownUnit,
      masterOrAlbum,
      masterForm,
      albumForm,
      changeMasterName,
      createMaster,
      masterSimilarList,
      masterLinkCode,
      masterRefForm,
      masterModalDiv,
      openMasterModal,
      masterRightAreaMode,
      findMasterContract,
      findMasterAlbum,
      createAlbum,
      albumSimilarList,
      albumLinkCode,
      albumModalDiv,
      albumRefForm,
      openAlbumModel,
      albumRightAreaMode,
      closeMasterModal,
      closeAlbumModal,
      gotoAlbum,
      masterCondition,
      albumCondition,
      masterResult,
      albumResult,
      findMaster,
      masterList,
      albumList,
      findAlbum,
      page,
      fileResult,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
