<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="form-label align-bottom">名前</div>
      <div class="form-edit-line fs-5" style="font-weight: bold;">
        {{ form.name }}
      </div>
      <div v-if="form.kana" class="form-label align-bottom">カナ</div>
      <div v-if="form.kana" class="form-edit-line">
        {{ form.kana }}
      </div>
      <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
      <div v-if="form.nameEn" class="form-edit-line">
        {{ form.nameEn }}
      </div>
      <div v-if="form.artistId" class="form-label align-bottom">アーティスト</div>
      <div v-if="form.artistId" class="form-edit-line">
        {{ form.artistName }}
      </div>
      <div v-if="form.projectId" class="form-label align-bottom">プロジェクト</div>
      <div v-if="form.projectId" class="form-edit-line">
        {{ form.projectName }}
      </div>
      <div v-if="form.makerList && form.makerList.length > 0" class="form-label align-bottom">
        レコード会社 / レーベル
      </div>
      <div v-if="form.makerList" class="form-edit-line">
        <p v-for="maker in form.makerList" :key="maker.userId" style="margin: 0;">
          {{maker.userName}}
        </p>
      </div>
      <div v-if="form.catalogNoList && form.catalogNoList.length > 0" class="form-label align-bottom">
        製品番号
      </div>
      <div class="form-edit-line">
        <p v-for="(catalogNo, index) in form.catalogNoList" :key="index" style="margin: 0;">
          {{catalogNo.catalogNo}}
        </p>
      </div>
      <div v-if="form.janCodeList && form.janCodeList.length > 0" class="form-label align-bottom">
        JANコード
      </div>
      <div class="form-edit-line">
        <p v-for="(janCode, index) in form.janCodeList" :key="index" style="margin: 0;">
          {{janCode.janCode}}
        </p>
      </div>
      <div v-if="form.salesDate" class="form-label align-bottom">発売日</div>
      <div v-if="form.salesDate" class="form-edit-line">
        {{form.salesDate.split('-')[0] + '年' + form.salesDate.split('-')[1] + '月' + form.salesDate.split('-')[2] + '日'}}
      </div>
      <div v-if="form.salesPriceOutTax || form.salesPriceInTax" class="form-label align-bottom">販売価格</div>
      <div v-if="form.salesPriceOutTax || form.salesPriceInTax" class="form-edit-line">
        <span v-if="form.salesPriceOutTax">￥{{form.salesPriceOutTax.toLocaleString()}} (税抜)<br v-if="form.salesPriceInTax"></span>
        <span v-if="form.salesPriceInTax">￥{{form.salesPriceInTax.toLocaleString()}} (税込)</span>
      </div>
      <div v-if="form.allRecordMusicCount" class="form-label align-bottom">総収録曲数</div>
      <div v-if="form.allRecordMusicCount" class="form-edit-line">
        {{form.allRecordMusicCount}}
      </div>
      <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-label align-bottom">総収録時間
      </div>
      <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-edit-line">
        <span v-if="form.playTimeH">{{ form.playTimeH }}時間</span>
        <span v-if="form.playTimeM">{{form.playTimeM }}分</span>
        <span v-if="form.playTimeS">{{ form.playTimeS }}秒</span>
      </div>
      <div class="card" style="margin-bottom: 10px;">
        <div class="card-header">計算</div>
        <div class="card-body" style="padding: 10px 5px 5px 10px;">
          <div class="form-label align-bottom">計算単位</div>
          <div class="form-edit-line">
            <span v-if="form.masterBreakDown">原盤単位</span>
            <span v-if="!form.masterBreakDown">アルバム単位</span>
          </div>
          <div class="form-label align-bottom">価格取得元</div>
          <div class="form-edit-line">
            <span v-if="form.priorityPrice === 'data'">実績データに価格情報がある場合、これを優先</span>
            <span v-if="form.priorityPrice === 'master'">本画面で登録された価格を優先</span>
          </div>
          <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-label align-bottom">管理割合</div>
          <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-edit-line">
            {{form.portionC}} / {{form.portionM}}
          </div>
          <div class="form-label align-bottom">計算対象</div>
          <div class="form-edit-line">
            <span v-if="form.calculateTarget">分配計算の対象とする</span>
            <span v-if="!form.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
          </div>
          <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
          <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
            <span v-if="!form.suspension">分配計算の対象とする</span>
            <span v-if="form.suspension">分配計算を保留する</span>
          </div>
        </div>
      </div>
      <div v-if="form.photoList && form.photoList.length > 0" class="form-label align-bottom">ジャケ写</div>
      <div v-if="form.photoList && form.photoList.length > 0" class="form-edit-line">
        <div v-for="(photo, index) in form.photoList" :key="photo.blobName">
          <a @click="selectPhoto(index)" href="#"><img v-if="photo.url" :src="photo.url" class="img-thumbnail float-start" style="max-width: 180px; margin: 5px;"></a>
        </div>
      </div>
      <div v-if="form.masterBreakDown === true || form.masterBreakDown === 'true'" class="card" style="margin-bottom: 10px;">
        <div class="card-header">収録原盤</div>
        <div class="card-body" style="padding: 5px;">
          <table class="table table-responsive">
            <tbody>
            <tr v-for="(record, index) in form.recordMusicList" :key="index">
              <td style="width: 40px; vertical-align: middle;">
                {{index + 1}}
              </td>
              <td>
                <span v-if="record.isrcList.length > 0" style="font-size: 80%;">ISRC : {{record.isrcList.map(x => x.isrc).join(' / ')}}<br></span>
                {{record.masterName}}
                <span v-if="record.masterArtistName" style="font-size: 80%;"><br>{{record.masterArtistName}}</span>
                <hr style="margin: 5px 0 5px 0;">
                <div style="font-size: 80%;">
                  <span v-if="record.managed" class="badge bg-info">管理</span>
                  <span v-if="!record.managed" class="badge bg-secondary">非管理</span>
                  <span v-if="record.playTimeH || record.playTimeM || record.playTimeS" style="margin-left: 5px;">収録時間 :</span>
                  <span v-if="record.playTimeH">{{record.playTimeH}}時間</span>
                  <span v-if="record.playTimeM">{{record.playTimeM}}分</span>
                  <span v-if="record.playTimeS">{{record.playTimeS}}秒</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="form.userCodeList && form.userCodeList.length > 0" class="card" style="margin-bottom: 10px;">
        <div class="card-header">報告コード</div>
        <div class="card-body" style="padding: 5px;">
          <table v-if="form.userCodeList" class="table table-responsive">
            <tbody>
            <tr v-for="(userCode, index) in form.userCodeList" :key="index">
              <td>{{userCode.userName}}</td>
              <td>{{userCode.code}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
        <div class="card-header">保留期</div>
        <div class="card-body" style="padding: 5px;">
          <table class="table table-responsive">
            <tbody>
            <tr v-for="pool in form.poolList" :key="pool.id">
              <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
              <td class="text-end">
                <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                <span v-else>解除しても支払わない</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="form.memo" class="form-label align-bottom">メモ</div>
      <div v-if="form.memo" class="form-edit-line">
        <span v-html="form.memo"></span>
      </div>
      <div class="form-label align-bottom">登録</div>
      <div class="form-edit-line">
        <span v-html="form.createdAt"></span>（{{form.createdBy}}）
      </div>
      <div class="form-label align-bottom">最終更新</div>
      <div class="form-edit-line">
        <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
      </div>
    </div>
    <div class="col-lg-5">
      <ul class="nav nav-tabs" style="margin-bottom: 10px;">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">
            契約書
          </a>
        </li>
      </ul>
      <div>
        <table v-if="form.contractList && form.contractList.length > 0" class="table table-responsive table-hover">
          <tbody>
          <tr v-for="contract in form.contractList" :key="contract.id">
            <td>
              <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
              {{contract.name}}
              <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { albumFunction } from '@/views/red/album/function'
import { contractFunction } from '@/views/red/contract/function'

export default defineComponent({
  props: ['albumId'],
  setup (props) {
    const albumFunc = albumFunction()
    const contractFunc = contractFunction()
    const form = ref({})
    const findContract = async (albumId) => {
      form.value.contractList = await contractFunc.findByAlbum(albumId)
    }
    onMounted(async () => {
      form.value = await albumFunc.get(props.albumId)
      await findContract(props.albumId)
    })
    return {
      form
    }
  }
})
</script>
