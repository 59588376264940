import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { usageStore } from './store'

export const usageFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const store = usageStore()
  const get = async (userSalesId, divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/' + divTermId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId, divTermId) => {
    const params = {
      companyId: companyId,
      companyDivTermId: divTermId,
      searchString: store.searchString,
      page: store.page,
      perPage: 20
    }
    if (store.status !== 'all') {
      params.status = store.status
    }
    if (store.incomeAmountType !== 'all') {
      params.incomeAmountType = store.incomeAmountType
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findFile = async (userSalesId, divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/' + divTermId + '/file/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findData = async (uploadedFileId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findUnMatch = async (uploadedFileId, page) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/list/unmatch', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        page: page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findError = async (uploadedFileId, page) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/error/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        page: page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getErrorCount = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/error/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getCheckCount = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getMatchCount = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/count/match', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUnMatchCount = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/count/unmatch', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUseTypeErrorCount = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/error/usetype/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const save = async (userSalesId, divTermId, form) => {
    await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/' + divTermId, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const getUploadedCount = async (userSalesId, divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/data/' + divTermId + '/file/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const getFileCountRegistered = async (usageId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/usage/' + usageId + '/file/count/registered', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const upload = async (blobName, fileName, userSalesDataId, usedMonthType, usedYear, usedMonth, taxRateId) => {
    await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/upload', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        blobName: blobName,
        fileName: fileName,
        userSalesDataId: userSalesDataId,
        usedMonthType: usedMonthType,
        usedYear: usedYear,
        usedMonth: usedMonth,
        taxRateId: taxRateId
      }
    })
  }
  const removeFile = async (uploadedFileId) => {
    await axios.delete(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const removeAllFiles = async (userSalesId, divTermId) => {
    await axios.delete(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/data/' + divTermId + '/file/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const register = async (uploadedFileId, continueOnError, incomeAmount, deductionRate, deductionAmount, unitPrice) => {
    await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/register', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        continue: continueOnError,
        deductionRate: deductionRate,
        deductionAmount: deductionAmount,
        incomeAmount: incomeAmount,
        unitPrice: unitPrice
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const matchWithMaster = async (uploadedFileId, masterId, code, codeType) => {
    await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId + '/match/master/' + masterId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        code: code,
        codeType: codeType
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const getUploadedFile = async (uploadedFileId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/data/file/uploaded/' + uploadedFileId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const calculate = (uploadedFileId) => {
    axios.post(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/calculate/usage/' + uploadedFileId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  const calculateSync = async (uploadedFileId) => {
    await axios.post(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/calculate/usage/' + uploadedFileId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const getStatusListUrl = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/status/list/url', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { divTermId: divTermId }
    })
    return data
  }
  const getUserSalesData = async (userSalesId, companyDivTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/user/sales/' + userSalesId + '/' + companyDivTermId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const batchRegister = async (blobName, companyId) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/batch/register', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        blobName: blobName,
        companyId: companyId
      }
    })
    return data
  }
  const batchDelete = async (companyId) => {
    await axios.delete(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/batch', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    })
  }
  const findUploadedFileAll = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/uploaded/file/list/all', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    })
    return data
  }
  return { get, find, getUploadedCount, getFileCountRegistered, findFile, findData, clear, save, upload, removeFile, removeAllFiles, register, findError, getCheckCount, getMatchCount, getUnMatchCount, findUnMatch, getUploadedFile, matchWithMaster, getErrorCount, calculate, getStatusListUrl, getUserSalesData, getUseTypeErrorCount, batchRegister, batchDelete, findUploadedFileAll, calculateSync }
}
