import RedPaymentDataDefinitionList from './List'
import RedPaymentDataDefinitionEdit from './Edit'
import RedPaymentDataDefinitionDisp from './Disp'

export default {
  routes: [
    {
      path: '/red/payment/data/definition/list',
      name: 'RedPaymentDataDefinitionList',
      component: RedPaymentDataDefinitionList
    },
    {
      path: '/red/payment/data/definition/add',
      name: 'RedPaymentDataDefinitionAdd',
      component: RedPaymentDataDefinitionEdit
    },
    {
      path: '/red/payment/data/definition/edit/:id',
      name: 'RedPaymentDataDefinitionEdit',
      component: RedPaymentDataDefinitionEdit
    },
    {
      path: '/red/payment/data/definition/disp/:id',
      name: 'RedPaymentDataDefinitionDisp',
      component: RedPaymentDataDefinitionDisp
    }
  ]
}
