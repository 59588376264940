import RedRightsHolderDisp from './Disp'
import RedRightsHolderEdit from './Edit'
import RedRightsHolderList from './List'

export default {
  routes: [
    {
      path: '/red/rightsholder/list',
      name: 'RedRightsHolderList',
      component: RedRightsHolderList
    },
    {
      path: '/red/rightsholder/edit/:id',
      name: 'RedRightsHolderEdit',
      component: RedRightsHolderEdit
    },
    {
      path: '/red/rightsholder/add',
      name: 'RedRightsHolderAdd',
      component: RedRightsHolderEdit
    },
    {
      path: '/red/rightsholder/disp/:id',
      name: 'RedRightsHolderDisp',
      component: RedRightsHolderDisp
    },
    {
      path: '/red/result/rightsholder/disp/:id/:resultId',
      name: 'RedRightsHolderResultDisp',
      component: RedRightsHolderDisp
    }
  ]
}
