<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <i class="fas fa-user" style="margin-right: 5px;"></i>Myアカウント
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/common/account/disp"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line fs-5" style="font-weight: bold;">
              <input v-model="form.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">
              メールアドレス
              <button @click="openEmailEditModal" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-edit-line">
              <table class="table table-responsive">
                <tbody>
                  <tr v-for="(email, index) in emailList" :key="email.id" :class="{'deleted-row': email.deleted}">
                    <td>{{email.email}}</td>
                    <td class="text-end" style="width: 250px;">
                      <button v-if="email.defaultEmail" class="btn btn-primary btn-sm" style="margin-right: 5px;">メイン</button>
                      <button v-if="!email.defaultEmail" @click="setDefaultEmail(index)" class="btn btn-secondary btn-sm" style="margin-right: 5px;">メイン</button>
                      <button v-if="email.public" @click="email.public = false" class="btn btn-success btn-sm" style="margin-right: 5px;">公開</button>
                      <button v-if="!email.public" @click="email.public = true" class="btn btn-secondary btn-sm" style="margin-right: 5px;">非公開</button>
                      <button @click="deleteEmail(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <span class="text-danger" style="text-decoration: underline;">※「公開」にすると、明細送信者にメールアドレスが開示されます。明細の受領は行うが、メールアドレスの開示はしたくない場合には「非公開」にしてください。</span>
              </div>
            </div>
            <div class="form-label align-bottom">
              携帯電話番号
              <button @click="openCellPhoneEditModal" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
            </div>
            <div v-if="form.cellPhone" class="form-edit-line">
              {{ form.cellPhone }}
            </div>
            <div v-else class="form-edit-line">
              未登録
            </div>
            <div v-if="form.cellPhone" class="form-label align-bottom">ログイン方法</div>
            <div v-if="form.cellPhone" class="form-edit-line">
              <select v-model="form.loginMethod" class="form-select">
                <option value="EMAIL">メール</option>
                <option value="SMS">SMS</option>
              </select>
            </div>
            <div class="form-label align-bottom">お知らせのメール受信</div>
            <div class="form-edit-line">
              <div class="form-check form-switch" style="margin-right: 5px;">
                <input v-model="form.receiveInformation" class="form-check-input" type="checkbox" id="receiveInformation">
                <label class="form-check-label" for="receiveInformation">システム停止等のお知らせ</label>
              </div>
              <div class="form-check form-switch" style="margin-right: 5px;">
                <input v-model="form.receiveVersionInformation" class="form-check-input" type="checkbox" id="receiveVersionInformation">
                <label class="form-check-label" for="receiveVersionInformation">バージョンアップのお知らせ</label>
              </div>
            </div>
            <div v-show="session.session.red" class="form-label align-bottom">RED取込結果メール通知</div>
            <div v-show="session.session.red" class="form-edit-line">
              <div class="form-check form-switch" style="margin-right: 5px;">
                <input v-model="form.redImportNotify" class="form-check-input" type="checkbox" id="redImportNotify">
                <label class="form-check-label" for="redImportNotify">受け取る</label>
              </div>
            </div>
            <div v-if="invoice">
              <div class="form-label align-bottom">課税事業者／免税事業者</div>
              <div class="form-edit-line">
                <select v-model="invoice.taxationType" class="form-select">
                  <option value="taxation">課税事業者</option>
                  <option value="exemption">免税事業者</option>
                </select>
              </div>
              <div class="form-label align-bottom">事業者登録番号</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">T</span>
                  <input v-model="invoice.invoiceNo" type="text" class="form-control" maxlength="13" style="flex: 0 1 160px;">
                </div>
              </div>
              <div v-if="agent">
                <div class="form-label align-bottom">代理登録分</div>
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item" v-for="(superior, index) in superiorList" :key="superior.id">
                    <a @click="changeSuperior(index)" class="nav-link" :class="{ 'active': selectedSuperior === superior.id }" aria-current="page" href="#">
                      {{superior.name}}
                    </a>
                  </li>
                </ul>
                <div v-for="superior in superiorList" :key="superior.id">
                  <div v-if="this.superior === superior.id">
                    <div v-if="superior.invoice" class="form-label align-bottom">課税事業者／免税事業者</div>
                    <div v-if="superior.invoice" class="form-value">
                      <select v-model="superior.invoice.taxationType" class="form-select">
                        <option value="taxation">課税事業者</option>
                        <option value="exemption">免税事業者</option>
                      </select>
                    </div>
                    <div v-if="superior.invoice && superior.invoice.taxationType === 'taxation'" class="form-label align-bottom">事業者登録番号</div>
                    <div v-if="superior.invoice && superior.invoice.taxationType === 'taxation'" class="form-value">
                      <input v-model="superior.invoice.invoiceNo" type="text" class="form-control" maxlength="13" style="flex: 0 1 160px;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="emailEditModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メールアドレス追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>新しいメールアドレス</div>
            <div class="form-edit-line">
              <input v-if="!isAuthCodeSent" @keyup="inputEmail" v-model="newEmail" type="text" class="form-control">
              <input v-if="isAuthCodeSent" v-model="newEmail" type="text" class="form-control" readonly>
            </div>
            <div v-if="isAuthCodeSent" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>認証コード</div>
            <div v-if="isAuthCodeSent" class="form-edit-line">
              <input v-model="authCode" type="text" class="form-control">
            </div>
            <div v-if="isExistsEmail === 'exists'" class="alert alert-danger">
              既に登録済みのメールアドレスです。このまま追加を実行すると、指定されたメールアドレスを本アカウントに移動し、旧アカウントから指定のメールアドレスが削除されます。
            </div>
            <div class="alert alert-danger">
              ここでは、ご自身のメールアドレスが複数ある場合、または使用するメールアドレスを変更する場合にのみ追加をしてください。
              別の担当者のアドレスを追加する場合は、送付元に送付先追加をお願いするか、受領設定よりCCで受け取るアドレスを追加してください。
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeEmailEditModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button v-if="!isAuthCodeSent && isExistsEmail !== ''" @click="sendAuthCodeByEmail" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>認証コード送信</button>
              <button v-if="isAuthCodeSent" @click="addEmail" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="cellPhoneEditModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">携帯電話番号変更</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="form-label align-bottom">現在の携帯電話番号</div>
            <div class="form-edit-line">
              <input v-model="form.cellPhone" type="text" class="form-control" readonly>
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>新しい携帯電話番号</div>
            <div class="form-edit-line">
              <input v-if="!isAuthCodeSent" v-model="newCellPhone" type="text" class="form-control">
              <input v-if="isAuthCodeSent" v-model="newCellPhone" type="text" class="form-control" readonly>
            </div>
            <div v-if="isAuthCodeSent" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>認証コード</div>
            <div v-if="isAuthCodeSent" class="form-edit-line">
              <input v-model="authCode" type="text" class="form-control">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeCellPhoneEditModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button v-if="!isAuthCodeSent" @click="sendAuthCodeBySms" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>認証コード送信</button>
              <button v-if="isAuthCodeSent" @click="saveCellPhone" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index"><span v-html="message"></span>
        </p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { accountFindFunction } from './function'
import axios from 'axios'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const accountFind = accountFindFunction()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const emailEditModalDiv = ref(null)
    let emailEditModal = null
    const cellPhoneEditModalDiv = ref(null)
    let cellPhoneEditModal = null
    // Form
    const form = ref(null)
    const emailList = ref([])
    const invoice = ref({})
    const agent = ref(false)
    const superiorList = ref([])
    const superior = ref({})
    const selectedSuperior = ref(null)
    const newEmail = ref('')
    const newCellPhone = ref('')
    const authCode = ref('')
    const isAuthCodeSent = ref(false)
    const isExistsEmail = ref('')
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      const promises = []
      promises.push(accountFind.get())
      promises.push(accountFind.findEmail())
      promises.push(accountFind.getInvoice())
      promises.push(accountFind.findSuperior())
      const response = await Promise.all(promises)
      form.value = response[0]
      emailList.value = response[1]
      invoice.value = response[2]
      superiorList.value = response[3]
      if (superiorList.value.length > 0) {
        agent.value = true
        superior.value = superiorList.value[0]
      } else {
        agent.value = false
      }
      for (const emailModel of emailList.value) {
        emailModel.authCode = null
      }
    }
    const save = async () => {
      errors.value = []
      errorToast.hide()
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (invoice.value.taxationType === 'taxation') {
        if (!invoice.value.invoiceNo) {
          errors.value.push('事業者登録番号を入力してください')
        } else {
          if (isNaN(invoice.value.invoiceNo)) {
            errors.value.push('事業者登録番号は半角数字で入力してください')
          }
          if (invoice.value.invoiceNo.length !== 13) {
            errors.value.push('事業者登録番号は13桁で入力してください')
          }
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です。しばらくお待ちください。'
        processModal.show()
        emailList.value.forEach((emailModel, index) => {
          if (emailModel.deleted) {
            emailList.value.splice(index, 1)
          }
        })
        await axios.put(process.env.VUE_APP_API_ENDPOINT + '/self/setting', {
          name: form.value.name,
          loginMethod: form.value.loginMethod,
          nickname: null,
          avatarBlob: null,
          receiveInformation: (form.value.receiveInformation ? 'YES' : 'NO'),
          receiveVersionInformation: (form.value.receiveVersionInformation ? 'YES' : 'NO'),
          redImportNotify: form.value.redImportNotify,
          cellPhone: form.value.cellPhone,
          emailList: emailList.value
        }, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).catch(error => {
          if (error.response.status === 401) {
            errors.value = ['追加されたメールアドレスの認証コードが誤っています。']
            errorToast.show()
          } else {
            ex.identifyEx(error)
          }
        })
        if (invoice.value) {
          await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/account/invoice', {
            taxationType: invoice.value.taxationType,
            invoiceNo: invoice.value.invoiceNo,
            toTaxationSchedule: invoice.value.toTaxationSchedule,
            invoiceName: invoice.value.invoiceName
          }, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).catch(error => { ex.identifyEx(error) })
          for (const superior of superiorList.value) {
            let invoiceNo = superior.invoice.invoiceNo
            if (superior.invoice.taxationType !== 'taxation') {
              invoiceNo = ''
            }
            await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + superior.id + '/invoice', {
              taxationType: superior.invoice.taxationType,
              invoiceNo: invoiceNo,
              toTaxationSchedule: superior.invoice.toTaxationSchedule,
              invoiceName: superior.invoice.invoiceName
            }, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).catch(error => { ex.identifyEx(error) })
          }
        }
        processModal.hide()
        await router.push('/common/account/disp')
      }
    }
    const setDefaultEmail = (index) => {
      for (const email of emailList.value) {
        email.defaultEmail = false
      }
      emailList.value[index].defaultEmail = true
    }
    const changeSuperior = (index) => {
      const superior = superiorList.value[index]
      selectedSuperior.value = superior.id
    }
    const openEmailEditModal = () => {
      isAuthCodeSent.value = false
      newEmail.value = ''
      isExistsEmail.value = ''
      emailEditModal.show()
    }
    const closeEmailEditModal = () => {
      emailEditModal.hide()
    }
    const openCellPhoneEditModal = () => {
      isAuthCodeSent.value = false
      newCellPhone.value = ''
      cellPhoneEditModal.show()
    }
    const closeCellPhoneEditModal = () => {
      cellPhoneEditModal.hide()
    }
    const inputEmail = async () => {
      if (!newEmail.value) return
      if (newEmail.value === form.value.email) {
        isExistsEmail.value = ''
        return
      }
      isExistsEmail.value = ''
      if (await accountFind.existsEmail(newEmail.value)) {
        isExistsEmail.value = 'exists'
      } else {
        isExistsEmail.value = 'not_exists'
      }
    }
    const sendAuthCodeByEmail = async () => {
      messages.value = []
      errors.value = []
      messageToast.hide()
      errorToast.hide()
      if (!newEmail.value) {
        errors.value.push('新しいメールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(newEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '認証コードの送信中です。しばらくお待ちください。'
        emailEditModal.hide()
        processModal.show()
        await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/code/email/' + newEmail.value)
        isAuthCodeSent.value = true
        messages.value.push('認証コードが送信されました')
        messageToast.show()
        emailEditModal.show()
        processModal.hide()
      }
    }
    const sendAuthCodeBySms = async () => {
      messages.value = []
      errors.value = []
      messageToast.hide()
      errorToast.hide()
      if (!newCellPhone.value) {
        errors.value.push('新しい携帯電話番号を入力してください')
      } else {
        const reg = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/
        if (!reg.test(newCellPhone.value.replaceAll('-', '')) || newCellPhone.value.replaceAll('-', '').length !== 11) {
          errors.value.push('電話番号を正しく入力してください。使用できる文字は、半角数字と半角ハイフン（-）のみです。')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '認証コードの送信中です。しばらくお待ちください。'
        cellPhoneEditModal.hide()
        processModal.show()
        await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/code/sms/' + newCellPhone.value)
        isAuthCodeSent.value = true
        messages.value.push('認証コードが送信されました')
        messageToast.show()
        cellPhoneEditModal.show()
        processModal.hide()
      }
    }
    const addEmail = async () => {
      errors.value = []
      errorToast.hide()
      if (!authCode.value) {
        errors.value.push('認証コードを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        // let validatedEmail = false
        // await axios.get(process.env.VUE_APP_API_ENDPOINT + '/self/setting/email/add/' + newEmail.value + '/' + authCode.value, {
        //   headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        // }).then(() => {
        //   validatedEmail = true
        // }).catch(() => {
        //   validatedEmail = false
        // })
        // if (validatedEmail) {
        emailList.value.push({
          email: newEmail.value,
          authCode: authCode.value,
          defaultEmail: false,
          public: false
        })
        closeEmailEditModal()
        // } else {
        //   errors.value.push('認証コードが誤っています')
        //   errorToast.show()
        // }
        console.log(emailList.value)
      }
    }
    const saveCellPhone = async () => {
      messages.value = []
      errors.value = []
      messageToast.hide()
      errorToast.hide()
      if (!authCode.value) {
        errors.value.push('認証コードを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        try {
          await axios.put(process.env.VUE_APP_API_ENDPOINT + '/self/setting/sms/' + newCellPhone.value + '/' + authCode.value, {}, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
          messages.value.push('携帯電話番号が更新されました')
          messageToast.show()
          cellPhoneEditModal.hide()
        } catch (error) {
          if (error.response?.status === 401) {
            errors.value.push('認証コードが正しくありません')
            errorToast.show()
          } else {
            await ex.identifyEx(error)
          }
        }
      }
    }
    const deleteEmail = (index) => {
      if (!emailList.value[index].deleted && emailList.value.length - emailList.value.filter(x => x.deleted).length === 1) {
        errors.value = ['最低でも1件のメールアドレスが必要です。']
        errorToast.show()
      } else {
        emailList.value[index].deleted = !emailList.value[index].deleted
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      emailEditModal = new Modal(emailEditModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      cellPhoneEditModal = new Modal(cellPhoneEditModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init()
    })
    return {
      session,
      processMessage,
      processModalDiv,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      emailEditModalDiv,
      cellPhoneEditModalDiv,
      route,
      router,
      form,
      emailList,
      invoice,
      agent,
      superior,
      superiorList,
      newEmail,
      newCellPhone,
      authCode,
      isAuthCodeSent,
      save,
      setDefaultEmail,
      changeSuperior,
      openEmailEditModal,
      openCellPhoneEditModal,
      closeEmailEditModal,
      closeCellPhoneEditModal,
      sendAuthCodeByEmail,
      sendAuthCodeBySms,
      addEmail,
      saveCellPhone,
      deleteEmail,
      isExistsEmail,
      inputEmail
    }
  }
})
</script>
