import { defineStore } from 'pinia'
import { ref } from 'vue'

export const rightsholderStore = defineStore('green_rights_holder', () => {
  const system = ref('')
  const capoCompanyIndex = ref(-1)
  const jrcaCompanyIndex = ref(-1)
  const contanoCompanyIndex = ref(-1)
  const redCompanyIndex = ref(-1)
  const page = ref(0)
  const detailSearchMode = ref(false)
  const detailCondition = ref({
    useOnlineStatement: 'all',
    destinationNotCreated: false,
    registered: 'all',
    searchString: '',
    sortColumn: 'name',
    sortOrder: 'asc',
    sortColumnE: 'name',
    sortOrderE: 'asc'
  })
  return { system, capoCompanyIndex, jrcaCompanyIndex, contanoCompanyIndex, redCompanyIndex, detailCondition, detailSearchMode, page }
})
