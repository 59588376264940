import RedAlbumDisp from './Disp'
import RedAlbumEdit from './Edit'
import RedAlbumList from './List'

export default {
  routes: [
    {
      path: '/red/album/list',
      name: 'RedAlbumList',
      component: RedAlbumList
    },
    {
      path: '/red/album/add',
      name: 'RedAlbumAdd',
      component: RedAlbumEdit
    },
    {
      path: '/red/album/edit/:id',
      name: 'RedAlbumEdit',
      component: RedAlbumEdit
    },
    {
      path: '/red/album/copy/:id',
      name: 'RedAlbumCopy',
      component: RedAlbumEdit
    },
    {
      path: '/red/album/disp/:id',
      name: 'RedAlbumDisp',
      component: RedAlbumDisp
    },
    {
      path: '/red/album/disp/:id/unmatched',
      name: 'RedAlbumDispUnmatched',
      component: RedAlbumDisp
    },
    {
      path: '/red/result/album/disp/:id/:resultId',
      name: 'RedAlbumDispResult',
      component: RedAlbumDisp
    }
  ]
}
