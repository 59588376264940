<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/payment/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>
                      支払明細一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form" :to="'/red/payment/edit/' + form.id" class="btn btn-outline-danger" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>鑑修正</router-link>
              <button @click="textDownload" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-text fa-fw" style="margin-right: 5px;"></i>Text</button>
              <button @click="pdfDownload" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-pdf fa-fw" style="margin-right: 5px;"></i>PDF</button>
              <button @click="headerDownload" class="btn btn-outline-primary"><i class="fas fa-file-pdf fa-fw" style="margin-right: 5px;"></i>鑑のみ</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 10px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div v-if="form.protect" style="margin-bottom: 10px;">
            <span class="badge bg-danger">保護されています</span>
          </div>
          <div v-if="form.rightsHolderCode" class="form-label align-bottom">権利者コード</div>
          <div v-if="form.rightsHolderCode" class="form-edit-line">
            {{ form.rightsHolderCode }}
          </div>
          <div class="form-label align-bottom">権利者名</div>
          <div class="form-edit-line fs-5">
            <button @click="openRightsHolderRefModal(form.rightsHolderId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{ form.rightsHolderName }}
          </div>
          <div class="form-label align-bottom">消費税</div>
          <div class="form-edit-line">
            <span v-if="form.taxation">課税</span>
            <span v-else>非課税</span>
          </div>
          <div class="form-label align-bottom">源泉</div>
          <div class="form-edit-line">
            <span v-if="form.withholdingTaxation === 'personal'">個人（10.21% 〜 20.42%）</span>
            <span v-else-if="form.withholdingTaxation === 'fix'">定率（{{form.withholdingRate}}%）</span>
            <span v-else>非課税</span>
          </div>
          <div class="form-label align-bottom">課税事業者／免税事業者</div>
          <div class="form-edit-line">
            <span v-if="form.taxationType === 'taxation'">課税事業者（T{{form.invoiceNo}}）</span>
            <span v-else>免税事業者</span>
          </div>
          <div class="form-label align-bottom">消費税相当額の控除</div>
          <div class="form-edit-line">
            <span v-if="invoiceExemptionTaxPayable === 'pay'">控除せずに支払う</span>
            <span v-else>
              控除する
              （
              少額特例 : <span v-if="invoiceSmallException === 'adapt'">対応</span><span v-else>対応しない</span>
              /
              経過措置 : <span v-if="invoiceTransitionalMeasures === 'adapt'">対応</span><span v-else>対応しない</span>
              /
              控除額 : <span v-if="company.invoiceDeductionTarget === 'tax'">控除前消費税額相当</span><span v-else>控除前課税対象額から算出</span>
              ）
            </span>
          </div>
          <div class="form-label align-bottom">計算書言語</div>
          <div class="form-edit-line">
            <span v-if="form.statementLang === 'japanese'">日本語</span>
            <span v-if="form.statementLang === 'english'">英語</span>
          </div>
          <div class="form-label align-bottom">0円明細</div>
          <div class="form-edit-line">
            <span v-if="form.statementDetailZeroOmit">出力しない</span>
            <span v-if="!form.statementDetailZeroOmit">出力する</span>
          </div>
          <ul class="nav nav-tabs" style="margin-bottom: 10px;">
            <li class="nav-item">
              <a @click="rightAreaMode = 'ADDRESS'" class="nav-link" :class="{ 'active': rightAreaMode === 'ADDRESS' }" aria-current="page" href="#">
                明細書送付先
              </a>
            </li>
            <li class="nav-item">
              <a @click="rightAreaMode = 'BANK'" class="nav-link" :class="{ 'active': rightAreaMode === 'BANK' }" aria-current="page" href="#">
                振込先
              </a>
            </li>
          </ul>
          <div v-if="rightAreaMode === 'ADDRESS'">
            <div v-if="(form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding)" class="form-label align-bottom">送付先住所</div>
            <div v-if="(form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding)" class="form-edit-line">
              <p v-if="form.statementPostalCode" style="margin: 0;">〒{{ form.statementPostalCode }}</p>
              <p style="margin: 0;">{{ form.statementPrefecture }}{{ form.statementCity }}{{ form.statementTownArea }}</p>
              <p style="margin: 0;">{{ form.statementBuilding }}</p>
            </div>
            <div v-if="form.statement_tel" class="form-label align-bottom">送付先電話番号</div>
            <div v-if="form.statement_tel" class="form-edit-line">
              {{ form.statementTel }}
            </div>
            <div v-if="form.statement_email" class="form-label align-bottom">送付先メールアドレス</div>
            <div v-if="form.statement_email" class="form-edit-line">
              {{ form.statementEmail }}
            </div>
            <div v-if="form.statementGreeting" class="form-label align-bottom">挨拶文</div>
            <div v-if="form.statementGreeting" class="form-edit-line">
              <span v-html="form.statementGreeting.replaceAll('\n', '<br>')"></span>
            </div>
          </div>
          <div v-if="rightAreaMode === 'BANK'">
            <div v-if="form.bankName" class="form-label align-bottom">銀行</div>
            <div v-if="form.bankName" class="form-edit-line">
              {{ form.bankName }}（{{ form.bankCode }}）
            </div>
            <div v-if="form.branchName" class="form-label align-bottom">支店</div>
            <div v-if="form.branchName" class="form-edit-line">
              {{ form.branchName }}（{{ form.branchCode }}）
            </div>
            <div v-if="form.accountNo" class="form-label align-bottom">預金種目</div>
            <div v-if="form.accountNo" class="form-edit-line">
              <span v-if="form.depositItem === '1'">普通</span>
              <span v-if="form.depositItem === '2'">当座</span>
              <span v-if="form.depositItem === '4'">貯蓄</span>
              <span v-if="form.depositItem === '9'">その他</span>
            </div>
            <div v-if="form.accountNo" class="form-label align-bottom">口座番号</div>
            <div v-if="form.accountNo" class="form-edit-line">
              {{ form.accountNo }}
            </div>
            <div v-if="form.accountName" class="form-label align-bottom">口座名義</div>
            <div v-if="form.accountName" class="form-edit-line">
              {{ form.accountName }}
            </div>
            <div v-if="form.accountKana" class="form-label align-bottom">口座名義カナ</div>
            <div v-if="form.accountKana" class="form-edit-line">
              {{ form.accountKana }}
            </div>
          </div>
          <ul class="nav nav-tabs" style="margin-bottom: 10px;">
            <li class="nav-item">
              <a @click="bottomAreaMode = 'ALL'" class="nav-link" :class="{ 'active': bottomAreaMode === 'ALL' }" aria-current="page" href="#">
                支払額
              </a>
            </li>
            <li class="nav-item">
              <a @click="bottomAreaMode = 'TAX'" class="nav-link" :class="{ 'active': bottomAreaMode === 'TAX' }" aria-current="page" href="#">
                消費税別内訳
              </a>
            </li>
            <li class="nav-item">
              <a @click="bottomAreaMode = 'DETAIL'" class="nav-link" :class="{ 'active': bottomAreaMode === 'DETAIL' }" aria-current="page" href="#">
                明細
              </a>
            </li>
            <li v-if="fileList.length > 0" class="nav-item">
              <a @click="bottomAreaMode = 'ATTACHMENT'" class="nav-link" :class="{ 'active': bottomAreaMode === 'ATTACHMENT' }" aria-current="page" href="#">
                添付ファイル
              </a>
            </li>
          </ul>
          <div v-if="bottomAreaMode === 'ATTACHMENT'">
            <table class="table table-responsive">
              <tbody>
                <tr v-for="file in fileList" :key="file.blobName">
                  <td><a :href="file.url">{{file.fileName}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="bottomAreaMode === 'ALL'">
            <div class="form-label align-bottom">(A) 前期繰越額</div>
            <div class="form-edit-line">
              ￥{{ form.amountBroughtForward.toLocaleString() }}
            </div>
            <div class="form-label align-bottom">(B) 今期発生額</div>
            <div class="form-edit-line">
              ￥{{ form.amountInThisTerm.toLocaleString() }}
            </div>
            <div class="form-label align-bottom">(C) 合計発生額 (A + B)</div>
            <div class="form-edit-line">
              ￥{{ form.totalAmount.toLocaleString() }}
            </div>
            <div v-if="form.managementFeeRateType === 'out_tax'" class="form-label align-bottom">(D) 管理手数料（税抜）</div>
            <div v-if="form.managementFeeRateType === 'out_tax'" class="form-edit-line">
              ￥{{ form.managementFeeAmount.toLocaleString() }}
            </div>
            <div class="form-label align-bottom">(E) 相殺対象額（税抜） (C - D)</div>
            <div class="form-edit-line">
              ￥{{ form.offsetTargetAmountOutTax.toLocaleString() }}
            </div>
            <div class="form-label align-bottom">(F) アドバンス相殺額（税抜）</div>
            <div class="form-edit-line">
              ￥{{ form.offsetAmountOutTax.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax !== 0" class="form-label align-bottom">(G) 次期繰越額（税抜） (E - F)</div>
            <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax !== 0" class="form-edit-line">
              ￥{{ form.amountCarriedForwardOutTax.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(H) 控除額</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.invoiceDeductionAmount.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(I) 課税対象額 (E - F - H)</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.beforeTaxAmount.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(J) 消費税額</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.taxAmount.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(K) 源泉徴収額</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.withholdingTaxAmount.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(L) 相殺対象額（税込） (H + I - J)</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.offsetTargetAmountInTax.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">(M) アドバンス相殺額（税込）</div>
            <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
              ￥{{ form.offsetAmountInTax.toLocaleString() }}
            </div>
            <hr>
            <div v-if="form.payOrCarryForward === 'pay'" class="form-label align-bottom">支払額 (L - M)</div>
            <div v-if="form.payOrCarryForward === 'pay'" class="form-edit-line fs-4">
              ￥{{ form.paymentAmount.toLocaleString() }}
            </div>
            <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">次期繰越額（税込）</div>
            <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax === 0" class="form-edit-line fs-4">
              ￥{{ form.amountCarriedForwardInTax.toLocaleString() }}
            </div>
          </div>
          <div v-if="bottomAreaMode === 'TAX'">
            <table class="table table-responsive table-bordered">
              <thead>
                <tr class="text-center">
                  <td style="width: 130px;">税率</td>
                  <td v-for="tax in taxList" :key="tax.rate">{{tax.rate}}%</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>前期繰越</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.amountBroughtForward.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>今期発生</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.amountInThisTerm.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>合計発生</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.totalAmount.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>管理手数料</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">
                    ￥{{tax.amount.managementFeeAmount.toLocaleString()}}</span>
                  </td>
                </tr>
                <tr>
                  <td>相殺（税抜）</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.offsetAmountOutTax.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>控除額</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.invoiceDeductionAmount.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>課税対象</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.beforeTaxAmount.toLocaleString()}}</span></td>
                </tr>
                <tr>
                  <td>消費税</td>
                  <td v-for="tax in taxList" :key="tax.rate" class="text-end"><span v-if="tax.amount">￥{{tax.amount.taxAmount.toLocaleString()}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="bottomAreaMode === 'DETAIL'">
            <div v-if="form" class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="detailCondition.page = 0; findStatementDetail(form.id);" v-model="detailCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button @click="detailList = []; detailCondition.searchString = ''; detailCondition.page = 0; findStatementDetail(form.id)" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="detailCondition.page = 0; findStatementDetail(form.id);" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="form" class="table">
              <thead>
                <tr class="align-middle" style="word-break: normal;">
                  <th style="width: 80px;">単位</th>
                  <th>タイトル</th>
                  <th>アーティスト</th>
                  <th>受領額</th>
                  <th>支払額</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="detail in detailList" :key="detail.id">
                  <td>
                    <span v-if="detail.dataUnit === 'master'">原盤</span>
                    <span v-if="detail.dataUnit === 'album'">アルバム</span>
                  </td>
                  <td>
                    <span v-if="detail.dataUnit === 'master'">{{detail.masterName}}</span>
                    <span v-if="detail.dataUnit === 'album'">{{detail.albumName}}</span>
                  </td>
                  <td>
                    <span v-if="detail.dataUnit === 'master'">{{detail.masterArtistName}}</span>
                    <span v-if="detail.dataUnit === 'album'">{{detail.albumArtistName}}</span>
                  </td>
                  <td class="text-end">{{detail.incomeAmount.toLocaleString()}}</td>
                  <td class="text-end">{{detail.paymentAmount.toLocaleString()}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rightsHolderRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refRightsHolderId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">権利者詳細</h5>
          </div>
          <div class="modal-body text-start">
            <rights-holder-modal :rightsHolder-id="refRightsHolderId"></rights-holder-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeRightsHolderRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoRightsHolder(refRightsHolderId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>権利者画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import footerComponent from '@/components/Footer'
import { paymentFunction } from './function'
import { redCompanyFunction } from '@/functions/common'
import { detailStore } from './store'
import rightsHolderModal from '@/components/modal/RightsHolderModal'

export default defineComponent({
  components: { rightsHolderModal, footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    // Form
    const paymentFunc = paymentFunction()
    const companyFunc = redCompanyFunction()
    const form = ref(null)
    const taxList = ref([])
    const detailResult = ref({})
    const detailList = ref([])
    const rightAreaMode = ref('ADDRESS')
    const bottomAreaMode = ref('ALL')
    const fileList = ref([])
    const company = ref({})
    const rightsHolder = ref({})
    const invoiceExemptionTaxPayable = ref('pay')
    const invoiceSmallException = ref('adapt')
    const invoiceTransitionalMeasures = ref('adapt')
    const detailCondition = detailStore()
    // 権利者詳細
    const rightsHolderRefModalDiv = ref({})
    let rightsHolderRefModal = null
    const refRightsHolderId = ref('')
    // メソッド
    const pdfDownload = async () => {
      processMessage.value = 'PDFの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      let blob = await paymentFunc.getStatementBlob(form.value.id)
      if (!blob || !blob.pdfBlob) {
        await paymentFunc.createFile(form.value.id)
        blob = await paymentFunc.getStatementBlob(form.value.id)
      }
      const url = await paymentFunc.getStatementUrl(form.value.id, blob.pdfBlob)
      const a = document.createElement('a')
      a.href = url
      a.click()
      processModal.hide()
    }
    const headerDownload = async () => {
      processMessage.value = 'PDFの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      let blob = await paymentFunc.getStatementBlob(form.value.id)
      if (!blob || !blob.pdfHeaderBlob) {
        await paymentFunc.createFile(form.value.id)
        blob = await paymentFunc.getStatementBlob(form.value.id)
      }
      const url = await paymentFunc.getStatementUrl(form.value.id, blob.pdfHeaderBlob)
      const a = document.createElement('a')
      console.log(url)
      a.href = url
      a.click()
      processModal.hide()
    }
    const textDownload = async () => {
      processMessage.value = 'テキストデータの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      let blob = await paymentFunc.getStatementBlob(form.value.id)
      if (!blob || !blob.textBlob) {
        await paymentFunc.createFile(form.value.id)
        blob = await paymentFunc.getStatementBlob(form.value.id)
      }
      const url = await paymentFunc.getStatementUrl(form.value.id, blob.textBlob)
      const a = document.createElement('a')
      console.log(url)
      a.href = url
      a.click()
      processModal.hide()
    }
    async function init (id) {
      const paymentPromises = []
      paymentPromises.push(paymentFunc.get(id))
      paymentPromises.push(paymentFunc.findStatementTax(id))
      paymentPromises.push(paymentFunc.findStatementDetail(id, detailCondition))
      paymentPromises.push(paymentFunc.findStatementAttachment(id))
      const paymentResponse = await Promise.all(paymentPromises)
      form.value = paymentResponse[0]
      taxList.value = paymentResponse[1]
      detailResult.value = paymentResponse[2]
      detailList.value = detailResult.value.list
      fileList.value = paymentResponse[3]
      company.value = await companyFunc.get(session.session.company.id)
      if (rightsHolder.value.useRightsHolderSetting) {
        invoiceExemptionTaxPayable.value = rightsHolder.value.invoiceExemptionTaxPayable
        invoiceSmallException.value = rightsHolder.value.invoiceSmallException
        invoiceTransitionalMeasures.value = rightsHolder.value.invoiceTransitionalMeasures
      } else {
        invoiceExemptionTaxPayable.value = company.value.invoiceExemptionTaxPayable
        invoiceSmallException.value = company.value.invoiceSmallException
        invoiceTransitionalMeasures.value = company.value.invoiceTransitionalMeasures
      }
    }
    const findStatementDetail = async (id) => {
      detailResult.value = await paymentFunc.findStatementDetail(id, detailCondition)
      detailList.value = detailResult.value.list
    }
    const openRightsHolderRefModal = async (rightsHolderId) => {
      refRightsHolderId.value = rightsHolderId
      rightsHolderRefModal.show()
    }
    const closeRightsHolderRefModal = () => {
      refRightsHolderId.value = null
      rightsHolderRefModal.hide()
    }
    const gotoRightsHolder = (id) => {
      rightsHolderRefModal.hide()
      router.push('/red/rightsholder/disp/' + id)
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rightsHolderRefModal = new Modal(rightsHolderRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      processMessage,
      processModalDiv,
      form,
      company,
      rightsHolder,
      invoiceExemptionTaxPayable,
      invoiceSmallException,
      invoiceTransitionalMeasures,
      taxList,
      detailResult,
      detailList,
      rightAreaMode,
      bottomAreaMode,
      pdfDownload,
      headerDownload,
      textDownload,
      fileList,
      detailCondition,
      findStatementDetail,
      openRightsHolderRefModal,
      closeRightsHolderRefModal,
      gotoRightsHolder,
      rightsHolderRefModalDiv,
      refRightsHolderId
    }
  }
})
</script>
