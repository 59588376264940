<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page">
                    <i class="fas fa-info-circle fa-fw" style="margin-right: 5px;"></i> [管理者用] お知らせ
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/admin/message/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 90px; overflow: auto;">
          <table class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 150px;">種別</th>
                <th style="width: 130px;">作成日時</th>
                <th style="width: 130px;">公開日時</th>
                <th>タイトル</th>
              </tr>
            </thead>
            <tbody v-if="messageResult">
              <tr v-for="message in messageList" :key="message.id" class="text-start">
                <td>
                  <span v-if="message.messageType === 'GLOBAL'">停止等の案内</span>
                  <span v-if="message.messageType === 'VERSION'">バージョンアップ</span>
                </td>
                <td>{{message.createdAtS}}</td>
                <td>
                  <span v-if="message.publishedAt">{{message.publishedAtS}}</span>
                  <span v-else>未公開</span>
                </td>
                <td><router-link :to="'/admin/message/disp/' + message.id">{{message.title}}</router-link></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="messageResult">{{messageResult.allRecords.toLocaleString()}}件の一致データ</span>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <nav v-if="messageResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{store.page + 1}}/{{messageResult.allPages}}ページ</span></li>
                <li v-if="store.page !== messageResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== messageResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { messageStore } from './store'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const store = messageStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // 検索
    const messageResult = ref(null)
    const messageList = ref([])
    // メソッド
    const find = () => {
      loadingModal.show()
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/message/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          searchString: store.searchString,
          page: store.page,
          perPage: 20
        }
      }).then(response => {
        messageResult.value = response.data
        messageList.value = messageResult.value.list
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      loadingModal.hide()
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = messageResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      find()
    })
    return {
      loadingModalDiv,
      store,
      messageResult,
      messageList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
