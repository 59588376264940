import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const definitionFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const findAll = async () => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: '',
        page: 0,
        perPage: 2147483647
      }
    }).catch(error => { ex.identifyEx(error) })
    if (data.list) {
      return data.list
    } else {
      return []
    }
  }
  const findItem = async (definitionId, userSalesId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + definitionId + '/item/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { userSalesId: userSalesId }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const findColumns = async (definitionId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + definitionId + '/item/column/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const insert = async (form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/definition/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, findAll, findItem, clearSearch, insert, update, remove, findColumns, indexUpdate }
}
