import RedPaymentRecordList from './List'
import RedPaymentRecordDisp from './Disp'
import RedPaymentRecordEdit from './Edit.vue'

export default {
  routes: [
    {
      path: '/red/paymentrecord/list',
      name: 'RedPaymentRecordList',
      component: RedPaymentRecordList
    },
    {
      path: '/red/paymentrecord/disp/:rightsHolderId/:withholdingTaxation/:year',
      name: 'RedPaymentRecordDisp',
      component: RedPaymentRecordDisp
    },
    {
      path: '/red/paymentrecord/edit/:rightsHolderId/:withholdingTaxation/:year',
      name: 'RedPaymentRecordEdit',
      component: RedPaymentRecordEdit
    }
  ]
}
