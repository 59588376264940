import AdminRedConvert from './Red.vue'
import AdminGreenConvert from './Green.vue'

export default {
  routes: [
    {
      path: '/admin/convert/red',
      name: 'AdminRedConvert',
      component: AdminRedConvert
    },
    {
      path: '/admin/convert/green',
      name: 'AdminGreenConvert',
      component: AdminGreenConvert
    }
  ]
}
