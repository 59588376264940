<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-5 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active">
                    <i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                    [管理者用] 郵便番号データ取込
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="register" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-file-upload fa-fw" style="margin-right: 5px;"></i>取込</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">郵便番号データファイル</div>
          <div class="form-edit-line">
            <input class="form-control" type="file" id="postalFile" accept="text/*" ref="postalFile">
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index"><span v-html="message"></span>
        </p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal, Toast } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const messages = ref([])
    const messageToastDiv = ref(null)
    const errors = ref([])
    const errorToastDiv = ref(null)
    let processModal = null
    let messageToast = null
    let errorToast = null
    // Form
    const postalFile = ref(null)
    const register = async () => {
      messages.value = []
      errors.value = []
      const file = postalFile.value.files[0]
      if (file) {
        const startDate = new Date()
        processMessage.value = '郵便番号データファイルのアップロードと取り込み処理中です。しばらくお待ちください。'
        processModal.show()
        const formData = new FormData()
        formData.append('file', file, file.name)
        await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/postal', formData, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        const endDate = new Date()
        const diff = endDate.getTime() - startDate.getTime()
        postalFile.value.value = null
        messages.value.push('郵便番号データの取り込みが完了しました<br>（取込時間 : ' + Math.round(diff / 1000) + '秒）')
        messageToast.show()
        processModal.hide()
      } else {
        errors.value.push('ファイルを選択してください')
        errorToast.show()
      }
    }
    // メソッド
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
    })
    return {
      messages,
      errors,
      processMessage,
      processModalDiv,
      messageToastDiv,
      errorToastDiv,
      postalFile,
      register
    }
  }
})
</script>
