import CommonArtistDisp from './Disp'
import CommonArtistEdit from './Edit'
import CommonArtistList from './List'

export default {
  routes: [
    {
      path: '/common/artist/list',
      name: 'CommonArtistList',
      component: CommonArtistList
    },
    {
      path: '/common/artist/disp/:id',
      name: 'CommonArtistDisp',
      component: CommonArtistDisp
    },
    {
      path: '/common/artist/edit/:id',
      name: 'CommonArtistEdit',
      component: CommonArtistEdit
    },
    {
      path: '/common/artist/add',
      name: 'CommonArtistAdd',
      component: CommonArtistEdit
    },
    {
      path: '/red/album/artist/disp/:id/:albumId',
      name: 'CommonArtistRedAlbum',
      component: CommonArtistDisp
    },
    {
      path: '/red/master/artist/disp/:id/:masterId',
      name: 'CommonArtistRedMaster',
      component: CommonArtistDisp
    },
    {
      path: '/red/result/artist/disp/:id/:resultId',
      name: 'CommonArtistRedResult',
      component: CommonArtistDisp
    }
  ]
}
