<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="../../../../assets/logo_nav.png" height="24"></a>
      </div>
    </nav>
    <div v-if="!validAccess" class="container-fluid text-start">
      <div class="alert alert-danger text-start">
        <p style="font-size: 120%;">URLが無効です。誤ったURLを入力していないかをご確認ください。</p>
        <p style="margin-bottom: 0;">※このエラーは既に登録処理が完了しているURLに再度アクセスした場合にも表示されます。</p>
        <p style="margin-bottom: 0;">※QRコードからURLを読み込んだ、もしくは正しいURLを入力し、まだアカウント登録を実施していないにも関わらずこのエラーが表示される場合には、本画面下部に記載の連絡先よりお問い合わせください。</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="emailAuthorized && !rejected && !completed">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">メールアドレスの確認が完了しました。</p>
        <p style="margin-bottom: 3px;">事業者登録番号に関してご回答をいただけますようお願い申し上げます。</p>
        <p style="margin-bottom: 0;">※回答中に前の画面に戻りたい場合には、ブラウザの戻る機能を使わずに画面下部に表示されている「戻る」ボタンをしようしてください。</p>
      </div>
      <div class="container">
        <div v-if="!taxType" class="text-start fs-4">
          課税事業者か免税事業者かを選択してください。
        </div>
        <div v-if="!taxType" class="text-start" style="margin-bottom: 10px;">
          前年度の売上が1,000万円を超える場合は課税事業者、1,000万円以下の場合は免税事業者となります。1,000万円以下でも、既に課税事業者への変更手続きを済ませている場合には「課税事業者」を選択してください。
        </div>
        <div v-if="!taxType" class="text-start" style="margin-bottom: 30px;">
          課税事業者・免税事業者については<a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shohi/6501.htm" target="_blank">こちら（国税庁ホームページ）<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>をご参照ください。
        </div>
        <div v-if="!taxType" class="row">
          <div class="col-6 d-grid gap-2">
            <button @click="taxType = 'taxation'" class="btn btn-outline-primary btn-lg">課税事業者</button>
          </div>
          <div class="col-6 d-grid gap-2">
            <button @click="taxType = 'exemption'" class="btn btn-outline-primary btn-lg">免税事業者</button>
          </div>
        </div>
        <div v-if="taxType === 'taxation' && completedRegisterNumber === null">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            事業者登録番号の取得が完了していますか？
          </div>
          <div class="row">
            <div class="col-6 d-grid gap-2">
              <button @click="completedRegisterNumber = true" class="btn btn-outline-primary btn-lg">はい。完了しています。</button>
            </div>
            <div class="col-6 d-grid gap-2">
              <button @click="completedRegisterNumber = false" class="btn btn-outline-primary btn-lg">いいえ。完了していません。</button>
            </div>
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="taxType = null" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
        <div v-if="taxType === 'taxation' && completedRegisterNumber">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            事業者登録番号を入力してください
          </div>
          <div class="input-group input-group-lg">
            <span class="input-group-text">T</span>
            <input v-model="invoiceNo" type="text" class="form-control form-control-lg" maxlength="13" style="max-width: 250px;" :disabled="invoiceNoChecked">
          </div>
          <div v-if="announcement" class="alert alert-info text-start fs-5" style="margin-top: 15px;">
            <div v-if="announcement" class="form-label align-bottom">登録名</div>
            <div v-if="announcement" class="form-edit-line">
              {{announcement.name}}
            </div>
            <div v-if="announcement" class="form-label align-bottom">住所</div>
            <div v-if="announcement" class="form-edit-line">
              {{announcement.address}}
            </div>
          </div>
          <div v-if="!invoiceNoChecked" class="text-start" style="margin-top: 20px;">
            <button @click="check" class="btn btn-outline-primary btn-lg">番号をチェックする</button>
          </div>
          <div v-if="invoiceNoChecked" class="text-start" style="margin-top: 20px;">
            <button @click="register" class="btn btn-outline-primary btn-lg">表示されている名前、住所に間違いがないことを確認して登録する</button>
            <button @click="invoiceNoChecked = false; invoiceNo = ''; announcement = null" class="btn btn-secondary btn-lg" style="margin-left: 5px">やり直す</button>
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="completedRegisterNumber = null" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
        <div v-if="taxType === 'taxation' && completedRegisterNumber === false">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            事業者登録番号の取得が完了してから再度本サイトへアクセスして登録をお願いいたします。
          </div>
          <div class="text-start" style="margin-bottom: 30px;">
            適格請求書発行事業者登録申請については<a href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice_shinei.htm" target="_blank">こちら（国税庁ホームページ）<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>をご参照ください。
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="completedRegisterNumber = null" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
        <div v-if="taxType === 'exemption' && hasSchedule === null">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            課税事業者への変更をする予定はありますか？
          </div>
          <div class="text-start" style="margin-bottom: 30px;">
            免税事業者であることによる影響については<a href="https://www.jftc.go.jp/dk/guideline/unyoukijun/invoice_qanda.html" target="_blank">こちら（公正取引委員会ホームページ）<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>をご参照ください。
          </div>
          <div class="row">
            <div class="col-6 d-grid gap-2">
              <button @click="hasSchedule = true" class="btn btn-outline-primary btn-lg">はい。課税事業者に変更します。</button>
            </div>
            <div class="col-6 d-grid gap-2">
              <button @click="hasSchedule = false" class="btn btn-outline-primary btn-lg">いいえ。免税事業者のままの予定です。</button>
            </div>
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="taxType = null" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
        <div v-if="taxType === 'exemption' && hasSchedule === false">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            「免税事業者のまま」という選択をしています。
          </div>
          <div class="row">
            <div class="text-start">
              <button @click="register" class="btn btn-outline-primary btn-lg">回答する</button>
            </div>
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="hasSchedule = null;" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
        <div v-if="taxType === 'exemption' && hasSchedule === true">
          <div class="text-start fs-4" style="margin-bottom: 20px;">
            事業者登録番号を取得した後、改めて本サイトにアクセスして事業者登録番号を登録してください。
          </div>
          <div class="text-start" style="margin-bottom: 30px;">
            適格請求書発行事業者登録申請については<a href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice_shinei.htm" target="_blank">こちら（国税庁ホームページ）<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>をご参照ください。
          </div>
          <div class="text-start" style="margin-top: 20px;">
            <button @click="taxType = null; hasSchedule = null;" class="btn btn-success btn-lg"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="completed" class="container-fluid text-start">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">登録が完了しました</p>
        <p style="margin-bottom: 0;">登録にご協力いただきありがとうございます。情報の修正が必要な場合にはシステムにログインして行ってください。</p>
        <p style="margin-bottom: 0;">※ログイン方法：<a href="https://www.rights.services/login" target="_blank">https://www.rights.services/login</a></p>
        <p style="margin-bottom: 0;">※この画面はこのまま閉じてください。（ブラウザの「戻る」や「更新」を行うとエラーが発生します）</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="unnecessary" class="container-fluid text-start">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">既に回答いただいているため、回答は不要です。回答の変更をおこなう場合にはシステムにログインして行ってください。</p>
        <p style="margin-bottom: 0;">登録にご協力いただきありがとうございます。情報の修正が必要な場合にはシステムにログインして行ってください。</p>
        <p style="margin-bottom: 0;">※ログイン方法：<a href="https://www.rights.services/login" target="_blank">https://www.rights.services/login</a></p>
        <p style="margin-bottom: 0;">※この画面はこのまま閉じてください。（ブラウザの「戻る」や「更新」を行うとエラーが発生します）</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="rejected" class="container-fluid text-start">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">収集拒否の処理が完了しました</p>
        <p style="margin-bottom: 0;">※メールアドレスの収集を拒否したことは自動的に依頼元に通知されています。</p>
        <p style="margin-bottom: 0;">※この画面はこのまま閉じてください。（ブラウザの「戻る」や「更新」を行うとエラーが発生します）</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="validAccess && !emailAuthorized && !completed && !rejected && !unnecessary" class="container-fluid text-start">
      <div class="alert alert-info text-start">
        <p class="text-center fs-4">インボイス制度に関するアンケート回答ページ</p>
        <p>ご回答いただくにあたり、ご本人様確認のために使用するメールアドレスを入力してください。既に他社からの登録依頼で登録をおこなっている場合でも、お手数ですが再度登録をお願いいたします。なお、メールアドレスが既に登録済みの場合には、自動的にアカウントが統合され、以前ご回答いただいた内容が自動的に問い合わせ会社に共有されます。</p>
        <p>依頼主、権利者名に心当たりがない場合には、お手数をおかけしますが、画面下部にある「心当たりのない依頼のため登録を拒否する」ボタンを押してくださいますようお願い申し上げます。</p>
        <p style="margin-top: 20px;">この処理により登録された個人情報は、当システムの<a href="https://www.rights.services/help/policy" target="_blank" style="margin-left: 5px; margin-right: 5px;">プライバシーポリシー<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>に則りお取り扱い致します。</p>
        <p style="margin-bottom: 0;">適格請求書等保存方式（インボイス制度）については<a href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice_about.htm" target="_blank">こちら（国税庁ホームページ）<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>をご参照ください。</p>
      </div>
      <div class="form-label align-bottom">登録依頼主</div>
      <div class="form-edit-line">
        {{companyName}}
      </div>
      <div class="form-label align-bottom">権利者名</div>
      <div class="form-edit-line">
        {{name}}
      </div>
      <div class="form-label align-bottom">登録実行者</div>
      <div class="form-edit-line">
        <div class="form-check form-check-inline">
          <input v-model="agent" class="form-check-input" type="radio" name="agentRadio" id="agentRadio1" value="false">
          <label class="form-check-label" for="agentRadio1">本人</label>
        </div>
        <div class="form-check form-check-inline">
          <input v-model="agent" class="form-check-input" type="radio" name="agentRadio" id="agentRadio2" value="true">
          <label class="form-check-label" for="agentRadio2">代理人（本人の代理で登録を行う場合にはこちらを選択）</label>
        </div>
      </div>
      <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>
        メールアドレス
        <span v-show="!!agent && agent !== 'false'">（代理人のメールアドレスを入力）</span>
      </div>
      <div class="form-edit-line">
        <input v-if="!isAuthCodeSent" v-model="email" type="text" class="form-control">
        <input v-if="isAuthCodeSent" v-model="email" type="text" class="form-control" readonly>
      </div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="alert alert-danger">未登録のメールアドレスのため、お名前を入力の上登録をおこなってください。</div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="form-edit-line">
        <input v-model="newName" type="text" class="form-control">
      </div>
      <div v-if="isAuthCodeSent" class="alert alert-danger">入力したメールアドレス宛に届いた認証コードを↓の認証コード欄に入力し、「<i class="fas fa-cloud-upload"></i> 認証して回答を開始する」ボタンを押すと登録が完了します。</div>
      <div v-if="isAuthCodeSent" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>認証コード</div>
      <div v-if="isAuthCodeSent" class="form-edit-line">
        <input v-model="authCode" type="text" class="form-control">
      </div>
      <div class="d-flex justify-content-end" style="margin-top: 20px;">
        <button @click="sendAuthCode" v-if="!isAuthCodeSent" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>登録のため認証コードを送信する</button>
        <button @click="save" v-if="isAuthCodeSent" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>認証して回答を開始する</button>
      </div>
      <div class="d-flex justify-content-end" style="margin-top: 20px;">
        <button @click="openRejectModal" type="button" class="btn btn-danger"><i class="fas fa-ban" style="margin-right: 5px;"></i>心当たりのない依頼のため登録を拒否する</button>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rejectModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">確認メッセージ</h5>
          </div>
          <div class="modal-body text-start">
            登録の拒否をしようとしています。本当によろしいですか？
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="reject" type="button" class="btn btn-danger"><i class="fas fa-ban" style="margin-right: 5px;"></i>拒否する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Modal, Toast } from 'bootstrap'
import { useRoute } from 'vue-router'
import axios from 'axios'
import exception from '@/functions/exception'

export default defineComponent({
  setup () {
    const validAccess = ref(true)
    const emailAuthorized = ref(false)
    const completed = ref(false)
    const rejected = ref(false)
    const route = useRoute()
    const { identifyEx } = exception()
    const token = ref('')
    const system = ref(null)
    const rightholderId = ref('')
    const messages = ref([])
    const errors = ref([])
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const rejectModalDiv = ref(null)
    let messageToast = null
    let errorToast = null
    let processModal = null
    let rejectModal = null
    const isAuthCodeSent = ref(false)
    // Form
    const externalRightsHolderId = ref(null)
    const companyName = ref('')
    const name = ref('')
    const agent = ref(false)
    const email = ref('')
    const newName = ref('')
    const authCode = ref('')
    const isExistsAccount = ref(false)
    const accountId = ref(null)
    const taxType = ref(null)
    const completedRegisterNumber = ref(null)
    const hasSchedule = ref(null)
    const invoiceNo = ref('')
    const invoiceName = ref('')
    const unnecessary = ref(false)
    const announcement = ref(null)
    const invoiceNoChecked = ref(false)
    const sendAuthCode = async () => {
      messages.value = []
      errors.value = []
      if (!email.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(email.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '認証コードの送信中です。しばらくお待ちください。'
        processModal.show()
        await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/code/email/' + email.value).catch(error => { identifyEx(error) })
        isExistsAccount.value = await existsAccount(email.value)
        isAuthCodeSent.value = true
        processModal.hide()
        messages.value.push('認証コードが送信されました')
        messageToast.show()
      }
    }
    const save = async () => {
      messages.value = []
      errors.value = []
      if (!authCode.value) {
        errors.value.push('認証コードを入力してください')
      }
      if (!isExistsAccount.value && !newName.value) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = 'メールアドレスの登録処理中です。しばらくお待ちください。'
        processModal.show()
        try {
          await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + externalRightsHolderId.value + '/authorize', {
            email: email.value,
            authCode: authCode.value,
            name: newName.value,
            agentAccount: agent.value,
            rightsHolderName: name.value
          }).then(response => {
            accountId.value = response.data
          })
          await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + accountId.value + '/invoice/count').then(response => {
            if (response.data === 0) {
              emailAuthorized.value = true
            } else {
              unnecessary.value = true
            }
            processModal.hide()
          })
        } catch (error) {
          errors.value = ['認証コードが誤っています']
          errorToast.show()
          processModal.hide()
        }
      }
    }
    const openRejectModal = () => {
      rejectModal.show()
    }
    const reject = async () => {
      rejectModal.hide()
      processMessage.value = 'メールアドレス収集の拒否処理中です。しばらくお待ちください。'
      processModal.show()
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + externalRightsHolderId.value + '/reject/' + token.value, {})
      rejected.value = true
      processModal.hide()
    }
    async function existsAccount (email) {
      const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + email + '/exists', {}).catch(error => { identifyEx(error) })
      return response.data
    }
    const register = async () => {
      errors.value = []
      if (taxType.value === 'taxation') {
        if (!invoiceNo.value) {
          errors.value.push('事業者登録番号を入力してください')
        } else {
          if (isNaN(invoiceNo.value)) {
            errors.value.push('事業者登録番号は半角数字で入力してください')
          }
          if (invoiceNo.value.length !== 13) {
            errors.value.push('事業者登録番号は13桁で入力してください')
          }
        }
        if (!invoiceName.value) {
          errors.value.push('事業者名を入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = 'アンケート結果を保存中です。しばらくお待ちください。'
        processModal.show()
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + externalRightsHolderId.value + '/save/' + token.value, {
          taxationType: taxType.value,
          toTaxationSchedule: hasSchedule.value,
          invoiceNo: invoiceNo.value,
          invoiceName: invoiceName.value
        })
        completed.value = true
        processModal.hide()
      }
    }
    const check = async () => {
      if (taxType.value === 'taxation') {
        if (!invoiceNo.value) {
          errors.value.push('事業者登録番号を入力してください')
        } else {
          if (isNaN(invoiceNo.value)) {
            errors.value.push('事業者登録番号は半角数字で入力してください')
          }
          if (invoiceNo.value.length !== 13) {
            errors.value.push('事業者登録番号は13桁で入力してください')
          }
        }
        if (!invoiceName.value) {
          errors.value.push('事業者名を入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '事業者登録番号のチェックをおこなっています。しばらくお待ちください。'
        processModal.show()
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/T' + invoiceNo.value, {}).then(response => {
          console.log(response.data)
          if (response.data && response.data.count > 0) {
            announcement.value = response.data.announcement[0]
            invoiceNoChecked.value = true
          } else {
            errors.value = ['国税庁のデータベースに登録されていない番号です。正しく入力されているか確認してください。']
            errorToast.show()
          }
        })
        processModal.hide()
      }
    }
    onMounted(() => {
      system.value = route.params.system
      rightholderId.value = route.params.id
      token.value = route.params.token
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rejectModal = new Modal(rejectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + system.value + '/' + rightholderId.value + '/' + token.value, {}).then(response => {
        const rh = response.data
        externalRightsHolderId.value = rh.id
        name.value = rh.name
        companyName.value = rh.contractName
        validAccess.value = true
        invoiceName.value = rh.name
      }).catch(() => {
        validAccess.value = false
      })
    })
    return {
      validAccess,
      completed,
      rejected,
      system,
      rightholderId,
      token,
      messages,
      errors,
      processMessage,
      messageToastDiv,
      errorToastDiv,
      processModalDiv,
      rejectModalDiv,
      isAuthCodeSent,
      externalRightsHolderId,
      companyName,
      name,
      email,
      newName,
      authCode,
      isExistsAccount,
      emailAuthorized,
      accountId,
      taxType,
      completedRegisterNumber,
      hasSchedule,
      agent,
      invoiceNo,
      invoiceName,
      sendAuthCode,
      save,
      reject,
      openRejectModal,
      register,
      unnecessary,
      announcement,
      invoiceNoChecked,
      check
    }
  }
})
</script>
