<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center" style="margin-top: 15px;">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/currency/list"><i class="fas fa-sitemap fa-fw" style="margin-right: 5px;"></i>
                      通貨一覧
                    </router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input v-model="store.year" @change="find" type="number" class="form-control" style="flex: 0 1 90px;">
            <span class="input-group-text">年</span>
            <input v-model="store.month" @change="find" type="number" class="form-control" style="flex: 0 1 70px;" min="1" max="12">
            <span class="input-group-text">月</span>
            <button @click="find" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </div>
      <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 60px; overflow: auto;">
        <div class="container" style="max-width: 800px;">
          <div class="text-end">
            <button @click="openDataModal" class="btn btn-outline-primary">Apple為替レート表読み込み</button>
          </div>
          <table class="table table-responsive">
            <thead>
              <tr>
                <th>通貨名</th>
                <th style="width: 100px;">略称</th>
                <th style="width: 300px;">レート</th>
                <th style="width: 60px;">規定</th>
                <th style="width: 60px;">削除</th>
              </tr>
            </thead>
            <tbody v-if="currencyList">
              <tr v-for="(currency, index) in currencyList" :key="index" class="text-start" :class="{ 'deleted-row': currency.deleted }">
                <td>
                  <input v-model="currency.name" type="text" class="form-control">
                </td>
                <td>
                  <input v-model="currency.mark" type="text" class="form-control">
                </td>
                <td>
                  <div class="input-group">
                    <span class="input-group-text" style="flex: 0 1 100px;">1{{currency.mark}} = </span>
                    <input v-model="currency.rate" type="number" class="form-control text-end">
                    <span class="input-group-text">円</span>
                  </div>
                </td>
                <td class="text-center" style="vertical-align: middle;">
                  <button v-if="currency.defaultCurrency" @click="changeDefaultCurrency(index)" class="btn btn-sm btn-primary"><i class="fas fa-circle"></i></button>
                  <button v-if="!currency.defaultCurrency" @click="changeDefaultCurrency(index)" class="btn btn-sm btn-secondary"><i class="fas fa-minus"></i></button>
                </td>
                <td class="text-center" style="vertical-align: middle;">
                  <span v-if="!currency.deleted"><button @click="currency.deleted = true" class="btn btn-sm btn-danger"><i class="fas fa-trash-alt"></i></button></span>
                  <span v-else><button @click="currency.deleted = false" class="btn btn-sm btn-secondary"><i class="fas fa-undo"></i></button></span>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-end">
                  <button @click="addItem" class="btn btn-outline-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="dataModalDiv">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Apple為替レート表読み込み</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">レート表ファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="appleFile" accept="text/csv" ref="appleFile">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="readFile" type="button" class="btn btn-primary"><i class="fas fa-file-import" style="margin-right: 5px;"></i>読み込む</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Modal, Toast } from 'bootstrap'
import { currencyStore } from './store'
import { currencyFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    // メソッド
    // 検索
    const store = currencyStore()
    const currencyFunc = currencyFunction()
    const currencyList = ref([])
    const dataModalDiv = ref(null)
    let dataModal = null
    const appleFile = ref(null)
    // メソッド
    const find = async () => {
      currencyList.value = await currencyFunc.find(store)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const addItem = () => {
      currencyList.value.push({
        name: '',
        mark: '',
        rate: 1,
        defaultCurrency: (currencyList.value.length === 0)
      })
    }
    const save = async () => {
      errors.value = []
      if (!(currencyList.value.some(x => x.defaultCurrency === true))) {
        errors.value.push('デフォルト通貨を指定してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        await currencyFunc.save(store, currencyList.value)
        await router.push('/common/currency/list')
      }
    }
    const changeDefaultCurrency = (index) => {
      for (let i = 0; i < currencyList.value.length; i++) {
        const currency = currencyList.value[i]
        if (i === index) {
          currency.defaultCurrency = true
        } else {
          currency.defaultCurrency = false
        }
      }
    }
    const openDataModal = () => {
      dataModal.show()
    }
    const readFile = () => {
      const file = appleFile.value.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = async () => {
          const dataLines = reader.result.split(/\r\n|\n/)
          for (let i = 1; i < dataLines.length; i++) {
            const dataList = dataLines[i].split(',')
            if (dataList.length >= 8) {
              for (const currency of currencyList.value) {
                if (dataList[0].includes(currency.mark)) {
                  currency.rate = parseFloat(dataList[8].replaceAll('"', ''))
                }
              }
            }
          }
        }
        reader.readAsText(file)
        dataModal.hide()
      }
    }

    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      dataModal = new Modal(dataModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      if (!store.year) {
        store.year = new Date().getFullYear()
      }
      if (!store.month) {
        store.month = new Date().getMonth() + 1
      }
      session.session = await auth.getSession()
      await find()
    })
    return {
      store,
      errors,
      errorToastDiv,
      currencyList,
      find,
      addItem,
      save,
      changeDefaultCurrency,
      dataModalDiv,
      appleFile,
      openDataModal,
      readFile
    }
  }
})
</script>
