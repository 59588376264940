<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/payment/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>
                      支払明細一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link v-if="form" :to="'/red/payment/disp/' + form.id"><i class="fas fa-eye fa-fw" style="margin-right: 5px;"></i>
                      閲覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>ヘッダ修正</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 10px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="alert alert-warning">権利者別支払金額を直接修正するための画面です。修正すると明細合計と金額が一致しなくなる場合があります。また、修正後に明細作成を実行してもこの明細が上書きされないようにするためには「保護」のチェックをONにして保存してください。</div>
          <div class="form-label align-bottom">権利者名</div>
          <div class="form-edit-line">
            {{ form.rightsHolderName }}
          </div>
          <div class="form-label align-bottom">消費税</div>
          <div class="form-edit-line">
            <span v-if="form.taxation">課税</span>
            <span v-else>非課税</span>
          </div>
          <div class="form-label align-bottom">源泉</div>
          <div class="form-edit-line">
            <span v-if="form.withholding_taxation === 'personal'">個人（10.21% 〜 20.42%）</span>
            <span v-else-if="form.withholding_taxation === 'fix'">定率（{{form.withholding_rate}}%）</span>
            <span v-else>非課税</span>
          </div>
          <div class="form-label align-bottom">課税事業者／免税事業者</div>
          <div class="form-edit-line">
            <span v-if="form.taxation_type === 'taxation'">課税事業者（T{{form.invoice_no}}）</span>
            <span v-else>免税事業者</span>
          </div>
          <div class="form-label align-bottom">消費税相当額の控除</div>
          <div class="form-edit-line">
            <span v-if="invoiceExemptionTaxPayable === 'pay'">控除せずに支払う</span>
            <span v-else>
              控除する
              （
              少額特例 : <span v-if="invoiceSmallException === 'adapt'">対応</span><span v-else>対応しない</span>
              /
              経過措置 : <span v-if="invoiceTransitionalMeasures === 'adapt'">対応</span><span v-else>対応しない</span>
              /
              控除額 : <span v-if="company.invoice_deduction_target === 'tax'">控除前消費税額相当</span><span v-else>控除前課税対象額から算出</span>
              ）
            </span>
          </div>
          <div class="form-label align-bottom">添付ファイル</div>
          <div class="form-edit-line">
            <div class="input-group">
              <input class="form-control" type="file" id="uploadFile" ref="uploadFile" multiple="multiple">
              <button @click="addFile" class="btn btn-outline-primary"><i class="fas fa-file-upload"></i></button>
            </div>
          </div>
          <table v-if="fileList.length > 0" class="table table-responsive">
            <tbody>
            <tr v-for="(file, index) in fileList" :key="file.blobName">
              <td>{{file.fileName}}</td>
              <td style="width: 60px;" class="text-end">
                <button @click="fileList.splice(index, 1)" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="form-label align-bottom">保護</div>
          <div class="form-edit-line">
            <div class="form-check form-switch">
              <input v-model="form.protect" class="form-check-input" type="checkbox" role="switch" id="protect">
              <label class="form-check-label" for="protect">明細再作成を実行してもこの明細は再作成対象外とする</label>
            </div>
          </div>
          <table class="table table-responsive table-bordered" style="margin-top: 20px;">
            <thead>
              <tr class="text-center">
                <th>税率</th>
                <th v-for="tax in taxList" :key="tax.rate" style="width: 14%;">{{tax.rate}}%</th>
                <th style="width: 14%">計</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <th>前期繰越</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.amountBroughtForward" @blur="changePayment('amountBroughtForward', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.amountBroughtForward.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>今期発生</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.amountInThisTerm" @blur="changePayment('amountInThisTerm', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.amountInThisTerm.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>合計発生</th>
              <td v-for="tax in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.totalAmount" type="text" class="form-control" style="text-align: end;" disabled="disabled">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.totalAmount.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>管理手数料</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.managementFeeAmount" @blur="changePayment('managementFeeAmount', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.managementFeeAmount.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>相殺（税抜）</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.offsetAmountOutTax" @blur="changePayment('offsetAmountOutTax', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.offsetAmountOutTax.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>控除額</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.invoiceDeductionAmount" @blur="changePayment('invoiceDeductionAmount', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.invoiceDeductionAmount.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>課税対象</th>
              <td v-for="tax in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.beforeTaxAmount" type="text" class="form-control" style="text-align: end;" disabled="disabled">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.beforeTaxAmount.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <th>消費税</th>
              <td v-for="(tax, index) in taxList" :key="tax.rate" class="text-end">
                <input v-model="tax.amount.taxAmount" @blur="changePayment('taxAmount', index)" type="text" class="form-control" style="text-align: end;">
              </td>
              <td class="align-middle text-end">
                ￥{{ form.taxAmount.toLocaleString() }}
              </td>
            </tr>
            </tbody>
          </table>
          <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">源泉徴収額</div>
          <div v-if="form.payOrCarryForward === 'pay' || form.amountCarriedForwardOutTax === 0" class="form-edit-line">
            <input v-model="form.withholdingTaxAmount" @blur="calcPayment" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">アドバンス相殺額（税込）</div>
          <div class="form-edit-line">
            <input v-model="form.offsetAmountInTax" @blur="calcPayment" type="text" class="form-control">
          </div>
          <hr>
          <div v-if="form.payOrCarryForward === 'pay'" class="form-label align-bottom">支払額</div>
          <div v-if="form.payOrCarryForward === 'pay'" class="form-edit-line fs-4">
            ￥{{ form.paymentAmount.toLocaleString() }}
          </div>
          <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax !== 0" class="form-label align-bottom">次期繰越額（税抜）</div>
          <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax !== 0" class="form-edit-line fs-4">
            ￥{{ form.amountCarriedForwardOutTax.toLocaleString() }}
          </div>
          <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax === 0" class="form-label align-bottom">次期繰越額（税込）</div>
          <div v-if="form.payOrCarryForward !== 'pay' && form.amountCarriedForwardOutTax === 0" class="form-edit-line fs-4">
            ￥{{ form.amountCarriedForwardInTax.toLocaleString() }}
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
         ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Modal, Toast } from 'bootstrap'
import footerComponent from '@/components/Footer'
import { paymentFunction } from './function'
import { redCompanyFunction } from '@/functions/common'
import { rightsHolderFunction } from '@/views/red/rightsholder/function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    // Form
    const paymentFunc = paymentFunction()
    const companyFunc = redCompanyFunction()
    const rightsHolderFunc = rightsHolderFunction()
    const form = ref(null)
    const taxList = ref([])
    const company = ref({})
    const fileList = ref([])
    const uploadFile = ref(null)
    const rightsHolder = ref({})
    const invoiceExemptionTaxPayable = ref('pay')
    const invoiceSmallException = ref('adapt')
    const invoiceTransitionalMeasures = ref('adapt')
    const divYear = ref(null)
    const divMonth = ref(null)
    // メソッド
    async function init (id) {
      const paymentPromises = []
      paymentPromises.push(paymentFunc.get(id))
      paymentPromises.push(paymentFunc.findStatementTax(id))
      paymentPromises.push(paymentFunc.findStatementAttachment(id))
      const paymentResponse = await Promise.all(paymentPromises)
      form.value = paymentResponse[0]
      taxList.value = paymentResponse[1]
      fileList.value = paymentResponse[2]
      company.value = await companyFunc.get(session.session.company.id)
      const companyDivTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      divYear.value = companyDivTerm.div_year
      const setting = await companyFunc.getDivTermSetting(session.session.company.id, companyDivTerm.divTerm)
      divMonth.value = setting.payment_month
      rightsHolder.value = await rightsHolderFunc.get(form.value.rightsHolderId)
      if (rightsHolder.value.useRightsHolderSetting) {
        invoiceExemptionTaxPayable.value = rightsHolder.value.invoiceExemptionTaxPayable
        invoiceSmallException.value = rightsHolder.value.invoiceSmallException
        invoiceTransitionalMeasures.value = rightsHolder.value.invoiceTransitionalMeasures
      } else {
        invoiceExemptionTaxPayable.value = company.value.invoiceExemptionTaxPayable
        invoiceSmallException.value = company.value.invoiceSmallException
        invoiceTransitionalMeasures.value = company.value.invoiceTransitionalMeasures
      }
    }
    const summarize = async () => {
      let amountBroughtForward = 0
      let amountInThisTerm = 0
      let totalAmount = 0
      let managementFeeAmount = 0
      let offsetTargetAmountOutTax = 0
      let offsetAmountOutTax = 0
      let invoiceDeductionAmount = 0
      let beforeTaxAmount = 0
      let taxAmount = 0
      for (const tax of taxList.value) {
        amountBroughtForward += parseInt(tax.amount.amountBroughtForward)
        amountInThisTerm += parseInt(tax.amount.amountInThisTerm)
        totalAmount += parseInt(tax.amount.totalAmount)
        managementFeeAmount += parseInt(tax.amount.managementFeeAmount)
        offsetTargetAmountOutTax += parseInt(tax.amount.offsetTargetAmountOutTax)
        offsetAmountOutTax += parseInt(tax.amount.offsetAmountOutTax)
        invoiceDeductionAmount += parseInt(tax.amount.invoiceDeductionAmount)
        beforeTaxAmount += parseInt(tax.amount.beforeTaxAmount)
        taxAmount += parseInt(tax.amount.taxAmount)
      }
      form.value.amountBroughtForward = amountBroughtForward
      form.value.amountInThisTerm = amountInThisTerm
      form.value.totalAmount = totalAmount
      form.value.managementFeeAmount = managementFeeAmount
      form.value.offsetTargetAmountOutTax = offsetTargetAmountOutTax
      form.value.offsetAmountOutTax = offsetAmountOutTax
      form.value.amountCarriedForwardOutTax = offsetTargetAmountOutTax
      form.value.invoiceDeductionAmount = invoiceDeductionAmount
      form.value.beforeTaxAmount = beforeTaxAmount
      form.value.taxAmount = taxAmount
      if (form.value.withholdingTaxation === 'personal') {
        form.value.withholdingTaxAmount = calculateTax(beforeTaxAmount, 0.1021, company.value.withholdingRoundMode)
      } else if (form.value.withholdingTaxation === 'fix') {
        form.value.withholdingTaxAmount = calculateTax(beforeTaxAmount, form.value.withholdingRate / 100, company.value.withholdingRoundMode)
      } else {
        form.value.withholdingTaxAmount = 0
      }
      await calcPayment()
    }
    const changePayment = (target, index) => {
      if (!taxList.value[index].amount[target]) {
        taxList.value[index].amount[target] = 0
      }
      taxList.value[index].amount[target] = toHalfNums(taxList.value[index].amount[target].toString()).replace(/[^0-9]-/g, '')
      if (target === 'totalAmount') {
        taxList.value[index].amount.beforeTaxAmount = parseInt(taxList.value[index].amount.totalAmount) - parseInt(taxList.value[index].amount.managementFeeAmount) - parseInt(taxList.value[index].amount.offsetAmountOutTax) - parseInt(taxList.value[index].amount.invoiceDeductionAmount)
        taxList.value[index].amount.offsetTargetAmountOutTax = parseInt(taxList.value[index].amount.totalAmount) - parseInt(taxList.value[index].amount.managementFeeAmount) - parseInt(taxList.value[index].amount.offsetAmountOutTax) - parseInt(taxList.value[index].amount.invoiceDeductionAmount)
        summarize()
        calculateInvoiceDeductionAmount(index)
        changePayment('beforeTaxAmount', index)
      }
      if (target === 'beforeTaxAmount') {
        if (form.value.taxation) {
          taxList.value[index].amount.taxAmount = calculateTax(parseInt(taxList.value[index].amount.beforeTaxAmount), taxList.value[index].rate / 100, company.value.taxRoundMode)
        } else {
          taxList.value[index].amount.taxAmount = 0
        }
      }
      if (target === 'amountBroughtForward' || target === 'amountInThisTerm') {
        taxList.value[index].amount.totalAmount = parseInt(taxList.value[index].amount.amountBroughtForward) + parseInt(taxList.value[index].amount.amountInThisTerm)
        changePayment('totalAmount', index)
      }
      if (target === 'managementFeeAmount' || target === 'offsetAmountOutTax' || target === 'invoiceDeductionAmount') {
        changePayment('totalAmount', index)
      }
      summarize()
    }
    const calcPayment = async () => {
      const rightsHolder = await getRightsHolder(form.value.rightsHolderId)
      form.value.offsetTargetAmountInTax = form.value.beforeTaxAmount + form.value.taxAmount - form.value.withholdingTaxAmount
      const paymentAmount = form.value.offsetTargetAmountInTax - form.value.offsetAmountInTax
      if (rightsHolder.payOrCarryForward === 'carry_forward' || form.value.carriedForwardLine > form.value.offsetTargetAmountOutTax) {
        form.value.payOrCarryForward = 'carry_forward'
      } else {
        form.value.payOrCarryForward = 'pay'
      }
      if (form.value.payOrCarryForward === 'pay') {
        form.value.paymentAmount = paymentAmount
        form.value.amountCarriedForwardInTax = 0
      } else {
        form.value.paymentAmount = 0
        form.value.amountCarriedForwardInTax = paymentAmount
      }
    }
    const getRightsHolder = async (id) => {
      return await rightsHolderFunc.get(id)
    }
    const save = async () => {
      const saveTaxList = []
      console.log(taxList.value)
      for (const tax of taxList.value) {
        const model = tax.amount
        model.taxRateId = tax.id
        model.amountBroughtForward = parseInt(model.amountBroughtForward.toString())
        model.amountInThisTerm = parseInt(model.amountInThisTerm.toString())
        model.beforeTaxAmount = parseInt(model.beforeTaxAmount.toString())
        model.invoiceDeductionAmount = parseInt(model.invoiceDeductionAmount.toString())
        model.managementFeeAmount = parseInt(model.managementFeeAmount.toString())
        model.offsetAmountOutTax = parseInt(model.offsetAmountOutTax.toString())
        model.offsetTargetAmountOutTax = parseInt(model.offsetTargetAmountOutTax.toString())
        model.totalAmount = parseInt(model.totalAmount.toString())
        saveTaxList.push(model)
      }
      form.value.taxList = saveTaxList
      form.value.fileList = fileList.value
      console.log(form.value.taxList)
      await axios.put(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + form.value.id, form.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      await router.push('/red/payment/disp/' + form.value.id)
    }
    function calculateTax (amount, rate, roundMode) {
      if (roundMode === 'floor') {
        return Math.floor(amount * rate)
      } else if (roundMode === 'ceil') {
        return Math.ceil(amount * rate)
      } else {
        return Math.round(amount * rate)
      }
    }
    const toHalfNums = text => text.replace(/[０-９]/g, m => '０１２３４５６７８９'.indexOf(m))
    const addFile = async () => {
      errors.value = []
      if (uploadFile.value.files.length === 0) {
        errors.value.push('ファイルを選択してください')
        errorToast.show()
      } else {
        processMessage.value = 'ファイルのアップロード中です。しばらくお待ちください。'
        processModal.show()
        for (const file of uploadFile.value.files) {
          const blobName = await getBlobName(file.name)
          const urlUl = await getUrlForUpload(blobName)
          await axios.put(urlUl.toString(), file, {
            headers: { 'Content-Type': 'application/octet-stream' }
          })
          const urlDl = await getUrlForDownload(blobName)
          fileList.value.push({
            blobName: blobName,
            fileName: file.name,
            url: urlDl
          })
        }
        uploadFile.value.value = ''
        processModal.hide()
      }
    }
    // 免税事業者控除額
    const calculateInvoiceDeductionAmount = (index) => {
      const amount = taxList.value[index].amount
      if (!form.value.taxation) {
        return
      }
      if (form.value.payOrCarryForward === 'carry_forward') {
        amount.invoiceDeductionAmount = 0
        amount.beforeTaxAmount = amount.totalAmount - amount.managementFeeAmount
        return
      }
      if (invoiceExemptionTaxPayable.value === 'pay') {
        amount.invoiceDeductionAmount = 0
        amount.beforeTaxAmount = amount.totalAmount - amount.managementFeeAmount
        return
      }
      if (invoiceSmallException.value === 'adapt') {
        if (form.value.paymentAmount < 10000) {
          amount.invoiceDeductionAmount = 0
          amount.beforeTaxAmount = amount.totalAmount - amount.managementFeeAmount
          return
        }
      }
      // 消費税額を控除
      if (company.value.invoice_deduction_target === 'tax') {
        // 本来の消費税額
        let deductionAmount = calculateTax(amount.totalAmount - amount.managementFeeAmount, taxList.value[index].rate / 100, company.value.taxRoundMode)
        if (invoiceTransitionalMeasures.value === 'adapt') {
          const yearMonth = divYear.value * 100 + divMonth.value
          let rate = 1
          if (yearMonth < 202610) {
            rate = 0.2
          } else if (yearMonth < 202910) {
            rate = 0.5
          }
          deductionAmount = calculateTax(deductionAmount, rate, company.value.taxRoundMode)
        }
        amount.invoiceDeductionAmount = deductionAmount
        amount.beforeTaxAmount = amount.totalAmount - amount.managementFeeAmount - deductionAmount
      // 課税対象額から算出
      } else {
        let deductionAmount = amount.beforeTaxAmount - Math.round(amount.beforeTaxAmount / (1 + taxList.value[index].rate / 100))
        if (invoiceTransitionalMeasures.value === 'adapt') {
          const yearMonth = divYear.value * 100 + divMonth.value
          let rate = 1
          if (yearMonth < 202610) {
            rate = 0.2
          } else if (yearMonth < 202910) {
            rate = 0.5
          }
          deductionAmount = calculateTax(deductionAmount, rate, company.value.taxRoundMode)
        }
        console.log(deductionAmount)
        amount.invoiceDeductionAmount = deductionAmount
        amount.beforeTaxAmount = amount.totalAmount - amount.managementFeeAmount - deductionAmount
      }
    }
    async function getBlobName (fileName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/blob', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          fileType: 'artist_photo',
          fileName: fileName
        }
      })
      return data
    }
    async function getUrlForUpload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/ul', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    async function getUrlForDownload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      await init(route.params.id)
      loadingModal.hide()
    })
    return {
      router,
      loadingModalDiv,
      errors,
      errorToastDiv,
      processMessage,
      processModalDiv,
      form,
      rightsHolder,
      invoiceExemptionTaxPayable,
      invoiceSmallException,
      invoiceTransitionalMeasures,
      taxList,
      company,
      fileList,
      uploadFile,
      summarize,
      changePayment,
      calcPayment,
      save,
      addFile
    }
  }
})
</script>
