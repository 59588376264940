<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/paymentrecord/list"><i class="fas fa-newspaper fa-fw" style="margin-right: 5px;"></i>
                      支払調書一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link v-if="form" :to="'/red/paymentrecord/disp/' + route.params.rightsHolderId + '/' + route.params.withholdingTaxation + '/' + route.params.year"><i class="fas fa-eye fa-fw" style="margin-right: 5px;"></i>
                      閲覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-money-check-edit-alt fa-fw" style="margin-right: 5px;"></i>調整</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 10px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div v-if="form.rights_holder_code" class="form-label align-bottom">権利者コード</div>
          <div v-if="form.rights_holder_code" class="form-edit-line">
            {{ form.rights_holder_code }}
          </div>
          <div class="form-label align-bottom">権利者名</div>
          <div class="form-edit-line">
            <router-link :to="'/red/rightsholder/disp/' + form.rights_holder_id">{{ form.rights_holder_name }}</router-link>
          </div>
          <div class="form-label align-bottom">消費税</div>
          <div class="form-edit-line">
            <span v-if="form.taxation">課税</span>
            <span v-else>非課税</span>
          </div>
          <div class="form-label align-bottom">源泉</div>
          <div class="form-edit-line">
            <span v-if="route.params.withholdingTaxation === 'personal'">個人（10.21% 〜 20.42%）</span>
            <span v-else-if="route.params.withholdingTaxation === 'fix'">定率（{{form.withholding_rate}}%）</span>
            <span v-else>非課税</span>
          </div>
          <div v-if="(form.payment_postal_code || form.payment_prefecture || form.payment_ciry || form.payment_town_area || form.payment_building)" class="form-label align-bottom">送付先住所</div>
          <div v-if="(form.payment_postal_code || form.payment_prefecture || form.payment_ciry || form.payment_town_area || form.payment_building)" class="form-edit-line">
            <p v-if="form.payment_postal_code" style="margin: 0;">〒{{ form.payment_postal_code }}</p>
            <p style="margin: 0;">{{ form.payment_prefecture }}{{ form.payment_city }}{{ form.payment_town_area }}</p>
            <p style="margin: 0;">{{ form.payment_building }}</p>
          </div>
          <div v-if="form.payment_tel" class="form-label align-bottom">送付先電話番号</div>
          <div v-if="form.payment_tel" class="form-edit-line">
            {{ form.payment_tel }}
          </div>
          <div v-if="form.payment_greeting" class="form-label align-bottom">挨拶文</div>
          <div v-if="form.payment_greeting" class="form-edit-line">
            <span v-html="form.payment_greeting.replaceAll('\n', '<br>')"></span>
          </div>
          <table class="table table-bordered" style="width: 700px;">
            <thead>
              <tr class="text-center">
                <th style="width: 150px;">期</th>
                <th style="width: 150px;">税抜支払額</th>
                <th style="width: 150px;">源泉徴収額</th>
                <th style="width: 150px;">消費税額</th>
                <th style="width: 150px;">支払額</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in paymentList" :key="payment.id">
                <td class="text-center">{{payment.div_year}}年{{payment.div_term}}期</td>
                <td class="text-end">{{(payment.payment_amount + payment.withholding_tax_amount - payment.tax_amount).toLocaleString()}}</td>
                <td class="text-end">{{payment.withholding_tax_amount.toLocaleString()}}</td>
                <td class="text-end">{{payment.tax_amount.toLocaleString()}}</td>
                <td class="text-end">{{payment.payment_amount.toLocaleString()}}</td>
              </tr>
              <tr>
                <td class="text-center">合計</td>
                <td class="text-end">{{(sumPayment.paymentAmount + sumPayment.withholdingTaxAmount - sumPayment.taxAmount).toLocaleString()}}</td>
                <td class="text-end">{{sumPayment.withholdingTaxAmount.toLocaleString()}}</td>
                <td class="text-end">{{sumPayment.taxAmount.toLocaleString()}}</td>
                <td class="text-end">{{sumPayment.paymentAmount.toLocaleString()}}</td>
              </tr>
            </tbody>
          </table>
          <div class="form-label align-bottom">調整金（税抜支払額）</div>
          <div class="form-edit-line">
            <input v-model="adjust.payment_amount_out_tax" class="form-control" type="number" style="width: 150px;">
          </div>
          <div class="form-label align-bottom">調整金（源泉徴収額）</div>
          <div class="form-edit-line">
            <input v-model="adjust.withholding_tax_amount" class="form-control" type="number" style="width: 150px;">
          </div>
          <div class="form-label align-bottom">調整金（消費税額）</div>
          <div class="form-edit-line">
            <input v-model="adjust.tax_amount" class="form-control" type="number" style="width: 150px;">
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import footerComponent from '@/components/Footer'
import { paymentRecordFunction } from './function'
import axios from 'axios'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // Form
    const paymentRecordFunc = paymentRecordFunction()
    const form = ref(null)
    const adjust = ref({})
    const paymentList = ref([])
    const sumPayment = ref({
      paymentAmount: 0,
      withholdingTaxAmount: 0,
      taxAmount: 0
    })
    // メソッド
    const init = async (rightsHolderId, withholdingTaxation, year) => {
      paymentList.value = await paymentRecordFunc.findByRightsHolder(rightsHolderId, withholdingTaxation, year)
      if (paymentList.value.length > 0) {
        form.value = paymentList.value[0]
      }
      for (const payment of paymentList.value) {
        sumPayment.value.paymentAmount = sumPayment.value.paymentAmount + payment.payment_amount
        sumPayment.value.withholdingTaxAmount = sumPayment.value.withholdingTaxAmount + payment.withholding_tax_amount
        sumPayment.value.taxAmount = sumPayment.value.taxAmount + payment.tax_amount
      }
      await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/record/' + year + '/' + rightsHolderId + '/' + withholdingTaxation, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        if (response.data) {
          adjust.value.payment_amount_out_tax = response.data.payment_amount_out_tax
          adjust.value.withholding_tax_amount = response.data.withholding_tax_amount
          adjust.value.tax_amount = response.data.tax_amount
        }
      })
    }
    const save = async () => {
      if (!adjust.value.payment_amount_out_tax) {
        adjust.value.payment_amount_out_tax = 0
      }
      if (!adjust.value.withholding_tax_amount) {
        adjust.value.withholding_tax_amount = 0
      }
      if (!adjust.value.tax_amount) {
        adjust.value.tax_amount = 0
      }
      await axios.post(process.env.VUE_APP_RED_ENDPOINT + '/payment/record', {
        year: route.params.year,
        rights_holder_id: route.params.rightsHolderId,
        withholding_taxation: route.params.withholdingTaxation,
        payment_amount_out_tax: adjust.value.payment_amount_out_tax,
        withholding_tax_amount: adjust.value.withholding_tax_amount,
        tax_amount: adjust.value.tax_amount
      }, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      await router.push('/red/paymentrecord/disp/' + route.params.rightsHolderId + '/' + route.params.withholdingTaxation + '/' + route.params.year)
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      await init(route.params.rightsHolderId, route.params.withholdingTaxation, route.params.year)
      loadingModal.hide()
    })
    return {
      router,
      route,
      loadingModalDiv,
      form,
      adjust,
      paymentList,
      sumPayment,
      save
    }
  }
})
</script>
