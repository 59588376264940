<template>
  <div v-if="form">
    <div class="form-label align-bottom">名前</div>
    <div class="form-edit-line fs-5" style="font-weight: bold;">
      {{ form.name }}
    </div>
    <div class="form-label align-bottom">略称</div>
    <div class="form-edit-line">
      {{ form.abbreviation }}
    </div>
    <div v-if="form.kana" class="form-label align-bottom">カナ</div>
    <div v-if="form.kana" class="form-edit-line">
      {{ form.kana }}
    </div>
    <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
    <div v-if="form.nameEn" class="form-edit-line">
      {{ form.nameEn }}
    </div>
    <div class="card" style="margin-bottom: 10px;" id="calcDiv">
      <div class="card-header">頒布形態</div>
      <div class="card-body" style="padding: 10px 5px 5px 10px;">
        <table class="table table-responsive table-hover">
          <tbody>
          <tr v-for="(sales, index) in form.salesList" :key="index" class="align-middle">
            <td>
              {{ sales.name }}
              <span v-if="sales.siteUrl" style="font-size: 85%;"><br><a :href="sales.siteUrl" target="_blank">{{sales.siteUrl}}</a></span>
            </td>
            <td class="text-end">
              <span v-if="sales.useTypeName" class="badge bg-info">{{ sales.useTypeName }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="form.memo" class="form-label align-bottom">メモ</div>
    <div v-if="form.memo" class="form-edit-line">
      <span v-html="form.memo"></span>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { userFunction } from '@/views/red/user/function'

export default defineComponent({
  props: ['userId'],
  setup (props) {
    const auth = authFunction()
    const session = sessionStore()
    // Form
    const userFunc = userFunction()
    const form = ref(null)
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      form.value = await userFunc.get(id)
      console.log(form.value)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(props.userId)
    })
    return {
      form
    }
  }
})
</script>
