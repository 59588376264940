import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const contractFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        searchString: store.searchString,
        sortColumn: store.sortColumn,
        sortOrder: store.sortOrder,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findSimilar = async (artistId, projectId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/list/similar', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        artistId: artistId,
        projectId: projectId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findDetail = async (companyId, store) => {
    const params = {
      company_id: companyId,
      sort_column1: store.detailCondition.sort_column1,
      sort_order1: store.detailCondition.sort_order1,
      sort_column2: store.detailCondition.sort_column2,
      sort_order2: store.detailCondition.sort_order2,
      page: store.page,
      per_page: 20
    }
    if (store.detailCondition.search_string) {
      params.search_string = store.detailCondition.search_string
    }
    if (store.detailCondition.contract_status !== 'all') {
      params.contract_status = store.detailCondition.contract_status
    }
    if (store.detailCondition.code) {
      params.code = store.detailCondition.code
    }
    if (store.detailCondition.rights_holder_name) {
      params.rights_holder_name = store.detailCondition.rights_holder_name
    }
    if (store.detailCondition.rights_holder_code) {
      params.rights_holder_code = store.detailCondition.rights_holder_code
    }
    if (store.detailCondition.master_name) {
      params.master_name = store.detailCondition.master_name
    }
    if (store.detailCondition.album_name) {
      params.album_name = store.detailCondition.album_name
    }
    if (store.detailCondition.artist_name) {
      params.artist_name = store.detailCondition.artist_name
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/contract/list/detail', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findForMatch = async (companyId, store) => {
    const params = {
      companyId: companyId,
      searchString: store.searchString,
      sortColumn: 'name',
      sortOrder: 'asc',
      page: store.page,
      perPage: 30
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByRightsHolder = async (rightsHolderId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { searchString: store.searchString }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findAllByRightsHolder = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/' + rightsHolderId + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByAlbum = async (albumId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/album/' + albumId + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByMaster = async (masterId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + masterId + '/contract/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.sortColumn = 'name'
    store.sortOrder = 'asc'
    store.page = 0
  }
  const detailClear = (store) => {
    store.detailCondition = {
      searchString: null,
      contractStatus: 'all',
      code: null,
      rightsHolderName: null,
      rightsHolderCode: null,
      masterName: null,
      albumName: null,
      artistName: null,
      sortColumn1: 'name',
      sortOrder1: 'asc',
      sortColumn2: 'status',
      sortOrder2: 'asc'
    }
    store.page = 0
  }
  const insert = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { companyId: companyId }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  const addAlbum = async (id, albumId) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id + '/album/' + albumId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const addMaster = async (id, masterId) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + id + '/master/' + masterId, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  return { get, find, findDetail, clear, detailClear, findByRightsHolder, findByAlbum, findByMaster, insert, update, remove, indexUpdate, findForMatch, addAlbum, addMaster, findSimilar, findAllByRightsHolder }
}
