import RedSettingDisp from './Disp'
import RedSettingEdit from './Edit'

export default {
  routes: [
    {
      path: '/red/setting/disp',
      name: 'RedSettingDisp',
      component: RedSettingDisp
    },
    {
      path: '/red/setting/edit',
      name: 'RedSettingEdit',
      component: RedSettingEdit
    }
  ]
}
