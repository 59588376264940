import { defineStore } from 'pinia'
import { ref } from 'vue'

export const bankStore = defineStore('bank', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
export const branchStore = defineStore('branch', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
