import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const currencyFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/currency/' + store.year + '/' + store.month, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const findAll = async () => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/currency/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const save = async (store, list) => {
    await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/currency/' + store.year + '/' + store.month, list, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  return { find, findAll, save }
}
