import RedPaymentPdfDefinitionList from './List'
import RedPaymentPdfDefinitionEdit from './Edit'
import RedPaymentPdfDefinitionDisp from './Disp'

export default {
  routes: [
    {
      path: '/red/payment/pdf/definition/list',
      name: 'RedPaymentPdfDefinitionList',
      component: RedPaymentPdfDefinitionList
    },
    {
      path: '/red/payment/pdf/definition/add',
      name: 'RedPaymentPdfDefinitionAdd',
      component: RedPaymentPdfDefinitionEdit
    },
    {
      path: '/red/payment/pdf/definition/edit/:id',
      name: 'RedPaymentPdfDefinitionEdit',
      component: RedPaymentPdfDefinitionEdit
    },
    {
      path: '/red/payment/pdf/definition/disp/:id',
      name: 'RedPaymentPdfDefinitionDisp',
      component: RedPaymentPdfDefinitionDisp
    }
  ]
}
