import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const destinationFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const count = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const findRed = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/' + rightsHolderId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  return { get, count, find, clearSearch, findRed }
}
