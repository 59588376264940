<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/setting/disp"><i class="fas fa-cabinet-filing fa-fw" style="margin-right: 5px;"></i> RED設定閲覧</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">表示用名（画面下部の会社切り替え部でのみ使用されます）</div>
          <div class="form-edit-line">
            <input v-model="commonCompany.nameDp" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">カナ</div>
          <div class="form-edit-line">
            <input v-model="form.kana" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">英語名</div>
          <div class="form-edit-line">
            <input v-model="form.nameEn" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">住所</div>
          <div class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text">〒</span>
              <input v-model="form.postalCode" @keydown.enter="getAddress(form.postalCode, '')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
              <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁で入力）</span>
              <button @click="getAddress(form.postalCode, '')" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
            </div>
          </div>
          <div class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text">都道府県</span>
              <input v-model="form.prefecture" type="text" class="form-control" style="flex: 0 1 200px;">
            </div>
          </div>
          <div class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text">市区町村</span>
              <input v-model="form.city" type="text" class="form-control" style="flex: 0 1 200px;">
            </div>
          </div>
          <div class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
              <input v-model="form.townArea" type="text" class="form-control">
            </div>
          </div>
          <div class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
              <input v-model="form.building" type="text" class="form-control">
            </div>
          </div>
          <div class="form-label align-bottom">電話番号</div>
          <div class="form-edit-line">
            <input v-model="form.tel" type="text" class="form-control" style="width: 200px;">
          </div>
          <div class="form-label align-bottom">メールアドレス</div>
          <div class="form-edit-line">
            <input v-model="form.email" type="text" class="form-control">
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">支払計算書</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div style="margin-bottom: 10px;">
                <button @click="copyStatement" class="btn btn-outline-primary"><i class="fas fa-copy" style="margin-right: 5px;"></i>基本情報からコピー</button>
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line">
                <input v-model="form.statementName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">英語名</div>
              <div class="form-edit-line">
                <input v-model="form.statementNameEn" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">住所</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">〒</span>
                  <input v-model="form.statementPostalCode" @keydown.enter="getAddress(form.statementPostalCode, 'STATEMENT')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
                  <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁で入力）</span>
                  <button @click="getAddress(form.statementPostalCode, 'STATEMENT')" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">都道府県</span>
                  <input v-model="form.statementPrefecture" type="text" class="form-control" style="flex: 0 1 200px;">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">市区町村</span>
                  <input v-model="form.statementCity" type="text" class="form-control" style="flex: 0 1 200px;">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
                  <input v-model="form.statementTownArea" type="text" class="form-control">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
                  <input v-model="form.statementBuilding" type="text" class="form-control">
                </div>
              </div>
              <div class="form-label align-bottom">英語住所</div>
              <div class="form-edit-line">
                <textarea v-model="form.statementAddressEn" class="form-control" rows="4"></textarea>
              </div>
              <div class="form-label align-bottom">電話番号</div>
              <div class="form-edit-line">
                <input v-model="form.statementTel" type="text" class="form-control" style="width: 200px;">
              </div>
              <div class="form-label align-bottom">メールアドレス</div>
              <div class="form-edit-line">
                <input v-model="form.statementEmail" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">計算書自社情報記載</div>
              <div class="form-edit-line">
                <div class="form-check form-check-inline">
                  <input v-model="form.statementTelOrEmail" class="form-check-input" type="radio" name="statementTelOrEmail" id="statementTelOrEmail_Tel" value="tel">
                  <label class="form-check-label" for="statementTelOrEmail_Tel">電話番号</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="form.statementTelOrEmail" class="form-check-input" type="radio" name="statementTelOrEmail" id="statementTelOrEmail_Email" value="email">
                  <label class="form-check-label" for="statementTelOrEmail_Email">メールアドレス</label>
                </div>
              </div>
              <div v-if="session && session.green" class="form-label align-bottom">明細作成対象</div>
              <div v-if="session && session.green" class="form-edit-line">
                <select v-model="form.makeStatementRange" class="form-select">
                  <option value="all">全て</option>
                  <option value="sum_income">前期繰越または今期発生のあるもののみ</option>
                  <option value="income">今期発生のあるもののみ</option>
                  <option value="payment">支払のあるもののみ</option>
                </select>
              </div>
              <div class="form-label align-bottom">挨拶文（初期値）</div>
              <div class="form-edit-line">
                <textarea v-model="form.statementGreeting" class="form-control" rows="5"></textarea>
              </div>
              <div class="form-label align-bottom">鑑形式</div>
              <div class="form-edit-line">
                <select v-model="form.paymentHeaderType" class="form-select">
                  <option value="landscape">横</option>
                  <option value="portrait">縦</option>
                </select>
              </div>
              <div class="form-label align-bottom">振込先情報の表記</div>
              <div class="form-edit-line">
                <div class="form-check form-switch">
                  <input v-model="form.bankInfoOnStatement" class="form-check-input" type="checkbox" id="bankInfoOnStatement">
                  <label class="form-check-label" for="bankInfoOnStatement">計算書に振込先を記載する</label>
                </div>
              </div>
              <div class="form-label align-bottom">単価計算時の受領額記載</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">PDF</span>
                  <select v-model="form.pdfIncomeAmountDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.pdfIncomeAmountDisplay === 'true' || form.pdfIncomeAmountDisplay === true" v-model="form.pdfIncomeAmountZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
                <div class="input-group" style="margin-top: 5px;">
                  <span class="input-group-text" style="flex: 0 1 90px;">データ</span>
                  <select v-model="form.dataIncomeAmountDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.dataIncomeAmountDisplay === 'true' || form.dataIncomeAmountDisplay === true" v-model="form.dataIncomeAmountZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">受領額・売上額計算時の使用数記載</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">PDF</span>
                  <select v-model="form.pdfUsedNoDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.pdfUsedNoDisplay === 'true' || form.pdfUsedNoDisplay === true" v-model="form.pdfUsedNoZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
                <div class="input-group" style="margin-top: 5px;">
                  <span class="input-group-text" style="flex: 0 1 90px;">データ</span>
                  <select v-model="form.dataUsedNoDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.dataUsedNoDisplay === 'true' || form.dataUsedNoDisplay === true" v-model="form.dataUsedNoZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">受領額・売上額計算時の販売価格記載</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">PDF</span>
                  <select v-model="form.pdfPriceDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.pdfPriceDisplay === 'true' || form.pdfPriceDisplay === true" v-model="form.pdfPriceZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
                <div class="input-group" style="margin-top: 5px;">
                  <span class="input-group-text" style="flex: 0 1 90px;">データ</span>
                  <select v-model="form.dataPriceDisplay" class="form-select" style="flex: 0 1 130px;">
                    <option value="true">記載する</option>
                    <option value="false">記載しない</option>
                  </select>
                  <select v-if="form.dataPriceDisplay === 'true' || form.dataPriceDisplay === true" v-model="form.dataPriceZeroDisplay" class="form-select" style="flex: 0 1 200px;">
                    <option value="true">0を0と記載</option>
                    <option value="false">0はブランクとする</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">PDF、テキスト上の「使用年月」表記</div>
              <div class="form-edit-line">
                <select v-model="form.usedMonthDisplayType" class="form-select" style="flex: 0 1 200px;">
                  <option value="use">使用年月</option>
                  <option value="income">入金年月</option>
                </select>
              </div>
              <div class="form-label align-bottom">PDFの明細行並び順</div>
              <div class="form-edit-line">
                <div v-for="(statementSort, index) in form.companyStatementSortList" :key="index" class="input-group">
                  <select v-model="statementSort.sortColumn" class="form-select" style="flex: 0 1 300px;">
                    <option value="data_unit">アルバム／原盤区分</option>
                    <option value="album_name">アルバム名</option>
                    <option value="catalog_no">製品番号</option>
                    <option value="jan_code">JANコード</option>
                    <option value="master_name">原盤名</option>
                    <option value="isrc">ISRC</option>
                    <option value="user_name">利用者名</option>
                    <option value="user_sales_name">頒布形態</option>
                    <option value="project_name">プロジェクト名</option>
                    <option value="used_year">利用年</option>
                    <option value="used_month">利用月</option>
                    <option value="use_type_abbreviation">利用種別</option>
                    <option value="royalty_type_abbreviation">印税種別</option>
                    <option value="payment_amount">支払額</option>
                  </select>
                  <select v-model="statementSort.sortOrder" class="form-select" style="flex: 0 1 100px;">
                    <option value="asc">昇順</option>
                    <option value="desc">降順</option>
                  </select>
                  <button v-if="index !== 0" @click="form.companyStatementSortList.splice(index, 1)" class="btn btn-outline-danger"><i class="fas fa-trash-alt fa-fw"></i></button>
                  <button v-if="index === form.companyStatementSortList.length - 1" @click="form.companyStatementSortList.push({sortColumn: 'data_unit', sortOrder: 'asc'})" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw"></i></button>
                </div>
              </div>
              <div class="form-label align-bottom">インボイス事業者登録番号（自社）</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">T</span>
                  <input v-model="form.invoiceNo" type="text" class="form-control" style="flex: 0 1 160px;" maxlength="13">
                </div>
              </div>
              <div class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
              <div class="form-edit-line">
                <select v-model="form.invoiceExemptionTaxPayable" class="form-select">
                  <option value="pay">課税事業者と同様に全額支払う</option>
                  <option value="deduct">消費税相当額を支払額から控除する</option>
                </select>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">
                控除額の算出方法<br>
                <span style="font-size: 85%;">※税抜10,000円の時、経過措置対応前の控除額を1,000円とする場合は「控除前消費税相当」、10,000円の内税額を算出し、これを経過措置対応前の控除額とする場合は「控除前課税対象額から算出」を選択してください。</span>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <select v-model="form.invoiceDeductionTarget" class="form-select">
                  <option value="tax">控除前消費税相当</option>
                  <option value="payment">控除前課税対象額から算出</option>
                </select>
              </div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">消費税相当額の控除対象</div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <select v-model="form.invoiceDeductUnit" class="form-select">
                  <option value="rights_holder">権利者単位</option>
                  <!--                <option value="contract">契約書単位</option>-->
                </select>
              </div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <select v-model="form.invoiceSmallException" class="form-select">
                  <option value="adapt">対応する</option>
                  <option value="not_adapt">対応しない</option>
                </select>
              </div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
              <div v-show="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <select v-model="form.invoiceTransitionalMeasures" class="form-select">
                  <option value="adapt">対応する</option>
                  <option value="not_adapt">対応しない</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">支払調書</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div style="margin-bottom: 10px;">
                <button @click="copyPayment" class="btn btn-outline-primary"><i class="fas fa-copy" style="margin-right: 5px;"></i>基本情報からコピー</button>
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line">
                <input v-model="form.paymentName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">住所</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">〒</span>
                  <input v-model="form.paymentPostalCode" @keydown.enter="getAddress(form.paymentPostalCode, 'PAYMENT')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
                  <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁で入力）</span>
                  <button @click="getAddress(form.statementPostalCode, 'PAYMENT')" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">都道府県</span>
                  <input v-model="form.paymentPrefecture" type="text" class="form-control" style="flex: 0 1 200px;">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">市区町村</span>
                  <input v-model="form.paymentCity" type="text" class="form-control" style="flex: 0 1 200px;">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
                  <input v-model="form.paymentTownArea" type="text" class="form-control">
                </div>
              </div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
                  <input v-model="form.paymentBuilding" type="text" class="form-control">
                </div>
              </div>
              <div class="form-label align-bottom">電話番号</div>
              <div class="form-edit-line">
                <input v-model="form.paymentTel" type="text" class="form-control" style="width: 200px;">
              </div>
              <div class="form-label align-bottom">挨拶文（初期値）</div>
              <div class="form-edit-line">
                <textarea v-model="form.paymentGreeting" class="form-control" rows="5"></textarea>
              </div>
              <div class="form-label align-bottom">法人番号</div>
              <div class="form-edit-line">
                <input v-model="form.corporateNumber" type="text" class="form-control" style="width: 200px;" maxlength="13">
              </div>
              <div class="form-label align-bottom">整理番号</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">①</span>
                  <input v-model="form.paymentRecordNo1" type="text" class="form-control" style="flex: 0 1 160px;" maxlength="10">
                  <span class="input-group-text">②</span>
                  <input v-model="form.paymentRecordNo2" type="text" class="form-control" style="flex: 0 1 160px;" maxlength="10">
                </div>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">振込元</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">振込元</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <input v-model="bankCode" type="text" class="form-control" style="flex: 0 1 70px;" readonly>
                  <input v-model="bankName" type="text" class="form-control" style="flex: 0 1 140px;" readonly>
                  <input v-model="branchCode" type="text" class="form-control" style="flex: 0 1 60px;" readonly>
                  <input v-model="branchName" type="text" class="form-control" style="flex: 0 1 140px;" readonly>
                  <button v-if="!form.branchId" @click="openBankSearch" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                  <button v-if="form.branchId" @click="clearBank" class="btn btn-outline-primary"><i class="fas fa-undo fa-fw"></i></button>
                </div>
              </div>
              <div class="form-label align-bottom">預金種目</div>
              <div class="form-edit-line">
                <select v-model="form.depositItem" class="form-select" style="width: 100px;">
                  <option value="1">普通</option>
                  <option value="2">当座</option>
                  <option value="4">貯蓄</option>
                  <option value="9">その他</option>
                </select>
              </div>
              <div class="form-label align-bottom">口座番号</div>
              <div class="form-edit-line">
                <input v-model="form.accountNo" type="text" class="form-control" style="width: 100px;" maxlength="7">
              </div>
              <div class="form-label align-bottom">口座名義</div>
              <div class="form-edit-line">
                <input v-model="form.accountName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">口座名義カナ</div>
              <div class="form-edit-line">
                <input v-model="form.accountKana" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">振込委託者コード</div>
              <div class="form-edit-line">
                <input v-model="form.assignorCode" type="text" class="form-control" style="width: 160px;" maxlength="10">
              </div>
              <div class="form-label align-bottom">振込委託者名</div>
              <div class="form-edit-line">
                <input v-model="form.assignorName" type="text" class="form-control" maxlength="10">
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">端数／初期値</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">受領額設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.incomeAmountScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.incomeAmountRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">使用数設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.usedNoScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.usedNoRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">計算対象数設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.calcTargetNoScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.calcTargetNoRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">価格設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.priceScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.priceRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">印税単価設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.unitPriceScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.unitPriceRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">支払額設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">小数点以下桁数</span>
                  <input v-model="form.paymentAmountScale" type="number" class="form-control" style="flex: 0 1 70px;">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.paymentAmountRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">消費税額設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.taxRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">源泉税額設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.withholdingRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">権利者単位支払額設定</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">端数処理</span>
                  <select v-model="form.totalPaymentAmountRoundMode" class="form-select" style="flex: 0 1 120px;">
                    <option value="round">四捨五入</option>
                    <option value="ceil">切り上げ</option>
                    <option value="floor">切り捨て</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">年度末強制支払</div>
              <div v-if="form.divTermSettingList" class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">年度末期</span>
                  <select v-model="form.lastTermOfYear" class="form-select" style="flex: 0 1 80px;">
                    <option v-for="divTermSetting in form.divTermSettingList" :key="divTermSetting.divTerm" :value="divTermSetting.divTerm">
                      {{divTermSetting.divTerm}}期
                    </option>
                  </select>
                  <span class="input-group-text">支払／保留</span>
                  <select v-model="form.carriedForwardAtEndOfYear" class="form-select" style="flex: 0 1 130px;">
                    <option value="false">強制支払</option>
                    <option value="true">保留</option>
                  </select>
                </div>
              </div>
              <div class="form-label align-bottom">少額保留下限額（初期値）</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">￥</span>
                  <input v-model="form.defaultCarriedForwardLine" type="number" class="form-control" style="flex: 0 1 150px;">
                </div>
              </div>
              <div class="form-label align-bottom">0円明細出力（初期値）</div>
              <div class="form-edit-line">
                <select v-model="form.statementDetailZeroOmit" class="form-select" style="width: 140px;">
                  <option value="false">出力する</option>
                  <option value="true">出力しない</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">分配期</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <table v-if="form.divTermSettingList" class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 40px;">期</th>
                  <th>利用月</th>
                  <th style="width: 400px;">支払日</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="divTermSetting in form.divTermSettingList" :key="divTermSetting.divTerm">
                  <td>{{divTermSetting.divTerm}}</td>
                  <td>
                    <span v-if="divTermSetting.startMonthYearType === 'previous'">前年</span>
                    <span v-if="divTermSetting.startMonthYearType === 'current'">当年</span>
                    {{divTermSetting.startMonth}}月
                    〜
                    <span v-if="divTermSetting.endMonthYearType === 'current'">当年</span>
                    <span v-if="divTermSetting.endMonthYearType === 'next'">翌年</span>
                    {{divTermSetting.endMonth}}月
                    <span v-if="form.divTermTargetMonthType === 'use'">利用分</span>
                    <span v-if="form.divTermTargetMonthType === 'income'">入金分</span>
                  </td>
                  <td>
                    <div class="input-group">
                      <select v-model="divTermSetting.paymentMonthYearType" class="form-select" style="flex: 0 1 90px;">
                        <option value="previous">前年</option>
                        <option value="current">当年</option>
                        <option value="next">翌年</option>
                      </select>
                      <select v-model="divTermSetting.paymentMonth" class="form-select" style="flex: 0 1 90px;">
                        <option value="1">1月</option>
                        <option value="2">2月</option>
                        <option value="3">3月</option>
                        <option value="4">4月</option>
                        <option value="5">5月</option>
                        <option value="6">6月</option>
                        <option value="7">7月</option>
                        <option value="8">8月</option>
                        <option value="9">9月</option>
                        <option value="10">10月</option>
                        <option value="11">11月</option>
                        <option value="12">12月</option>
                      </select>
                      <select v-model="divTermSetting.paymentDateType" class="form-select" style="flex: 0 1 90px;">
                        <option value="last">末日</option>
                        <option value="exact">指定</option>
                      </select>
                      <input v-if="divTermSetting.paymentDateType !== 'last'" v-model="divTermSetting.paymentDate" type="number" class="form-control">
                      <span v-if="divTermSetting.paymentDateType !== 'last'" class="input-group-text">日</span>
                    </div>
                    <div class="input-group">
                      <select v-model="divTermSetting.paymentOnHoliday" class="form-select">
                        <option value="before">休日の場合は前日</option>
                        <option value="after">休日の場合は翌日</option>
                      </select>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style="height: 50px;"></div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="bankSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">銀行検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="bankCondition.page = 0; findBank();" v-model="bankCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="bankCondition.searchString" @click="bankClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="bankCondition.page = 0; findBank();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="bankResult && bankResult.allRecords > 0" class="table table-responsive table-hover">
                <thead>
                <tr>
                  <th style="width: 120px;">銀行コード</th>
                  <th>銀行名</th>
                  <th style="width: 120px;">支店コード</th>
                  <th>支店名</th>
                </tr>
                </thead>
                <tbody v-if="bankList">
                <tr v-for="(bank, index) in bankList" :key="bank.branchId" class="text-start">
                  <td>{{ bank.bankCode }}</td>
                  <td>{{ bank.bankName }}</td>
                  <td>{{ bank.branchCode }}</td>
                  <td><a @click="selectBank(index)" href="#">{{ bank.branchName }}</a></td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="bankResult && bankResult.allRecords > 0">{{ bankResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="bankResult && bankResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="bankResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ bankCondition.page + 1 }}/{{ bankResult.allPages }}ページ</span>
                      </li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { redCompanyFunction, postalFunction, commonCompanyFunction, bankFunction } from '@/functions/common'
import { bankStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    // Form
    const companyFunc = redCompanyFunction()
    const commonCompanyFunc = commonCompanyFunction()
    const postalFunc = postalFunction()
    const form = ref(null)
    const commonCompany = ref({})
    const rightAreaMode = ref('STATEMENT')
    const bankCode = ref('')
    const bankName = ref('')
    const branchCode = ref('')
    const branchName = ref('')
    // 銀行検索
    const bankFunc = bankFunction()
    const bankCondition = bankStore()
    const bankSearchModalDiv = ref(null)
    let bankSearchModal = null
    const bankResult = ref(null)
    const bankList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const response = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company', form.value, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: { companyId: session.session.company.id }
        }).catch(error => {
          ex.identifyEx(error)
          errors.value = ['システムエラーが発生したため保存されませんでした']
          errorToast.show()
        })
        form.value.id = response.data
        await commonCompanyFunc.save(commonCompany.value.id, commonCompany.value)
        await router.push('/red/setting/disp')
      }
    }
    const getAddress = async (postalCode, mode) => {
      if (postalCode) {
        const postal = await postalFunc.getAddress(postalCode)
        if (postal) {
          if (mode === 'STATEMENT') {
            form.value.statementPrefecture = postal.prefecture
            form.value.statementCity = postal.city
            form.value.statementTownArea = postal.townArea
          } else if (mode === 'PAYMENT') {
            form.value.paymentPrefecture = postal.prefecture
            form.value.paymentCity = postal.city
            form.value.paymentTownArea = postal.townArea
          } else {
            form.value.prefecture = postal.prefecture
            form.value.city = postal.city
            form.value.townArea = postal.townArea
          }
        }
      }
    }
    const copyStatement = () => {
      form.value.statementName = form.value.name
      form.value.statementNameEn = form.value.nameEn
      form.value.statementPostalCode = form.value.postalCode
      form.value.statementPrefecture = form.value.prefecture
      form.value.statementCity = form.value.city
      form.value.statementTownArea = form.value.townArea
      form.value.statementBuilding = form.value.building
      form.value.statementTel = form.value.tel
      form.value.statementEmail = form.value.email
    }
    const copyPayment = () => {
      form.value.paymentName = form.value.name
      form.value.paymentNameEn = form.value.nameEn
      form.value.paymentPostalCode = form.value.postalCode
      form.value.paymentPrefecture = form.value.prefecture
      form.value.paymentCity = form.value.city
      form.value.paymentTownArea = form.value.townArea
      form.value.paymentBuilding = form.value.building
      form.value.paymentTel = form.value.tel
    }
    const findBank = async () => {
      bankResult.value = await bankFunc.findBranchWithBank(bankCondition)
      bankList.value = bankResult.value.list
    }
    const bankClearSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      findBank()
    }
    const bankFirstPage = () => {
      bankCondition.page = 0
      findBank()
    }
    const bankPrevPage = () => {
      bankCondition.page = bankCondition.page - 1
      findBank()
    }
    const bankNextPage = () => {
      bankCondition.page = bankCondition.page + 1
      findBank()
    }
    const bankLastPage = () => {
      bankCondition.page = bankResult.value.allPages - 1
      findBank()
    }
    const openBankSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      bankResult.value = null
      bankList.value = []
      bankSearchModal.show()
    }
    const selectBank = (index) => {
      const bank = bankList.value[index]
      form.value.branchId = bank.branchId
      bankCode.value = bank.bankCode
      bankName.value = bank.bankName
      branchCode.value = bank.branchCode
      branchName.value = bank.branchName
      bankSearchModal.hide()
    }
    const clearBank = () => {
      form.value.branchId = null
      bankCode.value = ''
      bankName.value = ''
      branchCode.value = ''
      branchName.value = ''
    }
    const init = async () => {
      form.value = await companyFunc.get(session.session.company.id)
      commonCompany.value = await commonCompanyFunc.get(session.session.company.id)
      if (form.value.branchId) {
        axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + form.value.branchId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          const branch = response.data
          branchCode.value = branch.code
          branchName.value = branch.name
          axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + branch.bankId, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            const bank = response.data
            bankCode.value = bank.code
            bankName.value = bank.name
          })
        })
      }
      form.value.divTermSettingList = await companyFunc.findDivTermSetting(session.session.company.id)
      const companyDivTerm = companyFunc.getCurrentDivTerm(session.session.company.id)
      form.value.redDivYear = companyDivTerm.divYear
      form.value.redDivTerm = companyDivTerm.divTerm
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      bankSearchModal = new Modal(bankSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init()
    })
    return {
      router,
      errors,
      errorToastDiv,
      session,
      form,
      bankCode,
      bankName,
      branchCode,
      branchName,
      rightAreaMode,
      bankSearchModalDiv,
      bankResult,
      bankList,
      save,
      getAddress,
      copyStatement,
      copyPayment,
      findBank,
      bankClearSearch,
      bankFirstPage,
      bankPrevPage,
      bankNextPage,
      bankLastPage,
      openBankSearch,
      selectBank,
      clearBank,
      commonCompany,
      bankCondition
    }
  }
})
</script>
