<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-exclamation-triangle fa-fw" style="margin-right: 5px;"></i>契約書未設定一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="download" class="btn btn-outline-primary"><i class="fas fa-file-download fa-fw" style="margin-right: 5px;"></i>ダウンロード</button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="resultResult && resultResult.allRecords > 0" class="table table-responsive table-hover">
            <thead style="font-size: 90%;">
            <tr>
              <th style="width: 50px;"></th>
              <th>利用者</th>
              <th>頒布形態</th>
              <th style="width: 90px;">利用種別</th>
              <th>ア／原</th>
              <th>タイトル</th>
            </tr>
            </thead>
            <tbody v-if="resultList" style="font-size: 85%;">
              <tr v-for="(result, index) in resultList" :key="result.id" class="text-start">
                <td class="text-center">
                  <button @click="openDetectModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-link-horizontal fa-fw"></i></button>
                </td>
                <td>{{ result.userName }}</td>
                <td>{{ result.userSalesName }}</td>
                <td>{{ result.useTypeName }}</td>
                <td>
                  <span v-if="result.dataUnit === 'album'">アルバム</span>
                  <span v-if="result.dataUnit === 'master'">原盤</span>
                </td>
                <td>
                  <span v-if="result.dataUnit === 'album'">{{result.albumName}}</span>
                  <span v-if="result.dataUnit === 'master'">{{result.masterName}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="resultResult && resultResult.allRecords > 0">{{ resultResult.allRecords.toLocaleString() }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="resultResult && resultResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="resultResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ resultResult.allPages }}ページ</span></li>
                <li v-if="store.page !== resultResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== resultResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="detectModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書未設定解消</h5>
          </div>
          <div class="modal-body text-start">
            <div v-if="detectForm.albumName" class="form-label align-bottom">アルバム名</div>
            <div v-if="detectForm.albumName" class="form-edit-line">
              {{ detectForm.albumName }}
            </div>
            <div v-if="detectForm.albumCode" class="form-label align-bottom">アルバムコード</div>
            <div v-if="detectForm.albumCode" class="form-edit-line">
              {{ detectForm.albumCode }}
            </div>
            <div v-if="detectForm.catalogNo" class="form-label align-bottom">品番</div>
            <div v-if="detectForm.catalogNo" class="form-edit-line">
              {{ detectForm.catalogNo }}
            </div>
            <div v-if="detectForm.janCode" class="form-label align-bottom">JANコード</div>
            <div v-if="detectForm.janCode" class="form-edit-line">
              {{ detectForm.janCode }}
            </div>
            <div v-if="detectForm.masterName" class="form-label align-bottom">原盤名</div>
            <div v-if="detectForm.masterName" class="form-edit-line">
              {{ detectForm.masterName }}
            </div>
            <div v-if="detectForm.masterCode" class="form-label align-bottom">原盤コード</div>
            <div v-if="detectForm.masterCode" class="form-edit-line">
              {{ detectForm.masterCode }}
            </div>
            <div v-if="detectForm.isrc" class="form-label align-bottom">ISRC</div>
            <div v-if="detectForm.isrc" class="form-edit-line">
              {{ detectForm.isrc }}
            </div>
            <div v-if="detectForm.userName" class="form-label align-bottom">利用者</div>
            <div v-if="detectForm.userName" class="form-edit-line">
              {{ detectForm.userName }}
            </div>
            <div v-if="detectForm.artistName" class="form-label align-bottom">アーティスト</div>
            <div v-if="detectForm.artistName" class="form-edit-line">
              {{ detectForm.artistName }}
            </div>
            <div v-if="detectForm.userSalesName" class="form-label align-bottom">頒布形態</div>
            <div v-if="detectForm.userSalesName" class="form-edit-line">
              {{ detectForm.userSalesName }}
            </div>
            <div v-if="detectForm.useTypeName" class="form-label align-bottom">利用種別</div>
            <div v-if="detectForm.useTypeName" class="form-edit-line">
              {{ detectForm.useTypeName }}
            </div>
            <hr>
            <ul class="nav nav-tabs" style="margin-bottom: 10px;">
              <li class="nav-item">
                <a @click="matchingMode = 'auto'" class="nav-link" :class="{ 'active': matchingMode === 'auto' }" aria-current="page" href="#">
                  自動候補
                </a>
              </li>
              <li class="nav-item">
                <a @click="matchingMode = 'manual'" class="nav-link" :class="{ 'active': matchingMode === 'manual' }" aria-current="page" href="#">
                  手動検索
                </a>
              </li>
<!--              <li class="nav-item">-->
<!--                <a @click="matchingMode = 'add'" class="nav-link" :class="{ 'active': matchingMode === 'add' }" aria-current="page" href="#">-->
<!--                  新規登録-->
<!--                </a>-->
<!--              </li>-->
            </ul>
            <div v-if="matchingMode === 'auto'">
              <div v-if="similarList.length === 0" style="margin-top: 10px;">類似契約書が見つかりませんでした。念の為手動検索をお試しの上、契約書未登録の場合には契約書画面より新規登録してください。</div>
              <table v-if="similarList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                    <th>契約書名</th>
                    <th class="d-none d-lg-table-cell">権利者</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="similar in similarList" :key="similar.id" class="text-start" @click="similar.selected = !similar.selected" :class="{ 'selected-row': similar.selected }">
                    <td style="width: 55px; vertical-align: middle;" class="text-end">
                      <span v-if="!similar.selected"><button class="btn btn-light"><i class="far fa-square"></i></button></span>
                      <span v-if="similar.selected"><button class="btn btn-light"><i class="far fa-check-square"></i></button></span>
                    </td>
                    <td class="d-none d-lg-table-cell">{{ similar.code }}</td>
                    <td>
                      <button @click="openContractModal(similar.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus"></i></button>
                      {{ similar.name }}
                    </td>
                    <td class="d-none d-lg-table-cell">{{ similar.rightsHolderName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="matchingMode === 'manual'">
              <div class="input-group" style="margin-top: 15px;">
                <input @keydown.enter="contractList = []; contractCondition.page = 0; findContract();" v-model="contractCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button @click="contractList = []; contractCondition.searchString = ''; contractCondition.page = 0;" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="contractList = []; contractCondition.page = 0; findContract();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="contractList.length > 0" class="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 50px;"></th>
                    <th class="d-none d-lg-table-cell" style="width: 150px;">コード</th>
                    <th>契約書名</th>
                    <th class="d-none d-lg-table-cell">権利者</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contract in contractList" :key="contract.id" class="text-start" @click="contract.selected = !contract.selected" :class="{ 'selected-row': contract.selected }">
                    <td style="width: 55px; vertical-align: middle;" class="text-end">
                      <span v-if="!contract.selected"><button class="btn btn-light"><i class="far fa-square"></i></button></span>
                      <span v-if="contract.selected"><button class="btn btn-light"><i class="far fa-check-square"></i></button></span>
                    </td>
                    <td class="text-center" style="padding: 8px 2px 8px 2px;">
                      <button class="btn btn-outline-primary btn-sm" @click="openContractModal(contract.id)"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    </td>
                    <td class="d-none d-lg-table-cell">{{ contract.code }}</td>
                    <td>
                      {{ contract.name }}
                    </td>
                    <td class="d-none d-lg-table-cell">{{ contract.rightsHolderName }}</td>
                  </tr>
                </tbody>
                <tfoot v-if="contractResult.allRecords > contractList.length">
                  <tr>
                    <td colspan="5">
                      <div class="d-grid gap-2">
                        <button @click="contractCondition.page = contractResult.page + 1; findContract();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button v-if="matchingMode === 'auto'" @click="linkContract(similarList.filter(x => x.selected).map(x => x.id))" class="btn btn-outline-primary"><i class="fas fa-link-horizontal fa-fw" style="margin-right: 5px;"></i>紐付け</button>
              <button v-if="matchingMode === 'manual'" @click="linkContract(contractList.filter(x => x.selected).map(x => x.id))" class="btn btn-outline-primary"><i class="fas fa-link-horizontal fa-fw" style="margin-right: 5px;"></i>紐付け</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { notDivStore, contractStore } from './store'
import { errorFunction } from './function'
import { contractFunction } from '../contract/function'
import { masterFunction } from '../master/function'
import { albumFunction } from '../album/function'
import { redCompanyFunction } from '@/functions/common'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    // 検索
    const companyFunc = redCompanyFunction()
    const store = notDivStore()
    const errorFunc = errorFunction()
    const resultResult = ref(null)
    const resultList = ref([])
    // エラー解消
    const albumFunc = albumFunction()
    const masterFunc = masterFunction()
    const detectModalDiv = ref(null)
    let detectModal = null
    const detectForm = ref({})
    const matchingMode = ref('auto')
    const similarList = ref([])
    const contractFunc = contractFunction()
    const contractCondition = contractStore()
    const contractResult = ref({})
    const contractList = ref([])
    // メソッド
    const find = async () => {
      const companyDivTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      resultResult.value = await errorFunc.findNotDiv(companyDivTerm.id, store)
      resultList.value = resultResult.value.list
    }
    const download = async () => {
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      const data = await errorFunc.downloadNotDiv(divTerm.id)
      const a = document.createElement('a')
      a.href = data
      a.click()
    }
    const clearSearch = () => {
      errorFunc.clear(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = resultResult.value.allPages - 1
      find()
    }
    const openDetectModal = async (index) => {
      detectForm.value = resultList.value[index]
      await findSimilar()
      detectModal.show()
    }
    const findContract = async () => {
      contractResult.value = await contractFunc.findForMatch(session.session.company.id, contractCondition)
      contractList.value = contractList.value.concat(contractResult.value.list)
    }
    const findSimilar = async () => {
      similarList.value = []
      let artistId = null
      let projectId = null
      if (detectForm.value.dataUnit === 'album') {
        const album = await albumFunc.get(detectForm.value.albumId)
        artistId = album.artistId
        projectId = album.projectId
      }
      if (detectForm.value.dataUnit === 'master') {
        const master = await masterFunc.get(detectForm.value.masterId)
        artistId = master.artistId
        projectId = master.projectId
      }
      if (artistId !== null || projectId !== null) {
        similarList.value = await contractFunc.findSimilar(artistId, projectId)
      }
    }
    const linkContract = async (idList) => {
      processMessage.value = '契約書との紐付け処理中です。しばらくこのままでお待ちください。'
      processModal.show()
      detectModal.hide()
      const promises = []
      for (const id of idList) {
        if (detectForm.value.dataUnit === 'master') {
          promises.push(contractFunc.addMaster(id, detectForm.value.masterId))
        }
        if (detectForm.value.dataUnit === 'album') {
          promises.push(contractFunc.addAlbum(id, detectForm.value.albumId))
        }
      }
      await Promise.all(promises)
      if (detectForm.value.dataUnit === 'master') {
        await masterFunc.calculateAsync(detectForm.value.masterId)
      }
      if (detectForm.value.dataUnit === 'album') {
        await albumFunc.calculateAsync(detectForm.value.albumId)
      }
      await find()
      detectModal.hide()
      processModal.hide()
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      detectModal = new Modal(detectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      store,
      processModalDiv,
      processMessage,
      resultResult,
      resultList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      detectModalDiv,
      detectForm,
      openDetectModal,
      matchingMode,
      similarList,
      contractCondition,
      contractResult,
      contractList,
      findContract,
      linkContract,
      findSimilar,
      download
    }
  }
})
</script>
