import AdminMessageList from './List'
import AdminMessageDisp from './Disp'
import AdminMessageEdit from './Edit'

export default {
  routes: [
    {
      path: '/admin/message/list',
      name: 'AdminMessageList',
      component: AdminMessageList
    },
    {
      path: '/admin/message/disp/:id',
      name: 'AdminMessageDisp',
      component: AdminMessageDisp
    },
    {
      path: '/admin/message/add',
      name: 'AdminMessageAdd',
      component: AdminMessageEdit
    },
    {
      path: '/admin/message/edit/:id',
      name: 'AdminMessageEdit',
      component: AdminMessageEdit
    }
  ]
}
