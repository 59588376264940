<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page">
                    <i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i> [管理者用] ユーザー
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/admin/user/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>管理者新規追加</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 90px; overflow: auto;">
          <table class="table table-responsive table-hover">
            <thead>
              <tr>
                <th>名前</th>
                <th class="d-none d-md-table-cell">メールアドレス</th>
                <th class="d-none d-md-table-cell">契約</th>
              </tr>
            </thead>
            <tbody v-if="accountResult">
              <tr v-for="account in accountResult.accountList" :key="account.id" class="text-start" :class="{ cantLogin: account.canLogin === 'NO' }">
                <td><router-link :to="'/admin/user/disp/' + account.id">{{account.name}}</router-link></td>
                <td class="d-none d-md-table-cell">{{account.email}}</td>
                <td class="d-none d-md-table-cell">{{account.contractName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="accountResult">{{accountResult.allRecords.toLocaleString()}}件の一致データ</span>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <nav v-if="accountResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{store.page + 1}}/{{accountResult.allPages}}ページ</span></li>
                <li v-if="store.page !== accountResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== accountResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { userStore } from './store'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const store = userStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // 検索
    const accountResult = ref(null)
    // メソッド
    const find = () => {
      loadingModal.show()
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/account/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          searchString: store.searchString,
          page: store.page,
          perPage: 20
        }
      }).then(response => {
        accountResult.value = response.data
        for (const account of accountResult.value.accountList) {
          if (account.contractId) {
            axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + account.contractId, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              account.contractName = response.data.name
            })
          }
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      loadingModal.hide()
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = accountResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      find()
    })
    return {
      loadingModalDiv,
      store,
      accountResult,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>

<style scoped>
.cantLogin {
  background-color: lightgray;
}
</style>
