import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const addresseeFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const insert = async (form) => {
    const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/addressee/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, insert, update, remove, clearSearch, indexUpdate }
}
