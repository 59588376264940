import { defineStore } from 'pinia'
import { ref } from 'vue'

export const projectStore = defineStore('project', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
export const albumStore = defineStore('project_album', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder }
})
export const masterStore = defineStore('project_master', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder }
})
