<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="form-label align-bottom">名前</div>
      <div class="form-edit-line fs-5" style="font-weight: bold;">
        {{ form.name }}
      </div>
      <div class="form-label align-bottom">名前（表示用）</div>
      <div class="form-edit-line">
        {{ form.displayName }}
      </div>
      <div v-if="form.kana" class="form-label align-bottom">カナ</div>
      <div v-if="form.kana" class="form-edit-line">
        {{ form.kana }}
      </div>
      <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
      <div v-if="form.nameEn" class="form-edit-line">
        {{ form.nameEn }}
      </div>
      <div v-if="form.code" class="form-label align-bottom">管理コード</div>
      <div v-if="form.code" class="form-edit-line">
        {{ form.code }}
      </div>
      <div v-if="form.isrcList && form.isrcList.length > 0" class="form-label align-bottom">
        ISRC
      </div>
      <div class="form-edit-line">
        <p v-for="(isrc, index) in form.isrcList" :key="index" style="margin: 0;">
          {{isrc.isrc}}
        </p>
      </div>
      <div v-if="form.artistId" class="form-label align-bottom">アーティスト</div>
      <div v-if="form.artistId" class="form-edit-line">
        {{ form.artistName }}
      </div>
      <div v-if="form.projectId" class="form-label align-bottom">プロジェクト</div>
      <div v-if="form.projectId" class="form-edit-line">
        <router-link :to="'/common/project/disp/' + form.projectId">{{ form.projectName }}</router-link>
      </div>
      <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-label align-bottom">演奏時間
      </div>
      <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-edit-line">
        <span v-if="form.playTimeH">{{ form.playTimeH }}時間</span><span v-if="form.playTimeM">{{form.playTimeM}}分</span><span v-if="form.playTimeS">{{ form.playTimeS }}秒</span>
      </div>
      <div class="card" style="margin-bottom: 10px;">
        <div class="card-header">計算</div>
        <div class="card-body" style="padding: 10px 5px 5px 10px;">
          <div class="form-label align-bottom">計算対象</div>
          <div class="form-edit-line">
            <span v-if="form.calculateTarget">分配計算の対象とする</span>
            <span v-if="!form.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
          </div>
          <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
          <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
            <span v-if="!form.suspension">分配計算の対象とする</span>
            <span v-if="form.suspension">分配計算を保留する</span>
          </div>
        </div>
      </div>
      <div v-if="form.userCodeList && form.userCodeList.length > 0" class="accordion" id="accordionUserCode" style="margin-bottom: 10px;">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUserCode" aria-expanded="true" aria-controls="collapseUserCode">
              報告コード
            </button>
          </h2>
          <div id="collapseUserCode" class="accordion-collapse collapse" data-bs-parent="#accordionUserCode">
            <div class="accordion-body">
              <table class="table table-responsive">
                <tbody>
                <tr v-for="(userCode, index) in form.userCodeList" :key="index">
                  <td class="text-break">{{userCode.userName}}</td>
                  <td class="text-break">{{userCode.code}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
        <div class="card-header">保留期</div>
        <div class="card-body" style="padding: 5px;">
          <table class="table table-responsive">
            <tbody>
            <tr v-for="pool in form.poolList" :key="pool.id">
              <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
              <td class="text-end">
                <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                <span v-else>解除しても支払わない</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="form.memo" class="form-label align-bottom">メモ</div>
      <div v-if="form.memo" class="form-edit-line">
        <span v-html="form.memo"></span>
      </div>
      <div class="form-label align-bottom">登録</div>
      <div class="form-edit-line">
        <span v-html="form.createdAt"></span>（{{form.createdBy}}）
      </div>
      <div class="form-label align-bottom">最終更新</div>
      <div class="form-edit-line">
        <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
      </div>
    </div>
    <div class="col-lg-5">
      <ul class="nav nav-tabs" style="margin-bottom: 10px;">
        <li class="nav-item">
          <a @click="masterRightAreaMode = 'CONTRACT'" class="nav-link" :class="{ 'active': masterRightAreaMode === 'CONTRACT' }"
             aria-current="page" href="#">
            契約書
          </a>
        </li>
        <li class="nav-item">
          <a @click="masterRightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': masterRightAreaMode === 'ALBUM' }" aria-current="page" href="#">
            アルバム
          </a>
        </li>
      </ul>
      <div v-if="masterRightAreaMode === 'ALBUM'">
        <table v-if="form.albumList && form.albumList.length > 0" class="table table-responsive">
          <tbody>
          <tr v-for="(album, index) in form.albumList" :key="index">
            <td>
              <p v-if="album.catalogNo" style="margin: 0; font-size: 80%;">{{album.catalogNo}}</p>
              {{album.name}}
              <p v-if="album.artistName" style="margin: 0; font-size: 80%;">{{album.artistName}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="form-edit-line" style="margin-top: 10px;">関連アルバムが存在しません</div>
      </div>
      <div v-if="masterRightAreaMode === 'CONTRACT'">
        <table v-if="form.contractList && form.contractList.length > 0" class="table table-responsive table-hover">
          <tbody>
          <tr v-for="contract in form.contractList" :key="contract.id">
            <td>
              <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
              {{contract.name}}
              <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { masterFunction } from '@/views/red/master/function'
import { albumFunction } from '@/views/red/album/function'
import { contractFunction } from '@/views/red/contract/function'

export default defineComponent({
  props: ['masterId'],
  setup (props) {
    const masterFunc = masterFunction()
    const albumFunc = albumFunction()
    const contractFunc = contractFunction()
    const form = ref({})
    const masterRightAreaMode = ref('CONTRACT')
    const findAlbum = async (masterId) => {
      form.value.albumList = await albumFunc.findByMaster(masterId)
    }
    const findContract = async (masterId) => {
      form.value.contractList = await contractFunc.findByMaster(masterId)
    }
    onMounted(async () => {
      console.log(props.masterId)
      form.value = await masterFunc.get(props.masterId)
      await findAlbum(props.masterId)
      await findContract(props.masterId)
    })
    return {
      form,
      masterRightAreaMode
    }
  }
})
</script>
