<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/user/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] ユーザー一覧
                    </router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item">
                    <router-link :to="'/admin/user/disp/' + id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
          <div v-if="route.path.includes('/add')" class="form-edit-line">
            <input v-model="email" type="text" class="form-control">
          </div>
          <div v-else class="form-edit-line">
            <table class="table table-responsive">
              <tbody>
              <tr v-for="email in emailList" :key="email.id">
                <td>{{email.email}}</td>
                <td class="text-end" style="width: 100px;">
                  <span v-if="email.defaultEmail" class="badge bg-primary">メイン</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="id && cellPhone" class="form-label align-bottom">携帯電話番号</div>
          <div v-if="id && cellPhone" class="form-edit-line">
            {{cellPhone}}
          </div>
          <div class="form-label align-bottom">ログイン可否</div>
          <div class="form-edit-line">
            <select v-model="canLogin" class="form-select">
              <option value="YES">可能</option>
              <option value="NO">不可能</option>
            </select>
          </div>
          <div v-if="cellPhone" class="form-label align-bottom">ログイン方法</div>
          <div v-if="cellPhone" class="form-edit-line">
            <select v-model="loginMethod" class="form-select">
              <option value="EMAIL">メール</option>
              <option value="SMS">SMS</option>
            </select>
          </div>
          <div class="form-label align-bottom">お知らせのメール受信</div>
          <div class="form-edit-line">
            <div class="form-check form-switch" style="margin-right: 5px;">
              <input v-model="receiveInformation" class="form-check-input" type="checkbox" id="receiveInformation">
              <label class="form-check-label" for="receiveInformation">システム停止等のお知らせ</label>
            </div>
            <div class="form-check form-switch" style="margin-right: 5px;">
              <input v-model="receiveVersionInformation" class="form-check-input" type="checkbox" id="receiveVersionInformation">
              <label class="form-check-label" for="receiveVersionInformation">バージョンアップのお知らせ</label>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const errorToastDiv = ref(null)
    let errorToast = null
    // Form
    const id = ref(null)
    const contractName = ref(null)
    const name = ref('')
    const email = ref('')
    const cellPhone = ref('')
    const loginMethod = ref('EMAIL')
    const canLogin = ref('YES')
    const nickname = ref('')
    const receiveInformation = ref(true)
    const agree = ref('NO')
    const receiveVersionInformation = ref(true)
    const destinationList = ref([])
    const emailList = ref([])
    // メソッド
    const initEdit = (paramId) => {
      id.value = paramId
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/account/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
      })
    }
    const initAdd = () => {
      id.value = null
      name.value = ''
      email.value = ''
      cellPhone.value = ''
      loginMethod.value = 'EMAIL'
      canLogin.value = 'YES'
      nickname.value = ''
      receiveInformation.value = true
      agree.value = 'NO'
      receiveVersionInformation.value = true
      contractName.value = null
      destinationList.value = []
      emailList.value = []
    }
    const save = async () => {
      errors.value = []
      if (!name.value) {
        errors.value.push('名前を入力してください')
      }
      if (!email.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(email.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const form = {
          name: name.value,
          email: email.value,
          loginMethod: loginMethod.value,
          canLogin: canLogin.value,
          receiveInformation: (receiveInformation.value ? 'YES' : 'NO'),
          receiveVersionInformation: (receiveVersionInformation.value ? 'YES' : 'NO')
        }
        try {
          if (!id.value) {
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/admin/account', form, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
            id.value = response.data
          } else {
            await axios.put(process.env.VUE_APP_API_ENDPOINT + '/admin/account/' + id.value, form, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
          }
        } catch (error) {
          if (error.response?.status === 409) {
            errors.value.push('入力されたメールアドレスは既に登録されています')
            errorToast.show()
          } else {
            console.log(error)
            errors.value.push('システムエラーが発生しました')
            errorToast.show()
          }
        }
        await router.push('/admin/user/disp/' + id.value)
      }
    }
    function entityToForm (model) {
      name.value = model.name
      email.value = model.email
      cellPhone.value = model.cellPhone
      loginMethod.value = model.loginMethod
      canLogin.value = model.canLogin
      nickname.value = model.nickname
      receiveInformation.value = (model.receiveInformation === 'YES')
      agree.value = model.agree
      receiveVersionInformation.value = (model.receiveVersionInformation === 'YES')
      if (model.contractId) {
        axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + model.contractId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          contractName.value = response.data.name
        })
      } else {
        contractName.value = null
      }
      destinationList.value = []
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + model.id + '/destination/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        destinationList.value = response.data
        for (const destination of destinationList.value) {
          axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + destination.contractId, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            destination.contractName = response.data.name
          })
        }
      })
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + model.id + '/email/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        emailList.value = response.data
      })
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      errorToast = new Toast(errorToastDiv.value, {})
      loadingModal.show()
      session.session = await auth.getSession()
      if (route.path.includes('/add')) {
        initAdd()
      } else {
        initEdit(route.params.id)
      }
      loadingModal.hide()
    })
    return {
      route,
      session,
      loadingModalDiv,
      errors,
      errorToastDiv,
      id,
      contractName,
      name,
      email,
      cellPhone,
      loginMethod,
      canLogin,
      nickname,
      receiveInformation,
      agree,
      receiveVersionInformation,
      destinationList,
      emailList,
      initAdd,
      initEdit,
      save
    }
  }
})
</script>
