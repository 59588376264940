import { defineStore } from 'pinia'
import { ref } from 'vue'

export const naviStore = defineStore('navi', () => {
  const showBlue = ref(true)
  const showRed = ref(true)
  const showPink = ref(true)
  const showGreen = ref(true)
  const showWorkMenu = ref(false)
  const showPersonMenu = ref(false)
  const showContractMenu = ref(false)
  const showRedCalcMenu = ref(false)
  const showSupportMenu = ref(false)
  const showPaymentMenu = ref(false)
  const showTermMenu = ref(false)
  return {
    showBlue,
    showRed,
    showPink,
    showGreen,
    showWorkMenu,
    showPersonMenu,
    showContractMenu,
    showRedCalcMenu,
    showSupportMenu,
    showPaymentMenu,
    showTermMenu
  }
})
