import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const organizationFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/organization/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/organization/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  return { get, find }
}
