<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/message/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] お知らせ一覧
                    </router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item">
                    <router-link :to="'/admin/message/disp/' + id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="id && !publishedAt && showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="id && !publishedAt" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>種類</div>
          <div class="form-edit-line">
            <select v-model="messageType" class="form-select">
              <option value="GLOBAL">全体周知</option>
              <option value="VERSION">バージョンアップ</option>
            </select>
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>タイトル</div>
          <div class="form-edit-line">
            <input v-model="title" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>お知らせ内容</div>
          <div class="form-edit-line">
            <textarea v-model="messageBody" class="form-control" rows="12"></textarea>
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メッセージの削除</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger">以下のメッセージを削除しようとしています。</div>
            <div class="form-label align-bottom">作成日時</div>
            <div class="form-edit-line">
              {{createdAt}}
            </div>
            <div class="form-label align-bottom">タイトル</div>
            <div class="form-edit-line">
              {{title}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>削除をキャンセル</button>
            </div>
            <div>
              <button @click="deleteMessage" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const id = ref(null)
    const messageType = ref('GLOBAL')
    const title = ref('')
    const messageBody = ref('')
    const createdAt = ref('')
    const publishedAt = ref('')
    // メソッド
    const initEdit = (paramId) => {
      id.value = paramId
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/message/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
      })
    }
    const initAdd = () => {
      messageType.value = 'GLOBAL'
      title.value = ''
      messageBody.value = ''
      createdAt.value = ''
      publishedAt.value = ''
    }
    const save = async () => {
      errors.value = []
      if (!title.value) {
        errors.value.push('タイトルを入力してください')
      }
      if (!messageBody.value) {
        errors.value.push('お知らせ内容を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const form = {
          messageType: messageType.value,
          title: title.value,
          messageBody: messageBody.value
        }
        try {
          if (!id.value) {
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/admin/message', form, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
            id.value = response.data
          } else {
            await axios.put(process.env.VUE_APP_API_ENDPOINT + '/admin/message/' + id.value, form, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
          }
        } catch (error) {
          if (error.response?.status === 409) {
            errors.value.push('入力されたメールアドレスは既に登録されています')
            errorToast.show()
          } else {
            console.error(error)
          }
        }
        await router.push('/admin/message/disp/' + id.value)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const deleteMessage = async () => {
      await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/admin/message/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      deleteModal.hide()
      await router.push('/admin/message/list')
    }
    function entityToForm (model) {
      messageType.value = model.messageType
      title.value = model.title
      messageBody.value = model.messageBody
      createdAt.value = model.createdAt
      publishedAt.value = model.publishedAt
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      if (route.path.includes('/add')) {
        initAdd()
      } else {
        await initEdit(route.params.id)
      }
      loadingModal.hide()
    })
    return {
      session,
      loadingModalDiv,
      errors,
      errorToastDiv,
      deleteModalDiv,
      id,
      messageType,
      title,
      messageBody,
      createdAt,
      publishedAt,
      showDeleteButton,
      initAdd,
      initEdit,
      save,
      openDeleteModal,
      deleteMessage
    }
  }
})
</script>
