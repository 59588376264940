import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const usageDetailFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const find = async (divTermId, store) => {
    const params = {
      divTermId: divTermId,
      searchString: store.searchString,
      page: store.page,
      perPage: 30
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { find }
}
