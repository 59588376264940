import { defineStore } from 'pinia'
import { ref } from 'vue'

export const payeeStore = defineStore('payee', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const bankStore = defineStore('payee_bank', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
