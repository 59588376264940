import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const userFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUserSales = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/sales/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findAll = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        page: 0,
        perPage: 99999
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findUserSales = async (useTypeId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/usetype/' + useTypeId + '/user/sales/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findUserSalesByUser = async (userId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/' + userId + '/sales/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const insert = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const clear = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/user/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, clear, insert, update, remove, getUserSales, findUserSales, indexUpdate, findUserSalesByUser, findAll }
}
