import { defineStore } from 'pinia'
import { ref } from 'vue'

export const destinationStore = defineStore('destination', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const rightsHolderStore = defineStore('destination_rightsholder', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  const notMember = ref(true)
  return { searchString, sortColumn, sortOrder, notMember, page }
})
