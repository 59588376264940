<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page">
                    <i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i> [管理者用] 契約一覧
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/admin/contract/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加
              </router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <select v-model="store.useOnTest" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 200px;">
              <option value="false">テスト契約を除く</option>
              <option value="true">テスト契約のみ</option>
              <option value="all">全て</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 90px; overflow: auto;">
          <table class="table table-responsive table-hover">
            <thead>
              <tr>
                <th v-if="store.useOnTest !== 'false'" style="width: 80px;"></th>
                <th>契約者名</th>
                <th class="d-none d-lg-block">利用システム</th>
              </tr>
            </thead>
            <tbody v-if="contractResult">
              <tr v-for="contract in contractResult.contractList" :key="contract.id" class="text-start">
                <td v-if="store.useOnTest !== 'false'"><span v-if="contract.useOnTest" class="badge bg-warning">テスト</span></td>
                <td><router-link :to="'/admin/contract/disp/' + contract.id">{{contract.name}}</router-link></td>
                <td class="d-none d-lg-table-cell" style="width: 300px;">
                  <span v-if="contract.blue === 'YES'" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
                  <span v-if="contract.red === 'YES'" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
                  <span v-if="contract.pink === 'YES'" class="badge text-white" style="background-color: #EE86A8; margin-right: 5px;">PINK</span>
                  <span v-if="contract.green === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">GREEN</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="contractResult && contractResult.allRecords > 0">{{contractResult.allRecords}}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="contractResult && contractResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="contractResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{store.page + 1}}/{{contractResult.allPages}}ページ</span></li>
                <li v-if="store.page !== contractResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== contractResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'
import { contractStore } from './store'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const store = contractStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // 検索
    const contractResult = ref(null)
    // メソッド
    const find = () => {
      loadingModal.show()
      const params = {
        searchString: store.searchString,
        page: store.page,
        perPage: 30
      }
      if (store.useOnTest !== 'all') {
        params.useOnTest = store.useOnTest
      }
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: params
      }).then(response => {
        contractResult.value = response.data
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      loadingModal.hide()
    }
    const clearSearch = () => {
      store.searchString = ''
      store.useOnTest = 'false'
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = contractResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      find()
    })
    return {
      loadingModalDiv,
      store,
      contractResult,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
