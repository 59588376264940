import { defineStore } from 'pinia'
import { ref } from 'vue'

export const paymentStore = defineStore('payment', () => {
  // for status
  const divYear = ref(0)
  const divTerm = ref(0)
  // for list
  const searchString = ref('')
  const likeOrNotLike = ref('like')
  const payOrCarryForward = ref('all')
  const page = ref(0)
  const detailCondition = ref({
    searchString: null,
    taxationType: 'all',
    invoiceNo: null,
    payOrCarryForward: 'all',
    statementSendType: 'all',
    sortColumn: 'name',
    sortOrder: 'asc'
  })
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { divYear, divTerm, searchString, likeOrNotLike, payOrCarryForward, page, sortColumn, sortOrder, detailCondition }
})

export const detailStore = defineStore('payment_store', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
