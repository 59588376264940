import AdminUserList from './List'
import AdminUserDisp from './Disp.vue'
import AdminUserEdit from './Edit.vue'

export default {
  routes: [
    {
      path: '/admin/user/list',
      name: 'AdminUserList',
      component: AdminUserList
    },
    {
      path: '/admin/user/disp/:id',
      name: 'AdminUserDisp',
      component: AdminUserDisp
    },
    {
      path: '/admin/user/add',
      name: 'AdminUserAdd',
      component: AdminUserEdit
    },
    {
      path: '/admin/user/edit/:id',
      name: 'AdminUserEdit',
      component: AdminUserEdit
    }
  ]
}
