<template>
  <div>
    <div class="container-fluid no_print" style="margin-left: 5mm; width: 295mm;">
      <div class="row align-items-center" style="margin-top: 15px;">
        <div class="d-none d-md-block col-md-8 text-start">
          <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
            <ol class="breadcrumb" style="margin: 0;">
              <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 収集用権利者一覧</router-link></li>
              <li v-if="route.params.id !== 'ALL'" class="breadcrumb-item">
                <router-link :to="'/green/rightsholder/external/collect/' + route.params.id"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>
                  収集情報
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-print" style="margin-right: 5px;"></i>印刷</li>
            </ol>
          </nav>
        </div>
        <div class="col d-flex justify-content-end">
          <button v-if="!loading" onclick="window.print()" class="btn btn-primary"><i class="fas fa-print fa-fw"></i></button>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <div v-for="(page, index) in pages" :key="index">
        <section class="page">
          <div style="width: 100%; text-align: left; margin-bottom: 20px;">
            <img src="../../../../assets/logo.png" style="height: 45px;">
            <span style="position: relative; top: 3px; margin-left: 10px; font-size: 15pt; font-weight: bold; color: #8EC31F">GREEN</span>
          </div>
          <div style="font-size: 15pt;">
            適格請求書等保存方式（インボイス制度）のための事業者登録状況確認<br>および事業者登録番号登録のお願い
          </div>
          <div :style="'text-align: left; margin-top: 20px; font-size: ' + greetingFontSize + '!important;'">
            <span v-if="page.greeting" v-html="page.greeting.replaceAll('\n', '<br>').replaceAll('$name', page.name)"></span>
          </div>
          <div style="color: red; text-align: left; font-size: 11pt; margin-top: 20px;">
            登録方法がわからない等、本システムの使用方法に関するご質問は、下記「システムに関するお問い合わせ先」までご連絡ください。
          </div>
          <div style="font-size: 10pt; text-align: left; margin-top: 30px;">
            <hr style="border: 1px solid #999999; margin-bottom: 10px;">
            <p>【rights.services GREEN運営会社 / システムに関するお問い合わせ先】</p>
            <table style="margin-top: 7px;">
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">運営会社</td>
                <td>: 東京ライツサービス株式会社</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">WEBサイト</td>
                <td>: https://www.rights.tokyo</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">メールアドレス</td>
                <td>: support@monstrs.rights.tokyo</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">電話番号</td>
                <td>: 050-3138-2564</td>
              </tr>
              <tr><td colspan="3" style="height: 1px;"></td></tr>
              <tr>
                <td colspan="3">
              <span style="margin-left: 10px; font-size: 11pt; color: #999999;">
                Our customer support is available only in Japanese language.
              </span>
                </td>
              </tr>
            </table>
          </div>
          <div style="width: 100%; text-align: right;">
            <img src="../../../../assets/logo_corp.png" style="height: 30px;">
          </div>
        </section>
        <section class="page">
          <div style="width: 100%; text-align: left;">
            <img src="../../../../assets/logo.png" style="height: 45px;">
            <span style="position: relative; top: 3px; margin-left: 10px; font-size: 15pt; font-weight: bold; color: #8EC31F">GREEN</span>
          </div>
          <div style="font-size: 16pt;">【登録用シート】</div>
          <div style="margin-top: 10px; font-size: 11pt;">
            <table style="width: 100%; border-collapse: collapse;">
              <tr>
                <td colspan="2" style="border: 1px solid #cccccc; background-color: #eeeeee!important; padding: 3px; vertical-align: middle;">登録用情報</td>
                <td style="width: 100px; border: 1px solid #cccccc; background-color: #eeeeee!important;">QRコード</td>
              </tr>
              <tr>
                <td style="height: 34px; width: 120px; border: 1px solid #cccccc; padding: 3px 3px 3px 5px; text-align: left; vertical-align: middle;">依頼元</td>
                <td style="border: 1px solid #cccccc; text-align: left; padding-left: 5px; vertical-align: middle;">{{page.companyName}}</td>
                <td rowspan="3" style="width: 100px; border: 1px solid #cccccc;">
                  <img v-if="page.qrCodeBase64" :src="'data:image/png;base64, ' + page.qrCodeBase64">
                </td>
              </tr>
              <tr>
                <td style="width: 120px; border: 1px solid #cccccc; padding: 3px 3px 3px 5px; text-align: left; vertical-align: middle;">権利者名</td>
                <td style="border: 1px solid #cccccc; text-align: left; padding-left: 5px; vertical-align: middle;">{{page.name}}</td>
              </tr>
              <tr>
                <td style="width: 120px; border: 1px solid #cccccc; padding: 3px 3px 3px 5px; text-align: left; vertical-align: middle;">登録用URL</td>
                <td style="border: 1px solid #cccccc; text-align: left; padding-left: 5px; font-size: 9pt; vertical-align: middle; font-family: 'JetBrains Mono', monospace">{{page.url}}</td>
              </tr>
            </table>
          </div>
          <div style="font-size: 13pt; text-align: left; margin-top: 20px; margin-bottom: 10px;">【登録手順】</div>
          <div style="background-color: #eeeeee!important; padding: 3px 3px 3px 5px; border: 1px solid #cccccc; font-size: 11pt; text-align: left;">QRコードを使用して登録する</div>
          <div style="text-align: left; font-size: 11pt; margin-bottom: 10px;">
            <ol style="margin-top: 0;" class="list-group list-group-numbered">
              <li>
                スマートフォン等、QRコードを読み取り可能な機器を用いて、本資料右上のQRコードを読み込み、 登録用画面を表示する。
              </li>
              <li>
                画面に表示されている内容がご本人のものか確認する。
              </li>
              <li>
                明細受領に使用するメールアドレスを登録する。
              </li>
            </ol>
          </div>
          <div style="background-color: #eeeeee!important; padding: 3px 3px 3px 5px; border: 1px solid #cccccc; font-size: 11pt; text-align: left;">QRコードを使用せず、URLを入力して登録する</div>
          <div style="text-align: left; font-size: 11pt;">
            <ol style="margin-top: 0;" class="list-group list-group-numbered">
              <li>
                PC等、インターネットに接続可能な機器より本資料上部に記載の「登録用URL」にアクセスし、登録用画面を表示する。
              </li>
              <li>
                画面に表示されている内容がご本人のものか確認する。
              </li>
              <li>
                明細受領に使用するメールアドレスを登録する。
              </li>
            </ol>
          </div>
          <div style="color: red; text-align: left; font-size: 11pt; margin-top: 20px;">
            メールアドレス登録方法等、本システムの使用方法に関するご質問は、下記「システムに関するお問い合わせ先」までご連絡ください。
          </div>
          <div style="font-size: 10pt; text-align: left; margin-top: 30px;">
            <hr style="border: 1px solid #999999; margin-bottom: 10px;">
            <p>【rights.services GREEN運営会社 / システムに関するお問い合わせ先】</p>
            <table style="margin-top: 7px;">
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">運営会社</td>
                <td>: 東京ライツサービス株式会社</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">WEBサイト</td>
                <td>: https://www.rights.tokyo</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">メールアドレス</td>
                <td>: support@monstrs.rights.tokyo</td>
              </tr>
              <tr>
                <td style="width: 10px;"></td>
                <td style="width: 120px;">電話番号</td>
                <td>: 050-3138-2564</td>
              </tr>
              <tr><td colspan="3" style="height: 1px;"></td></tr>
              <tr>
                <td colspan="3">
              <span style="margin-left: 10px; font-size: 11pt; color: #999999;">
                Our customer support is available only in Japanese language.
              </span>
                </td>
              </tr>
            </table>
          </div>
          <div style="width: 100%; text-align: right;">
            <img src="../../../../assets/logo_corp.png" style="height: 30px;">
          </div>
        </section>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-info fs-5" style="margin-top: 50px; margin-left: 30px; margin-right: 30px; padding: 30px;">
        ページを読み込み中です。しばらくこのままでお待ちください。
        <div v-if="route.params.id === 'ALL'">
          {{madePage}} / {{allPage}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, nextTick, onBeforeMount } from 'vue'
import auth from '@/functions/auth'
import exception from '@/functions/exception'
import axios from 'axios'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    // Form
    const loading = ref(true)
    const pages = ref([])
    const greetingFontSize = ref('12pt')
    const allPage = ref(0)
    const madePage = ref(0)
    async function getPage (id) {
      const page = {}
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + id, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(async response => {
        const rh = response.data
        page.name = rh.name
        page.greeting = rh.greeting
        await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contract/' + rh.contractId, {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          page.companyName = response.data.name
        })
        if (!page.greeting) {
          await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting', {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            page.greeting = response.data.replace('$name', rh.name)
            axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + id, {
              name: rh.name,
              externalId: rh.externalId,
              memo: rh.memo,
              greeting: page.greeting
            }, {
              headers: { Authorization: 'Bearer ' + getAccessToken() }
            })
          })
        }
        if (page.greeting.length > 800) {
          greetingFontSize.value = '10pt'
        }
        await generateUrl(id, false)
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + response.data.id + '/token', {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          page.url = response.data.url
          page.qrCodeBase64 = response.data.qrCode
        }).catch(error => {
          identifyEx(error)
        })
      }).catch(error => { identifyEx(error) })
      return page
    }
    const generateUrl = async (id, renew) => {
      let tokenUrl = '/external/rightsholder/' + id + '/token'
      if (renew) {
        tokenUrl = tokenUrl + '/renew'
      }
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + tokenUrl, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).catch(error => {
        identifyEx(error)
      })
    }
    onBeforeMount(() => {
      const style = document.createElement('style')
      style.innerHTML = '@page { size: A4 portrait!important; margin: 0; }'
      document.head.appendChild(style)
    })
    onMounted(async () => {
      if (route.params.id === 'ALL') {
        let searchString = ''
        if (sessionStorage.getItem('green_rights_holder_searchString')) {
          searchString = sessionStorage.getItem('green_rights_holder_searchString')
        }
        const params = {
          searchString: searchString,
          sortColumn: sessionStorage.getItem('green_rights_holder_sortColumnE'),
          sortOrder: sessionStorage.getItem('green_rights_holder_sortOrderE'),
          page: 0,
          perPage: 2147483647
        }
        if (sessionStorage.getItem('green_rights_holder_registered') && sessionStorage.getItem('green_rights_holder_registered') !== 'ALL') {
          params.registered = sessionStorage.getItem('green_rights_holder_registered')
        }
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/list', {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
          params: params
        }).then(async response => {
          allPage.value = response.data.list.length
          for (const data of response.data.list) {
            pages.value.push(await getPage(data.id))
            madePage.value = madePage.value + 1
          }
        })
      } else {
        pages.value.push(await getPage(route.params.id))
      }
      await nextTick(() => {
        loading.value = false
      })
    })
    return {
      route,
      pages,
      loading,
      greetingFontSize,
      allPage,
      madePage
    }
  }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

*{
  margin: 0;
  padding: 0;
}
body {
  width: 210mm;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  line-height: 1.5em;
}
.page {
  width: 210mm;
  height: 296mm;
  page-break-after: always;
  padding: 5mm;
}
@media screen {
  body {
    background-color: #eee;
  }
  .page {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}
@media print {
  #backButton {
    display: none;
  }
  ol li {
    margin-left: 25px;
    margin-right: 5px;
  }
  .no_print {
    display: none;
  }
}
</style>
